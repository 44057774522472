import { render, staticRenderFns } from "./BantuanList.vue?vue&type=template&id=68ef3db7&scoped=true"
import script from "./BantuanList.vue?vue&type=script&lang=js"
export * from "./BantuanList.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "68ef3db7",
  null
  
)

export default component.exports