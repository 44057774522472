<template>
  <v-main>
    <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>
    <v-container fluid>
      <v-card>
        <v-card-title> Bantuan </v-card-title>
        <form class="pl-4 pr-4">
          <v-row dense>
            <v-col cols="12" sm="12" md="12">
              <v-row dense>
                <v-col cols="12" sm="12" md="12">
                  <v-textarea
                    v-model="data.value"
                    outlined
                    label="Bantuan"
                    rows="12"
                    row-height="15"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="d-flex justify-end">
              <v-btn style="margin-right: 10px" @click="doBatal">Batal</v-btn>
              <v-btn dark color="primary" type="button" @click="doSubmit"
                >Simpan</v-btn
              >
            </v-col>
          </v-row>
        </form>
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
import WebContentService from "../services/WebContentService";

export default {
  name: "BantuanList",
  components: {},
  data: () => ({
    breadcrumbs: [{ text: "Bantuan", disabled: true }],
    data: {
      id: "",
      value: "",
    },
  }),
  mounted: async function () {
    this.bantuan();
  },
  methods: {
    bantuan: async function () {
      WebContentService.bantuan()
        .then((resp) => {
          console.log(resp.data);
          this.data = resp.data.result;
        })
        .catch((err) => console.log(err));
    },

    doBatal: function () {
      this.$router.go();
    },

    doSubmit: async function () {
      WebContentService.updateBantuan(this.data)
        .then((res) => {
          console.log(res);
          this.$toast.success("Update Bantuan berhasil", {
            position: "top-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        })
        .catch((err) => {
          console.log(err);
          this.$toast.error("Update Bantuan gagal. Coba lagi", {
            position: "top-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        });
    },
  },
};
</script>

<style scoped></style>
