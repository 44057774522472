<template>
  <v-main>
    <!-- Provides the application the proper gutter -->
    <v-container fluid>
      <v-breadcrumbs :items="breadcrumbs" />

      <!-- If using vue-router -->
      <!-- <router-view></router-view> -->
      <v-card>
        <v-card-title>
          Komisariat
          <v-spacer />
          <!-- <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Cari Komisariat"
            single-line
            hide-details
            @keyup.enter="getDataFromApi"
          />
          <v-spacer /> -->

          <v-dialog v-model="dialog" persistent max-width="600px">
            <template #activator="{}">
              <v-btn color="primary" dark @click="doAdd"> Tambah </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">Komisariat</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        v-model="currentData.nama"
                        label="Nama"
                        required
                        :rules="currentData.namaRules"
                      />
                    </v-col>

                    <v-col cols="12">
                      <v-combobox
                        v-model="currentData.selectedDpw"
                        label="Dewan Pengurus Wilayah"
                        clearable
                        dense
                        outlined
                        :items="items_dpw"
                        item-text="nama"
                        :rules="currentData.dpwRules"
                        :disabled="isDpwPengurus"
                        @change="changeDpwInput"
                      ></v-combobox>
                    </v-col>

                    <v-col cols="12">
                      <v-combobox
                        v-model="currentData.selectedDpd"
                        label="Dewan Pengurus Daerah"
                        clearable
                        dense
                        outlined
                        :items="items_dpd_input"
                        item-text="nama"
                        :rules="currentData.dpdRules"
                        :disabled="isDpdPengurus"
                        @change="changeDpd"
                        @click="getListDpdInput"
                      ></v-combobox>
                    </v-col>

                    <v-col cols="12">
                      <v-text-field
                        v-model="currentData.alamat"
                        label="Alamat"
                        required
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn color="blue darken-1" text @click="dialog = false">
                  Close
                </v-btn>
                <v-btn color="blue darken-1" text @click="doSave"> Save </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5">
                Apa anda akan menghapus data ini?
              </v-card-title>
              <v-card-actions>
                <v-spacer />
                <v-btn color="blue darken-1" text @click="closeDelete">
                  Tidak
                </v-btn>
                <v-btn color="blue darken-1" text @click="deleteItemConfirm">
                  Ya
                </v-btn>
                <v-spacer />
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card-title>

        <v-row class="mx-4 my-4">
          <v-col cols="12" sm="4" md="4">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Cari Nama"
              single-line
              outlined
              dense
              hide-details
              @keyup.enter="getDataFromApi"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="4" md="4">
            <v-combobox
              v-model="selectedDpw"
              label="Dewan Pengurus Wilayah"
              clearable
              dense
              outlined
              :items="items_dpw"
              item-text="nama"
              :disabled="isDpwPengurus"
              @change="changeDpw"
            ></v-combobox>
          </v-col>
          <v-col cols="12" sm="4" md="4">
            <v-combobox
              v-model="selectedDpd"
              label="Dewan Pengurus Daerah"
              clearable
              dense
              outlined
              :items="items_dpd"
              item-text="nama"
              :disabled="isDpdPengurus"
              @change="changeDpd"
              @click="getListDpd"
            ></v-combobox>
          </v-col>
        </v-row>

        <v-data-table
          :headers="headers"
          :items="list_data"
          :options.sync="options"
          :server-items-length="totalData"
          :loading="loading"
          class="elevation-1"
        >
          <template #[`item.actions`]="{ item }">
            <v-icon medium class="mr-3" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon medium @click="deleteItem(item)"> mdi-delete </v-icon>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
import KomisariatService from "../services/KomisariatService";
import CommonService from "../services/CommonService";
import DPWService from "../services/DPWService";
import DPDService from "../services/DPDService";
import store from "../store/index";

export default {
  data: () => ({
    breadcrumbs: [
      {
        text: "Data Komisariat",
        disabled: true,
        href: "breadcrumbs_dashboard",
      },
    ],
    search: "",
    selectedDpw: "",
    selectedDpd: "",
    items_dpw: [],
    items_dpd: [],
    items_dpd_input: [],
    headers: [
      {
        text: "No",
        align: "start",
        sortable: false,
        value: "no",
      },
      { text: "Nama", value: "nama", sortable: false },
      { text: "DPD", value: "dpd_nama", sortable: false },
      { text: "DPW", value: "dpw_nama", sortable: false },
      { text: "Alamat", value: "alamat", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
    isFirst: false,
    totalData: 0,
    list_data: [],
    loading: true,
    options: {
      itemsPerPage: 10,
    },

    editedIndex: -1,
    editedItem: {},
    dialog: false,
    dialogDelete: false,
    currentData: {
      id: null,
      nama: "",
      alamat: "",
      selectedDpw: "",
      selectedDpd: "",
      namaRules: [(v) => !!v || "Nama harus diisi"],
      dpwRules: [(v) => !!v || "DPW harus diisi"],
      dpdRules: [(v) => !!v || "DPD harus diisi"],
    },
  }),
  computed: {
    getDpwPengurusId() {
      return store.getters.getUser.dpw_id;
    },
    getDpdPengurusId() {
      return store.getters.getUser.dpd_id;
    },
    isDpwPengurus() {
      if (store.getters.getUser.dpw_id) {
        return true;
      } else {
        return false;
      }
    },
    isDpdPengurus() {
      if (store.getters.getUser.dpd_id) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    options: {
      handler() {
        if (this.isFirst) {
          this.getDataFromApi();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getListDpw();
    this.getListDpwInput();
    this.getDataFromApi();
  },
  methods: {
    changeDpw() {
      this.selectedDpd = null;
      this.getDataFromApi();
    },

    changeDpwInput() {
      this.currentData.selectedDpd = null;
    },

    changeDpd() {
      this.getDataFromApi();
    },

    async getListDpw() {
      DPWService.dpwListAll()
        .then((res) => {
          this.items_dpw = res.data.list_data;

          if (this.getDpwPengurusId) {
            this.items_dpw.forEach((entry) => {
              if (entry.id == this.getDpwPengurusId) {
                this.selectedDpw = entry;

                if (this.getDpdPengurusId) {
                  this.getListDpd();
                } else {
                  this.getDataFromApi();
                }
              }
            });
          } else {
            this.getDataFromApi();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getListDpwInput() {
      DPWService.dpwListAll()
        .then((res) => {
          this.items_dpw = res.data.list_data;

          if (this.getDpwPengurusId) {
            this.items_dpw.forEach((entry) => {
              if (entry.id == this.getDpwPengurusId) {
                this.currentData.selectedDpw = entry;

                if (this.getDpdPengurusId) {
                  this.getListDpdInput();
                }
              }
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getListDpd() {
      var dpwId = "";

      if (this.selectedDpw) {
        dpwId = this.selectedDpw.id;
      }

      DPDService.dpdListAll(dpwId)
        .then((res) => {
          this.items_dpd = res.data.list_data;

          if (this.getDpdPengurusId) {
            this.items_dpd.forEach((entry) => {
              if (entry.id == this.getDpdPengurusId) {
                this.selectedDpd = entry;
                this.getDataFromApi();
              }
            });
          } else {
            this.getDataFromApi();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getListDpdInput() {
      var dpwId = "";

      if (this.currentData.selectedDpw) {
        dpwId = this.selectedDpw.id;
      }

      DPDService.dpdListAll(dpwId)
        .then((res) => {
          this.items_dpd_input = res.data.list_data;

          if (this.getDpdPengurusId) {
            this.items_dpd_input.forEach((entry) => {
              if (entry.id == this.getDpdPengurusId) {
                this.currentData.selectedDpd = entry;
              }
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getDataFromApi() {
      const { page, itemsPerPage } = this.options;

      var dpw = "";
      var dpd = "";

      if (this.selectedDpw) {
        dpw = this.selectedDpw.id;
      }

      if (this.selectedDpd) {
        dpd = this.selectedDpd.id;
      }

      this.loading = true;
      KomisariatService.komisariatList(
        page,
        itemsPerPage,
        this.search,
        dpw,
        dpd,
      )
        .then((res) => {
          this.list_data = res.data.list_data;
          this.totalData = res.data.total;
          this.loading = false;
          this.isFirst = true;
        })
        .catch((err) => {
          this.loading = false;
          this.isFirst = true;
          console.log(err);
        });
    },

    doAdd: async function () {
      this.dialog = true;
      this.currentData.nama = "";
      this.currentData.alamat = "";
      // this.currentData.selectedDpw = "";
      // this.currentData.selectedDpd = "";
      this.currentData.id = null;
      console.log("do add");
    },

    doSave: async function () {
      if (this.currentData.id == null) {
        KomisariatService.create(this.currentData)
          .then((res) => {
            console.log(res);
            this.dialog = false;

            CommonService.showSuccessToast("Tambah komisariat berhasil");

            this.getDataFromApi();
          })
          .catch((err) => {
            console.log(err);
            CommonService.showErrorToast("Tambah komisariat gagal. Coba lagi");
          });
      } else {
        KomisariatService.update(this.currentData.id, this.currentData)
          .then((res) => {
            this.dialog = false;

            console.log(res);
            CommonService.showSuccessToast("Update komisariat berhasil");

            this.getDataFromApi();
          })
          .catch((err) => {
            console.log(err);
            CommonService.showErrorToast("Update komisariat gagal. Coba lagi");
          });
      }
    },

    editItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.dialog = true;
      this.currentData.nama = this.editedItem.nama;
      this.currentData.alamat = this.editedItem.alamat;
      this.currentData.id = this.editedItem.id;

      if (this.editedItem.dewan_pengurus_wilayah_id) {
        this.items_dpw.forEach((entry) => {
          if (entry.id == this.editedItem.dewan_pengurus_wilayah_id) {
            this.currentData.selectedDpw = entry;
          }
        });

        if (this.editedItem.dewan_pengurus_daerah_id) {
          this.getListDPDAll(
            this.editedItem.dewan_pengurus_wilayah_id,
            this.editedItem.dewan_pengurus_daerah_id,
          );
        }
      }

      console.log("do add");
    },

    async getListDPDAll(dpwId, dpdId) {
      DPDService.dpdListAll(dpwId)
        .then((res) => {
          this.items_dpd_input = res.data.list_data;
          if (dpdId) {
            this.items_dpd_input.forEach((entry) => {
              if (entry.id == dpdId) {
                this.currentData.selectedDpd = entry;
              }
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    deleteItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.closeDelete();

      KomisariatService.delete(this.editedItem.id)
        .then((res) => {
          console.log(res);

          CommonService.showSuccessToast("Hapus komisariat berhasil");

          this.getDataFromApi();
        })
        .catch((err) => {
          console.log(err);
          CommonService.showSuccessToast("Hapus komisariat gagal. Coba lagi");
        });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
  },
};
</script>
