<template>
  <v-main>
    <v-container fluid>
      <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>

      <v-card>
        <v-card-title>
          Agenda Pelatihan
          <v-spacer></v-spacer>

          <!-- <v-btn color="purple" dark class="mb-2" @click="doAdd">
            Tambah
          </v-btn> -->

          <v-dialog v-model="dialog" persistent>
            <v-card>
              <v-card-title>
                <span class="text-h5">Agenda Pelatihan</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" md="8">
                      <v-row>
                        <v-col cols="12" sm="12" md="12">
                          <v-text-field
                            v-model="currentData.nama"
                            label="Nama"
                            required
                            readonly
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" sm="12" md="12">
                          <v-file-input
                            v-model="currentData.foto_url"
                            accept="image/*"
                            prepend-icon="mdi-image"
                            show-size
                            label="Foto"
                            @change="loadImage"
                          />
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" sm="12" md="12">
                          <v-responsive :aspect-ratio="16 / 9">
                            <v-img
                              :key="currentData.fotoKey"
                              :src="currentData.FotoUrl"
                              contain
                            ></v-img>
                          </v-responsive>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialog = false">
                  Close
                </v-btn>
                <v-btn color="blue darken-1" text @click="doSave"> Save </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <!-- <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5"
                >Apa anda akan menghapus data ini?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete"
                  >Tidak</v-btn
                >
                <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                  >Ya</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog> -->
        </v-card-title>

        <v-row class="mx-4 my-4">
          <v-col cols="12" sm="4" md="4">
            <v-combobox
              v-model="selectedDpd"
              label="DPD"
              clearable
              dense
              outlined
              :items="items_dpd"
              item-text="nama"
              @change="changeDpd"
            ></v-combobox>
          </v-col>
        </v-row>

        <v-data-table
          :headers="headers"
          :items="list_data"
          :options.sync="options"
          :server-items-length="totalData"
          :loading="loading"
          class="elevation-1"
        >
          <template #[`item.foto_url`]="{ item }">
            <v-img :src="item.foto_url" contain width="100"></v-img>
          </template>

          <template #[`item.actions`]="{ item }">
            <v-icon medium class="mr-3" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <!-- <v-icon medium @click="deleteItem(item)"> mdi-delete </v-icon> -->
          </template>
        </v-data-table>
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
import AgendaPelatihanService from "../../services/web_content/AgendaPelatihanService";
import CommonService from "../../services/CommonService";
// import KomisariatService from "../../services/KomisariatService";
// import store from "../../store/index";
import DPDService from "../../services/DPDService";

export default {
  name: "AgendaPelatihan",
  components: {},
  props: {},
  data: () => ({
    breadcrumbs: [
      {
        text: "Agenda Pelatihan",
        disabled: false,
        exact: true,
        to: { name: "AgendaPelatihan" },
      },
      { text: "Detail", disabled: true },
    ],
    // selectedKomisariat: "",
    // items_komisariat: [],
    selectedDpd: "",
    items_dpd: [],
    // readOnly: true,
    currentData: {
      id: 0,
      nama: "",
      foto_url: null,
      fotoKey: 0,
      FotoUrl: null,
      visi: "",
      misi: "",
    },

    isFirst: false,
    totalData: 0,
    list_data: [],
    loading: true,
    options: {
      itemsPerPage: 10,
    },

    // editedIndex: -1,
    // editedItem: {},
    dialog: false,
    // dialogDelete: false,

    headers: [
      {
        text: "No",
        align: "start",
        sortable: false,
        value: "no",
      },
      { text: "DPD", value: "nama", sortable: false },
      { text: "Foto", value: "foto_url", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
  }),
  computed: {
    // getKomisariatId() {
    //   return store.getters.getUser.komisariat_id;
    // },
    // isAdminKomisariat() {
    //   if (store.getters.getUser.komisariat_id) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // },
  },
  mounted: async function () {
    // this.getListKomisariat();
    this.getListDpd();
    this.getDataFromApi();
  },
  methods: {
    // changeKomisariat() {
    //   this.getDataFromApi();
    // },

    // async getListKomisariat() {
    //   KomisariatService.komisariatListAll()
    //     .then((res) => {
    //       this.items_komisariat = res.data.list_data;

    //       if (this.getKomisariatId) {
    //         this.items_komisariat.forEach((entry) => {
    //           if (entry.id == this.getKomisariatId) {
    //             this.selectedKomisariat = entry;
    //             this.getDataFromApi();
    //           }
    //         });
    //       } else {
    //         this.getDataFromApi();
    //       }
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // },
    changeDpd() {
      this.getDataFromApi();
    },
    async getListDpd() {
      DPDService.dpdListAllOnly()
        .then((res) => {
          this.items_dpd = res.data.list_data;

          // if (this.getKomisariatId) {
          //   this.items_komisariat.forEach((entry) => {
          //     if (entry.id == this.getKomisariatId) {
          //       this.selectedKomisariat = entry;
          //       this.getDataFromApi();
          //     }
          //   });
          // } else {

          // }
          this.getDataFromApi();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    loadImage: function (data) {
      console.log("loadImage:", data);
      if (data) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.currentData.FotoUrl = e.target.result;
          this.currentData.fotoKey++;
        };
        reader.readAsDataURL(data);
      } else {
        this.currentData.FotoUrl = "";
        this.currentData.foto_url = null;
      }
    },

    getDataFromApi() {
      const { page, itemsPerPage } = this.options;

      var dpd = "";

      if (this.selectedDpd) {
        dpd = this.selectedDpd.id;
      }

      this.loading = true;
      AgendaPelatihanService.agendaPelatihanList(
        page,
        itemsPerPage,
        this.search,
        dpd,
      )
        .then((res) => {
          this.list_data = res.data.list_data;
          this.totalData = res.data.total;
          this.loading = false;
          this.isFirst = true;
        })
        .catch((err) => {
          this.loading = false;
          this.isFirst = true;
          console.log(err);
        });
    },

    // doAdd: async function () {
    //   this.dialog = true;
    //   this.currentData.id = null;
    //   this.currentData.judul = "";
    //   this.currentData.konten = "";
    //   this.currentData.foto_url = null;

    //   console.log("do add");
    // },

    doSave: async function () {
      if (this.currentData.id != null) {
        AgendaPelatihanService.update(this.currentData)
          .then((res) => {
            console.log(res);
            this.dialog = false;
            CommonService.showSuccessToast("Update agenda pelatihan berhasil");

            this.getDataFromApi();
          })
          .catch((err) => {
            console.log(err);
            CommonService.showErrorToast(
              "Update agenda pelatihan gagal. Coba lagi",
            );
          });
      } else {
        AgendaPelatihanService.create(this.currentData)
          .then((res) => {
            console.log(res);
            this.dialog = false;
            CommonService.showSuccessToast("Tambah agenda pelatihan berhasil");

            this.getDataFromApi();
          })
          .catch((err) => {
            console.log(err);
            CommonService.showErrorToast(
              "Tambah agenda pelatihan gagal. Coba lagi",
            );
          });
      }
    },

    editItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.dialog = true;
      this.currentData.nama = this.editedItem.nama;
      this.currentData.visi = this.editedItem.visi;
      this.currentData.misi = this.editedItem.misi;
      this.currentData.FotoUrl = this.editedItem.foto_url;
      this.currentData.id = this.editedItem.id;
      this.currentData.fotoKey++;

      console.log("do edit");
    },

    // deleteItem(item) {
    //   console.log(item);
    //   this.editedIndex = this.list_data.indexOf(item);
    //   this.editedItem = Object.assign({}, item);
    //   this.dialogDelete = true;
    // },

    // deleteItemConfirm() {
    //   this.closeDelete();

    //   AgendaPelatihanService.delete(this.editedItem.id)
    //     .then((res) => {
    //       console.log(res);
    //       CommonService.showSuccessToast("Hapus sejarah berhasil");

    //       this.getDataFromApi();
    //     })
    //     .catch((err) => {
    //       console.log(err);

    //       CommonService.showErrorToast("Hapus sejarah gagal. Coba lagi");
    //     });
    // },

    // closeDelete() {
    //   this.dialogDelete = false;
    //   this.$nextTick(() => {
    //     this.editedItem = Object.assign({}, this.defaultItem);
    //     this.editedIndex = -1;
    //   });
    // },
  },
};
</script>

<style scoped></style>
