import http from "../http-common";
/* eslint-disable */
class PelatihanService {
  pelatihanList(page, itemPerPage, keyword, kegiatanId) {
    return http.get(
      "/pelatihan/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword +
        "&kegiatan_id=" +
        kegiatanId,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  getKegiatan(id) {
    return http.get(`/kegiatan/${id}`, {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  create(data) {
    return http.post("/pelatihan", data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  update(data) {
    return http.put(`/pelatihan/${data.id}`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  delete(id) {
    return http.delete(`/pelatihan/${id}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }
}

export default new PelatihanService();
