<template>
  <div>
    <v-card>
      <v-card-title>
        Narasumber <br />
        <v-spacer></v-spacer>
      </v-card-title>
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="list_data"
        :options.sync="options"
        :server-items-length="totalData"
        :loading="loading"
        :single-select="true"
        show-select
        class="elevation-1"
      >
        <template #[`item.tanggal`]="{ item }">
          {{ getCurrentDate(item.tanggal) }}
        </template>
      </v-data-table>
    </v-card>

    <v-card class="mt-4">
      <PelatihanEvaluasiNarasumberComponent
        v-if="selected != undefined && selected.length > 0"
        :id="selected[0].id"
        ref="mychild"
        :key="childKey"
        :kegiatan-id="kegiatanId"
        :user-id="id"
      />
    </v-card>
  </div>
</template>

<script>
import ReportService from "../../services/ReportService";
import * as moment from "moment";
import PelatihanEvaluasiNarasumberComponent from "./PelatihanEvaluasiNarasumberReport.vue";

export default {
  components: {
    PelatihanEvaluasiNarasumberComponent,
  },
  props: {
    id: {
      type: Number,
      default: 0,
    },
    kegiatanId: {
      type: String,
      default: "0",
    },
    userId: {
      type: String,
      default: "0",
    },
  },
  data: () => ({
    childKey: 0,
    search: "",
    selected: [],
    headers: [
      {
        text: "No",
        align: "start",
        sortable: false,
        value: "no",
      },
      { text: "Nama", value: "nama", sortable: false },
    ],
    isFirst: false,
    totalData: 0,
    list_data: [],
    loading: true,
    options: {
      itemsPerPage: 10,
      page: 1,
    },
  }),
  watch: {
    selected: {
      handler() {
        if (this.selected != undefined && this.selected[0]) {
          this.childKey++;
        }
      },
      deep: true,
    },
    options: {
      handler() {
        if (this.isFirst) {
          this.getDataFromApi();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getDataFromApi();
  },
  methods: {
    getCurrentDate(tanggal) {
      return moment(tanggal).format("DD/MM/YYYY");
    },

    async getDataFromApi() {
      const { page, itemsPerPage } = this.options;

      this.loading = true;
      ReportService.narasumberReportList(
        page,
        itemsPerPage,
        this.search,
        this.id,
        this.userId,
      )
        .then((res) => {
          this.list_data = res.data.list_data;
          this.totalData = res.data.total;
          this.loading = false;
          this.isFirst = true;

          if (this.list_data.length > 0) {
            this.$nextTick(() => {
              this.selected.push(this.list_data[0]);
              this.childKey++;
            });
          }
        })
        .catch((err) => {
          this.loading = false;
          this.isFirst = true;
          console.log(err);
        });
    },
  },
};
</script>
