<template>
  <v-main>
    <!-- Provides the application the proper gutter -->
    <v-container fluid>
      <v-breadcrumbs :items="breadcrumbs" />

      <!-- If using vue-router -->
      <!-- <router-view></router-view> -->
      <v-card>
        <v-card-title>
          Dewan Pimpinan Daerah
          <v-spacer />
          <!-- <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Cari Nama"
            single-line
            hide-details
            @keyup.enter="getDataFromApi"
          />
          <v-spacer /> -->

          <v-dialog v-model="dialog" persistent max-width="600px">
            <template #activator="{}">
              <v-btn color="primary" dark @click="doAdd"> Tambah </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">Dewan Pimpinan Daerah</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        v-model="currentData.nama"
                        label="Nama"
                        required
                        :rules="currentData.namaRules"
                      />
                    </v-col>
                    <v-col cols="12">
                      <v-combobox
                        v-model="currentData.selectedDpw"
                        label="Dewan Pengurus Wilayah"
                        clearable
                        dense
                        outlined
                        :items="items_dpw"
                        item-text="nama"
                        :rules="currentData.dpwRules"
                      ></v-combobox>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model="currentData.alamat"
                        label="Alamat"
                        required
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn color="blue darken-1" text @click="dialog = false">
                  Close
                </v-btn>
                <v-btn color="blue darken-1" text @click="doSave"> Save </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5">
                Apa anda akan menghapus data ini?
              </v-card-title>
              <v-card-actions>
                <v-spacer />
                <v-btn color="blue darken-1" text @click="closeDelete">
                  Tidak
                </v-btn>
                <v-btn color="blue darken-1" text @click="deleteItemConfirm">
                  Ya
                </v-btn>
                <v-spacer />
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card-title>

        <v-row class="mx-4 my-4">
          <v-col cols="12" sm="4" md="4">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Cari Nama"
              single-line
              outlined
              dense
              hide-details
              @keyup.enter="getDataFromApi"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="4" md="4">
            <v-combobox
              v-model="selectedDpw"
              label="Dewan Pengurus Wilayah"
              clearable
              dense
              outlined
              :items="items_dpw"
              item-text="nama"
              @change="changeDpw"
            ></v-combobox>
          </v-col>
        </v-row>

        <v-data-table
          :headers="headers"
          :items="list_data"
          :options.sync="options"
          :server-items-length="totalData"
          :loading="loading"
          class="elevation-1"
        >
          <template #[`item.actions`]="{ item }">
            <v-icon medium class="mr-3" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon medium @click="deleteItem(item)"> mdi-delete </v-icon>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
import DPDService from "../services/DPDService";
import DPWService from "../services/DPWService";
import CommonService from "../services/CommonService";

export default {
  data: () => ({
    breadcrumbs: [
      {
        text: "Data DPD",
        disabled: true,
        href: "breadcrumbs_dashboard",
      },
    ],
    search: "",
    selectedDpw: "",
    items_dpw: [],
    headers: [
      {
        text: "No",
        align: "start",
        sortable: false,
        value: "no",
      },
      { text: "Nama", value: "nama", sortable: false },
      { text: "DPW", value: "dpw_nama", sortable: false },
      { text: "Alamat", value: "alamat", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
    isFirst: false,
    totalData: 0,
    list_data: [],
    loading: true,
    options: {
      itemsPerPage: 10,
    },

    editedIndex: -1,
    editedItem: {},
    dialog: false,
    dialogDelete: false,
    currentData: {
      id: null,
      nama: "",
      alamat: "",
      selectedDpw: "",
      namaRules: [(v) => !!v || "Nama harus diisi"],
      dpwRules: [(v) => !!v || "DPW harus diisi"],
    },
  }),
  watch: {
    options: {
      handler() {
        if (this.isFirst) {
          this.getDataFromApi();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getListDpw();
    this.getDataFromApi();
  },
  methods: {
    changeDpw() {
      this.getDataFromApi();
    },

    async getListDpw() {
      DPWService.dpwListAll()
        .then((res) => {
          this.items_dpw = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getDataFromApi() {
      const { page, itemsPerPage } = this.options;

      var dpw = "";

      if (this.selectedDpw) {
        dpw = this.selectedDpw.id;
      }

      this.loading = true;
      DPDService.dpdList(page, itemsPerPage, this.search, dpw)
        .then((res) => {
          this.list_data = res.data.list_data;
          this.totalData = res.data.total;
          this.loading = false;
          this.isFirst = true;
        })
        .catch((err) => {
          this.loading = false;
          this.isFirst = true;
          console.log(err);
        });
    },

    doAdd: async function () {
      this.dialog = true;
      this.currentData.nama = "";
      this.currentData.alamat = "";
      this.currentData.selectedDpw = "";
      this.currentData.id = null;
      console.log("do add");
    },

    doSave: async function () {
      if (this.currentData.id == null) {
        DPDService.create(this.currentData)
          .then((res) => {
            console.log(res);
            this.dialog = false;

            CommonService.showSuccessToast("Tambah DPD berhasil");

            this.getDataFromApi();
          })
          .catch((err) => {
            console.log(err);
            CommonService.showErrorToast("Tambah DPD gagal. Coba lagi");
          });
      } else {
        DPDService.update(this.currentData.id, this.currentData)
          .then((res) => {
            this.dialog = false;

            console.log(res);
            CommonService.showSuccessToast("Update DPD berhasil");

            this.getDataFromApi();
          })
          .catch((err) => {
            console.log(err);
            CommonService.showErrorToast("Update DPD gagal. Coba lagi");
          });
      }
    },

    editItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.dialog = true;
      this.currentData.nama = this.editedItem.nama;
      this.currentData.alamat = this.editedItem.alamat;
      this.currentData.id = this.editedItem.id;

      if (this.editedItem.dewan_pengurus_wilayah_id) {
        this.items_dpw.forEach((entry) => {
          if (entry.id == this.editedItem.dewan_pengurus_wilayah_id) {
            this.currentData.selectedDpw = entry;
          }
        });
      }

      console.log("do add");
    },

    deleteItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.closeDelete();

      DPDService.delete(this.editedItem.id)
        .then((res) => {
          console.log(res);

          CommonService.showSuccessToast("Hapus DPD berhasil");

          this.getDataFromApi();
        })
        .catch((err) => {
          console.log(err);
          CommonService.showSuccessToast("Hapus DPD gagal. Coba lagi");
        });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
  },
};
</script>
