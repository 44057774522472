<template>
  <v-main>
    <!-- Provides the application the proper gutter -->
    <v-container fluid>
      <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>

      <!-- If using vue-router -->
      <!-- <router-view></router-view> -->
      <v-card>
        <v-card-title>
          Membership User
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Cari User"
            single-line
            hide-details
            @keyup.enter="getDataFromApi"
          ></v-text-field>
          <v-spacer></v-spacer>

          <v-dialog v-model="dialog" persistent max-width="600px">
            <template #activator="{}">
              <v-btn color="primary" dark @click="doAdd"> Tambah </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">Membership User</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        v-model="currentData.user_nama"
                        label="User"
                        readonly
                        dense
                        @click="openUserModal"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-combobox
                        v-model="currentData.selectedTipeUser"
                        label="Tipe User"
                        clearable
                        dense
                        outlined
                        :items="items_tipe_user"
                        item-text="value"
                      ></v-combobox>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model="currentData.paket_nama"
                        label="Tipe Paket"
                        readonly
                        dense
                        @click="openPaketModal"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model="currentData.tanggal"
                        label="Tanggal Mulai"
                        type="date"
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-combobox
                        v-if="currentData.id != null"
                        v-model="currentData.selectedStatusPaket"
                        label="Status Paket"
                        clearable
                        dense
                        outlined
                        :items="items_status_paket"
                        item-text="value"
                      ></v-combobox>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialog = false">
                  Close
                </v-btn>
                <v-btn color="blue darken-1" text @click="doSave"> Save </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5"
                >Apa anda akan menghapus data ini?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete"
                  >Tidak</v-btn
                >
                <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                  >Ya</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialogBuktiBayar" max-width="500px">
            <v-card>
              <v-card-title class="text-h5">Bukti Bayar</v-card-title>
              <v-card-text>
                <v-img :src="editedItem.bukti_bayar" contain></v-img>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeBuktiBayar"
                  >Keluar</v-btn
                >
                <!-- <v-btn
                color="blue darken-1"
                text
                @click="deleteItemConfirm"
              >Ya</v-btn> -->
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialogKonfirmasi" persistent max-width="600px">
            <v-card>
              <v-card-title class="text-h5"
                >Apa anda akan mengkonfirmasi paket membership peserta
                ini?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="dialogKonfirmasi = false"
                >
                  Close
                </v-btn>
                <v-btn color="blue darken-1" text @click="doKonfirmasi">
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="list_data"
          :options.sync="options"
          :server-items-length="totalData"
          :loading="loading"
          class="elevation-1"
        >
          <template #[`item.tanggal`]="{ item }">
            {{ getCurrentDate(item.tanggal) }}
          </template>
          <template #[`item.status_value`]="{ item }">
            <v-chip :color="getColor(item.status)" dark>
              {{ item.status_value }}
            </v-chip>
          </template>
          <template #[`item.harga`]="{ item }">
            {{ new Intl.NumberFormat("id-ID").format(item.harga) }}
          </template>
          <template #[`item.actions`]="{ item }">
            <v-menu style="margin-left: 20px">
              <template #activator="{ on, attrs }">
                <v-icon medium v-bind="attrs" v-on="on">
                  mdi-dots-vertical
                </v-icon>
              </template>
              <v-list>
                <v-list-item @click="buktiBayar(item)">
                  <v-list-item-title>Bukti Bayar</v-list-item-title>
                </v-list-item>
                <v-list-item
                  v-if="item.status == 2"
                  @click="konfirmasiPembayaran(item)"
                >
                  <v-list-item-title>Konfirmasi Pembayaran</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-icon medium class="mr-3" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon medium @click="deleteItem(item)"> mdi-delete </v-icon>
          </template>
        </v-data-table>
      </v-card>

      <v-card class="mt-4">
        <PaketMembershipDetailComponent
          v-if="selected != undefined && selected.length > 0"
          :id="selected[0].id"
          ref="mychild"
          :key="childKey"
        />
      </v-card>

      <UserSelectComponent
        ref="modalUser"
        v-model="dialogUser"
        @pilihUser="pilihUser"
      />

      <PaketMembershipSelectComponent
        ref="modalPaket"
        v-model="dialogPaket"
        @pilihPaket="pilihPaket"
      />
    </v-container>
  </v-main>
</template>

<script>
import PaketMembershipUserService from "../services/PaketMembershipUserService";
import MasterService from "../services/MasterService";
import CommonService from "../services/CommonService";
import PaketMembershipDetailComponent from "./PaketMembershipDetail.vue";
import * as moment from "moment";

import UserSelectComponent from "../components/UserSelect.vue";
import PaketMembershipSelectComponent from "../components/PaketMembershipSelect.vue";

export default {
  components: {
    PaketMembershipDetailComponent,
    UserSelectComponent,
    PaketMembershipSelectComponent,
  },
  data: () => ({
    dialogUser: false,
    dialogPaket: false,
    dialogBuktiBayar: false,
    dialogKonfirmasi: false,
    selected: [],
    breadcrumbs: [
      {
        text: "Data Paket",
        disabled: true,
        href: "breadcrumbs_dashboard",
      },
    ],
    search: "",
    headers: [
      {
        text: "No",
        align: "start",
        sortable: false,
        value: "no",
      },
      { text: "User", value: "user_nama", sortable: false },
      { text: "Email", value: "email", sortable: false },
      { text: "Tipe User", value: "tipe_user_value", sortable: false },
      {
        text: "Paket Membership",
        value: "paket_membership_value",
        sortable: false,
      },
      {
        text: "Harga",
        value: "harga",
        sortable: false,
      },
      {
        text: "Periode (bulan)",
        value: "periode",
        sortable: false,
      },
      {
        text: "Tanggal Mulai",
        value: "tanggal",
        sortable: false,
      },
      {
        text: "Status",
        value: "status_value",
        sortable: false,
      },
      { text: "Actions", value: "actions", sortable: false },
    ],
    isFirst: false,
    totalData: 0,
    list_data: [],
    loading: true,
    options: {
      itemsPerPage: 10,
    },

    editedIndex: -1,
    editedItem: {},
    dialog: false,
    dialogDelete: false,
    currentData: {
      id: null,
      user_id: "",
      user_nama: "",
      selectedTipePaket: "",
      selectedTipeUser: "",
      selectedStatusPaket: "",
      tanggal: "",
      status: "",
      paket_membership_id: "",
      paket_nama: "",
    },
    items_tipe_paket: [],
    items_tipe_user: [],
    items_status_paket: [],
  }),
  watch: {
    selected: {
      handler() {
        if (this.selected != undefined && this.selected[0]) {
          this.childKey++;
        }
      },
      deep: true,
    },
    options: {
      handler() {
        if (this.isFirst) {
          this.getDataFromApi();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getTipePaketMembership();
    this.getTipeUser();
    this.getStatusPaket();
    this.getDataFromApi();
  },
  methods: {
    getColor(kategori) {
      switch (kategori) {
        case 1:
          return "pink";
        case 2:
          return "green";
        case 3:
          return "cyan";
        case 4:
          return "black";
        case 5:
          return "red";
      }
    },
    getCurrentDate(tanggal) {
      if (tanggal == null) return "";
      return moment(tanggal).format("DD/MM/YYYY");
    },

    async getTipePaketMembership() {
      MasterService.tipePaketMembershipList()
        .then((res) => {
          this.items_tipe_paket = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getTipeUser() {
      MasterService.tipeUserList()
        .then((res) => {
          this.items_tipe_user = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getStatusPaket() {
      MasterService.statusPaketList()
        .then((res) => {
          this.items_status_paket = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getDataFromApi() {
      const { page, itemsPerPage } = this.options;

      this.loading = true;
      PaketMembershipUserService.paketList(page, itemsPerPage, this.search)
        .then((res) => {
          this.list_data = res.data.list_data;
          this.totalData = res.data.total;
          this.loading = false;
          this.isFirst = true;
        })
        .catch((err) => {
          this.loading = false;
          this.isFirst = true;
          console.log(err);
        });
    },

    doAdd: async function () {
      this.dialog = true;
      this.currentData.selectedTipePaket = null;
      this.currentData.selectedTipeUser = null;
      this.currentData.selectedStatusPaket = null;
      this.currentData.tanggal = "";
      this.currentData.status = "";
      this.currentData.user_id = "";
      this.currentData.user_nama = "";
      this.currentData.id = null;
      console.log("do add");
    },

    doSave: async function () {
      if (this.currentData.id == null) {
        PaketMembershipUserService.create(this.currentData)
          .then((res) => {
            console.log(res);
            this.dialog = false;

            CommonService.showSuccessToast("Tambah paket membership berhasil");

            this.getDataFromApi();
          })
          .catch((err) => {
            console.log(err);
            CommonService.showErrorToast(
              "Tambah paket membership gagal. Coba lagi",
            );
          });
      } else {
        PaketMembershipUserService.update(this.currentData.id, this.currentData)
          .then((res) => {
            this.dialog = false;

            console.log(res);
            CommonService.showSuccessToast("Update paket membership berhasil");

            this.getDataFromApi();
          })
          .catch((err) => {
            console.log(err);
            CommonService.showErrorToast(
              "Update paket membership gagal. Coba lagi",
            );
          });
      }
    },

    editItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.dialog = true;
      this.currentData.user_id = this.editedItem.user_id;
      this.currentData.user_nama = this.editedItem.user_nama;
      this.currentData.paket_membership_id =
        this.editedItem.paket_membership_id;
      this.currentData.paket_nama = this.editedItem.paket_membership_value;

      if (this.editedItem.tanggal) {
        this.currentData.tanggal = moment(this.editedItem.tanggal).format(
          "YYYY-MM-DD",
        );
      }

      this.currentData.id = this.editedItem.id;

      if (this.editedItem.tipe_user) {
        this.items_tipe_user.forEach((entry) => {
          if (entry.code == this.editedItem.tipe_user) {
            this.currentData.selectedTipeUser = entry;
          }
        });
      }

      if (this.editedItem.status) {
        this.items_status_paket.forEach((entry) => {
          if (entry.code == this.editedItem.status) {
            this.currentData.selectedStatusPaket = entry;
          }
        });
      }

      console.log("do add");
    },

    deleteItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.closeDelete();

      PaketMembershipUserService.delete(this.editedItem.id)
        .then((res) => {
          console.log(res);

          CommonService.showSuccessToast("Hapus bank berhasil");

          this.getDataFromApi();
        })
        .catch((err) => {
          console.log(err);
          CommonService.showErrorToast("Hapus bank gagal. Coba lagi");
        });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    pilihUser(item) {
      console.log(item);
      this.dialogUser = false;
      this.currentData.user_id = item.id;
      this.currentData.user_nama = item.nama;
    },

    pilihPaket(item) {
      console.log(item);
      this.dialogPaket = false;
      this.currentData.paket_membership_id = item.id;
      this.currentData.paket_nama = item.paket_nama;
    },

    openUserModal: async function () {
      this.$refs.modalUser.getDataFromApi();
      this.dialogUser = true;
    },

    openPaketModal: async function () {
      this.$refs.modalPaket.getDataFromApi();
      this.dialogPaket = true;
    },

    buktiBayar(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogBuktiBayar = true;
    },

    closeBuktiBayar() {
      this.dialogBuktiBayar = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    konfirmasiPembayaran(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogKonfirmasi = true;
    },

    closeKonfirmasiPembayaran() {
      this.dialogKonfirmasi = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    doKonfirmasi() {
      this.closeKonfirmasiPembayaran();

      PaketMembershipUserService.konfirmasiPembayaran(
        this.editedItem.id,
        this.editedItem.user_id,
      )
        .then((res) => {
          console.log(res);

          CommonService.showSuccessToast("Konfirmasi pembayaran berhasil");

          this.getDataFromApi();
        })
        .catch((err) => {
          console.log(err);
          CommonService.showErrorToast(
            "Konfirmasi pembayaran gagal. Coba lagi",
          );
        });
    },
  },
};
</script>
