import http from "../http-common";
/* eslint-disable */
class KelasOnlineService {
  getWebinar(id) {
    return http.get(`/webinar/${id}`, {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  getWebinarExists(id) {
    return http.get(`/webinar-exists/${id}`, {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  create(id, data) {
    var bodyFormData = new FormData();
    bodyFormData.append("pelatihan_detail_id", id);
    if (data.judul) bodyFormData.append("judul", data.judul);
    if (data.judul) bodyFormData.append("url", data.url);
    if (data.deskripsi) bodyFormData.append("deskripsi", data.deskripsi);
    if (data.lokasi) bodyFormData.append("lokasi", data.lokasi);
    if (data.waktu_webinar_awal)
      bodyFormData.append(
        "waktu_webinar_awal",
        data.waktu_webinar_awal + " " + data.jam_webinar_awal
      );
    if (data.waktu_webinar_akhir)
      bodyFormData.append(
        "waktu_webinar_akhir",
        data.waktu_webinar_akhir + " " + data.jam_webinar_akhir
      );
    if (data.is_berakhir)
      bodyFormData.append("is_berakhir", data.is_berakhir.code);
    if (data.penyelenggara)
      bodyFormData.append("penyelenggara", data.penyelenggara);
    if (data.skp_peserta) bodyFormData.append("skp_peserta", data.skp_peserta);
    if (data.link_room) bodyFormData.append("link_room", data.link_room);
    if (data.username_room)
      bodyFormData.append("username_room", data.username_room);
    if (data.password_room)
      bodyFormData.append("password_room", data.password_room);

    return http.post("/webinar", bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  update(data) {
    var bodyFormData = new FormData();
    bodyFormData.append("id", data.id);
    if (data.judul) bodyFormData.append("judul", data.judul);
    if (data.deskripsi) bodyFormData.append("deskripsi", data.deskripsi);
    if (data.lokasi) bodyFormData.append("lokasi", data.lokasi);
    if (data.waktu_webinar_awal)
      bodyFormData.append(
        "waktu_webinar_awal",
        data.waktu_webinar_awal + " " + data.jam_webinar_awal
      );
    if (data.waktu_webinar_akhir)
      bodyFormData.append(
        "waktu_webinar_akhir",
        data.waktu_webinar_akhir + " " + data.jam_webinar_akhir
      );
    if (data.is_berakhir)
      bodyFormData.append("is_berakhir", data.is_berakhir.code);
    if (data.penyelenggara)
      bodyFormData.append("penyelenggara", data.penyelenggara);
    if (data.skp_peserta) bodyFormData.append("skp_peserta", data.skp_peserta);
    if (data.link_room) bodyFormData.append("link_room", data.link_room);
    if (data.username_room)
      bodyFormData.append("username_room", data.username_room);
    if (data.password_room)
      bodyFormData.append("password_room", data.password_room);
    if (data.url) bodyFormData.append("url", data.url);

    return http.put(`/webinar/${data.id}`, bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  delete(id) {
    return http.delete(`/webinar/${id}`, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
}

export default new KelasOnlineService();
