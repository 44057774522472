<template>
  <v-main>
    <!-- Provides the application the proper gutter -->
    <v-container fluid>
      <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>

      <!-- If using vue-router -->
      <!-- <router-view></router-view> -->
      <v-card>
        <v-card-title>
          Paket Membership
          <v-spacer></v-spacer>
          <!-- <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Cari Paket"
            single-line
            hide-details
            @keyup.enter="getDataFromApi"
          ></v-text-field>
          <v-spacer></v-spacer> -->

          <v-dialog v-model="dialog" persistent max-width="600px">
            <template #activator="{}">
              <v-btn color="primary" dark @click="doAdd"> Tambah </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">Paket Membership</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-form
                    ref="form"
                    v-model="valid"
                    lazy-validation
                    class="pl-4 pr-4 pt-4"
                  >
                    <v-row>
                      <v-col cols="12">
                        <v-sheet color="white" elevation="2">
                          <v-responsive :aspect-ratio="16 / 9">
                            <v-img
                              :key="fotoKey"
                              :src="currentData.FotoUrl"
                              contain
                            ></v-img>
                          </v-responsive>
                        </v-sheet>
                        <v-file-input
                          v-model="currentData.file_foto"
                          accept="image/*"
                          prepend-icon="mdi-image"
                          show-size
                          label="Foto"
                          @change="loadImage"
                        />
                      </v-col>

                      <v-col cols="12">
                        <v-text-field
                          v-model="currentData.nama"
                          label="Nama Paket"
                          required
                          :rules="currentData.namaRules"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          v-model="currentData.harga"
                          label="Harga"
                          required
                          :rules="currentData.hargaRules"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          v-model="currentData.periode"
                          label="Periode (bulan)"
                          required
                          :rules="currentData.periodeRules"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-textarea
                          v-model="currentData.deskripsi"
                          outlined
                          name="input-7-4"
                          label="Deskripsi"
                          dense
                          value=""
                        ></v-textarea>
                      </v-col>
                      <v-col cols="12">
                        <v-combobox
                          v-model="currentData.selectedDpw"
                          label="Dewan Pengurus Wilayah"
                          clearable
                          dense
                          outlined
                          :items="currentData.items_dpw"
                          item-text="nama"
                          :disabled="isDpwPengurus"
                          :rules="currentData.dpwRules"
                          @change="changeDpwInput"
                        ></v-combobox>
                      </v-col>
                      <v-col cols="12">
                        <v-combobox
                          v-model="currentData.selectedDpd"
                          label="DPD"
                          clearable
                          dense
                          outlined
                          :items="currentData.items_dpd"
                          item-text="nama"
                          :disabled="isDpdPengurus"
                          :rules="currentData.dpdRules"
                          @click="getListDpdInput"
                        ></v-combobox>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialog = false">
                  Close
                </v-btn>
                <v-btn color="blue darken-1" text @click="doSave"> Save </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5"
                >Apa anda akan menghapus data ini?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete"
                  >Tidak</v-btn
                >
                <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                  >Ya</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card-title>

        <v-row class="mx-4 my-4">
          <v-col cols="12" sm="4" md="4">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Cari Nama"
              single-line
              outlined
              dense
              hide-details
              @keyup.enter="getDataFromApi"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="4" md="4">
            <v-combobox
              v-model="selectedDpw"
              label="Dewan Pengurus Wilayah"
              clearable
              dense
              outlined
              :items="items_dpw"
              item-text="nama"
              :disabled="isDpwPengurus"
              @change="changeDpw"
            ></v-combobox>
          </v-col>
          <v-col cols="12" sm="4" md="4">
            <v-combobox
              v-model="selectedDpd"
              label="Dewan Pengurus Daerah"
              clearable
              dense
              outlined
              :items="items_dpd"
              item-text="nama"
              :disabled="isDpdPengurus"
              @change="changeDpd"
              @click="getListDpd"
            ></v-combobox>
          </v-col>
        </v-row>

        <v-data-table
          v-model="selected"
          :headers="headers"
          :items="list_data"
          :options.sync="options"
          :server-items-length="totalData"
          :loading="loading"
          class="elevation-1"
          :single-select="true"
          show-select
        >
          <template #[`item.harga`]="{ item }">
            {{ new Intl.NumberFormat("id-ID").format(item.harga) }}
          </template>
          <template #[`item.actions`]="{ item }">
            <v-icon medium class="mr-3" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon medium @click="deleteItem(item)"> mdi-delete </v-icon>
          </template>
        </v-data-table>
      </v-card>

      <v-card class="mt-4">
        <PaketMembershipPelatihanComponent
          v-if="selected != undefined && selected.length > 0"
          :id="selected[0].id"
          ref="mychild"
          :key="childKey"
        />
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
import PaketMembershipService from "../services/PaketMembershipService";
// import MasterService from "../services/MasterService";
import CommonService from "../services/CommonService";
import PaketMembershipPelatihanComponent from "./PaketMembershipPelatihan.vue";
import DPWService from "../services/DPWService";
import DPDService from "../services/DPDService";
import store from "../store/index";

export default {
  components: {
    PaketMembershipPelatihanComponent,
  },
  data: () => ({
    valid: true,
    selected: [],
    breadcrumbs: [
      {
        text: "Data Paket",
        disabled: true,
        href: "breadcrumbs_dashboard",
      },
    ],
    search: "",
    selectedDpw: "",
    selectedDpd: "",
    headers: [
      {
        text: "No",
        align: "start",
        sortable: false,
        value: "no",
      },
      { text: "Nama Paket", value: "nama", sortable: false },
      { text: "Harga", value: "harga", sortable: false },
      { text: "Periode (bulan)", value: "periode", sortable: false },
      { text: "DPD", value: "dpd_nama", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
    isFirst: false,
    totalData: 0,
    list_data: [],
    loading: true,
    options: {
      itemsPerPage: 10,
    },

    editedIndex: -1,
    editedItem: {},
    dialog: false,
    dialogDelete: false,
    items_dpd: [],
    currentData: {
      id: null,
      FotoUrl: "",
      file_foto: null,
      selectedTipePaket: "",
      harga: "",
      periode: "",
      nama: "",
      deskripsi: "",
      selectedDpw: "",
      selectedDpd: "",
      items_dpw: [],
      items_dpd: [],
      namaRules: [(v) => !!v || "Nama Paket harus diisi"],
      hargaRules: [(v) => !!v || "Harga Paket harus diisi"],
      periodeRules: [(v) => !!v || "Periode Paket harus diisi"],
      dpwRules: [(v) => !!v || "Dewan Pengurus Wilayah harus diisi"],
      dpdRules: [(v) => !!v || "Dewan Pengurus Daerah harus diisi"],
    },
    // items_tipe_paket: [],
    items_dpw: [],
    fotoKey: 0,
  }),
  computed: {
    getDpwPengurusId() {
      return store.getters.getUser.dpw_id;
    },
    getDpdPengurusId() {
      return store.getters.getUser.dpd_id;
    },
    isDpwPengurus() {
      if (store.getters.getUser.dpw_id) {
        return true;
      } else {
        return false;
      }
    },
    isDpdPengurus() {
      if (store.getters.getUser.dpd_id) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    selected: {
      handler() {
        if (this.selected != undefined && this.selected[0]) {
          this.childKey++;
        }
      },
      deep: true,
    },
    options: {
      handler() {
        if (this.isFirst) {
          this.getDataFromApi();
        }
      },
      deep: true,
    },
  },
  mounted() {
    // this.getTipePaketMembership();
    this.getListDpw();
    this.getListDpwInput();
    this.getDataFromApi();
  },
  methods: {
    loadImage: function (data) {
      console.log("loadImage:", data);
      if (data) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.currentData.FotoUrl = e.target.result;
          this.fotoKey++;
        };
        reader.readAsDataURL(data);
      } else {
        this.currentData.FotoUrl = "";
        this.currentData.file_foto = null;
      }
    },

    changeDpd() {
      this.getDataFromApi();
    },

    changeDpw() {
      this.selectedDpd = null;
      this.getDataFromApi();
    },

    changeDpwInput() {
      this.currentData.selectedDpd = null;
    },

    async getListDpw() {
      DPWService.dpwListAll()
        .then((res) => {
          this.items_dpw = res.data.list_data;

          if (this.getDpwPengurusId) {
            this.items_dpw.forEach((entry) => {
              if (entry.id == this.getDpwPengurusId) {
                this.selectedDpw = entry;

                if (this.getDpdPengurusId) {
                  this.getListDpd();
                } else {
                  this.getDataFromApi();
                }
              }
            });
          } else {
            this.getDataFromApi();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getListDpd() {
      var dpwId = "";

      if (this.selectedDpw) {
        dpwId = this.selectedDpw.id;
      }

      DPDService.dpdListAll(dpwId)
        .then((res) => {
          this.items_dpd = res.data.list_data;

          if (this.getDpdPengurusId) {
            this.items_dpd.forEach((entry) => {
              if (entry.id == this.getDpdPengurusId) {
                this.selectedDpd = entry;
                this.getDataFromApi();
              }
            });
          } else {
            this.getDataFromApi();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getListDpwInput() {
      DPWService.dpwListAll()
        .then((res) => {
          this.currentData.items_dpw = res.data.list_data;

          if (this.getDpwPengurusId) {
            this.items_dpw.forEach((entry) => {
              if (entry.id == this.getDpwPengurusId) {
                this.currentData.selectedDpw = entry;

                if (this.getDpdPengurusId) {
                  this.getListDpdInput();
                }
              }
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getListDpdInput() {
      var dpwId = "";

      if (this.currentData.selectedDpw) {
        dpwId = this.currentData.selectedDpw.id;
      }

      DPDService.dpdListAll(dpwId)
        .then((res) => {
          this.currentData.items_dpd = res.data.list_data;

          if (this.getDpdPengurusId) {
            this.currentData.items_dpd.forEach((entry) => {
              if (entry.id == this.getDpdPengurusId) {
                this.currentData.selectedDpd = entry;
              }
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // async getTipePaketMembership() {
    //   MasterService.tipePaketMembershipList()
    //     .then((res) => {
    //       this.items_tipe_paket = res.data.list_data;
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // },

    getDataFromApi() {
      const { page, itemsPerPage } = this.options;

      this.loading = true;

      var dpw = "";
      var dpd = "";

      if (this.selectedDpw) {
        dpw = this.selectedDpw.id;
      }

      if (this.selectedDpd) {
        dpd = this.selectedDpd.id;
      }

      PaketMembershipService.paketList(
        page,
        itemsPerPage,
        this.search,
        dpw,
        dpd,
      )
        .then((res) => {
          this.list_data = res.data.list_data;
          this.totalData = res.data.total;
          this.loading = false;
          this.isFirst = true;
        })
        .catch((err) => {
          this.loading = false;
          this.isFirst = true;
          console.log(err);
        });
    },

    doAdd: async function () {
      this.dialog = true;
      this.currentData.nama = "";
      this.currentData.deskripsi = "";
      this.currentData.harga = "";
      this.currentData.periode = "";
      this.currentData.selectedDpd = null;
      this.currentData.selectedDpw = null;
      this.currentData.id = null;
      this.currentData.FotoUrl = "";
      this.currentData.file_foto = null;

      if (this.getDpwPengurusId) {
        this.items_dpw.forEach((entry) => {
          if (entry.id == this.getDpwPengurusId) {
            this.currentData.selectedDpw = entry;

            if (this.getDpdPengurusId) {
              this.getListDpdInput();
            }
          }
        });
      }

      console.log("do add");
    },

    doSave: async function () {
      if (this.currentData.id == null) {
        PaketMembershipService.create(this.currentData)
          .then((res) => {
            console.log(res);
            this.dialog = false;

            CommonService.showSuccessToast("Tambah paket membership berhasil");

            this.getDataFromApi();
          })
          .catch((err) => {
            console.log(err);
            CommonService.showErrorToast(
              "Tambah paket membership gagal. Coba lagi",
            );
          });
      } else {
        PaketMembershipService.update(this.currentData.id, this.currentData)
          .then((res) => {
            this.dialog = false;

            console.log(res);
            CommonService.showSuccessToast("Update paket membership berhasil");

            this.getDataFromApi();
          })
          .catch((err) => {
            console.log(err);
            CommonService.showErrorToast(
              "Update paket membership gagal. Coba lagi",
            );
          });
      }
    },

    editItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.dialog = true;
      this.currentData.nama = this.editedItem.nama;
      this.currentData.deskripsi = this.editedItem.deskripsi;
      this.currentData.harga = this.editedItem.harga;
      this.currentData.periode = this.editedItem.periode;
      this.currentData.id = this.editedItem.id;
      this.currentData.FotoUrl = this.editedItem.foto;

      this.currentData.selectedDpd = null;
      this.currentData.selectedDpw = null;

      if (this.editedItem.dewan_pengurus_wilayah_id) {
        this.currentData.items_dpw.forEach((entry) => {
          if (entry.id == this.editedItem.dewan_pengurus_wilayah_id) {
            this.currentData.selectedDpw = entry;
          }
        });

        this.getListDpdAll(
          this.editedItem.dewan_pengurus_wilayah_id,
          this.editedItem.dpd_id,
        );
      }

      console.log("do add");
    },

    async getListDpdAll(dpwId, dpdId) {
      DPDService.dpdListAll(dpwId)
        .then((res) => {
          this.currentData.items_dpd = res.data.list_data;
          if (dpdId) {
            this.currentData.items_dpd.forEach((entry) => {
              if (entry.id == dpdId) {
                this.currentData.selectedDpd = entry;
              }
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    deleteItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.closeDelete();

      PaketMembershipService.delete(this.editedItem.id)
        .then((res) => {
          console.log(res);

          CommonService.showSuccessToast("Hapus Paket Membership berhasil");

          this.getDataFromApi();
        })
        .catch((err) => {
          console.log(err);
          CommonService.showSuccessToast(
            "Hapus Paket Membership gagal. Coba lagi",
          );
        });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
  },
};
</script>
