import http from "../http-common";
/* eslint-disable */
class MutasiService {
  mutasiList(page, itemPerPage, keyword, komisariat) {
    return http.get(
      "/mutasi/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword +
        "&komisariat_id=" +
        komisariat,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      },
    );
  }

  mutasiAnggotaList(page, itemPerPage, keyword, userId) {
    return http.get(
      "/mutasi/anggota/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword +
        "&user_id=" +
        userId,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      },
    );
  }

  getAnggota(id) {
    return http.get(`/mutasi/anggota/${id}`, {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  create(data) {
    var bodyFormData = new FormData();
    bodyFormData.append("user_id", data.user_id);
    bodyFormData.append("komisariat_asal", data.komisariat_asal);
    bodyFormData.append("langsung_mutasi", data.langsung_mutasi);

    if (
      data.selectedKomisariatTujuan &&
      data.selectedKomisariatTujuan != undefined
    ) {
      bodyFormData.append(
        "komisariat_tujuan",
        data.selectedKomisariatTujuan.id,
      );
    }

    return http.post("/mutasi", bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  update(data) {
    var bodyFormData = new FormData();
    bodyFormData.append("id", data.id);

    if (
      data.selectedKomisariatTujuan &&
      data.selectedKomisariatTujuan != undefined
    ) {
      bodyFormData.append(
        "komisariat_tujuan",
        data.selectedKomisariatTujuan.id,
      );
    }

    return http.put(`/mutasi/${data.id}`, bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  delete(id) {
    return http.delete(`/mutasi/${id}`, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  approve(id, data) {
    var bodyFormData = new FormData();
    bodyFormData.append("id", id);

    return http.put(`/mutasi/approve/${id}`, bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
}

export default new MutasiService();
