import http from "../http-common";
/* eslint-disable */
class KegiatanPesertaDashboardService {
  kegiatanPesertaList(page, itemPerPage, keyword, status) {
    return http.get(
      "/dashboard/kegiatan-peserta/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword +
        "&status=" +
        status,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  kegiatanNonPesertaList(page, itemPerPage, keyword, kegiatanId) {
    return http.get(
      "/kegiatan-non-peserta/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword +
        "&kegiatan_id=" +
        kegiatanId,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  getKegiatan(id) {
    return http.get(`/kegiatan/${id}`, {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  create(data) {
    return http.post("/kegiatan-peserta", data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  konfirmasiPeserta(data) {
    return http.put(`/kegiatan-konfirmasi-peserta/${data.id}`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  gantiPassword(data) {
    return http.put(`/kegiatan-peserta/ganti-password/${data.id}`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  absensi(data) {
    return http.put(`/kegiatan-peserta/absensi/${data.id}`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  delete(id) {
    return http.delete(`/kegiatan-peserta/${id}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }
}

export default new KegiatanPesertaDashboardService();
