<template>
  <v-main>
    <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>
    <v-container fluid>
      <v-card>
        <v-card-title> User </v-card-title>
        <form class="pl-4 pr-4">
          <v-row dense>
            <v-col cols="12" sm="6" md="6" class="pr-4">
              <v-sheet color="white" elevation="2">
                <v-responsive :aspect-ratio="16 / 9">
                  <v-img :key="fotoKey" :src="userData.FotoUrl" contain></v-img>
                </v-responsive>
              </v-sheet>
              <v-file-input
                v-model="userData.file_foto"
                accept="image/*"
                prepend-icon="mdi-image"
                show-size
                label="Foto"
                @change="loadImage"
              />
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-row dense>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    v-model="userData.nama"
                    label="Nama"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    v-model="userData.phone"
                    label="No Telepon"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    v-model="userData.email"
                    label="Email"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" sm="6" md="6">
                  <v-combobox
                    v-model="selectedPendidikan"
                    label="Pendidikan"
                    clearable
                    dense
                    outlined
                    :items="items_pendidikan"
                    item-text="value"
                  ></v-combobox>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="d-flex justify-end">
              <v-btn style="margin-right: 10px" @click="doBatal">Batal</v-btn>
              <v-btn dark color="primary" type="button" @click="doSubmit"
                >Simpan</v-btn
              >
            </v-col>
          </v-row>
        </form>
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
import UserService from "../services/UserService";

export default {
  name: "UserAdd",
  components: {},
  data: () => ({
    breadcrumbs: [
      {
        text: "Data User",
        disabled: false,
        exact: true,
        to: { name: "User" },
      },
      { text: "Tambah User", disabled: true },
    ],
    userData: {
      FotoUrl: "",
      file_foto: null,
      nama: "",
      email: "",
      phone: "",
      profesi_id: "",
      str: "",
      sip: "",
      status_profesi: "",
      pendidikan_id: "",
    },
    selectedProfesi: "",
    selectedPendidikan: "",
    items_profesi: [],
    items_pendidikan: [],
    fotoKey: 0,
  }),
  computed: {},
  mounted: async function () {
    this.getListProfesi();
    this.getListPendidikan();
  },
  methods: {
    loadImage: function (data) {
      console.log("loadImage:", data);
      if (data) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.userData.FotoUrl = e.target.result;
          this.fotoKey++;
        };
        reader.readAsDataURL(data);
      } else {
        this.userData.FotoUrl = "";
        this.userData.file_foto = null;
      }
    },

    async getListProfesi() {
      UserService.profesiList()
        .then((res) => {
          this.items_profesi = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getListPendidikan() {
      UserService.pendidikanList()
        .then((res) => {
          this.items_pendidikan = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    doBatal: function () {
      this.$router.go(-1);
    },

    doSubmit: async function () {
      if (this.selectedProfesi && this.selectedProfesi != undefined) {
        this.userData.profesi_id = this.selectedProfesi.code;
      }
      if (this.selectedPendidikan && this.selectedPendidikan != undefined) {
        this.userData.pendidikan_id = this.selectedPendidikan.code;
      }
      UserService.create(this.userData, this.userData.file_foto)
        .then((res) => {
          console.log(res);
          this.$toast.success("Tambah User berhasil", {
            position: "top-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });

          this.$router.push("/user-edit/" + res.data.user.id).catch((err) => {
            console.log(err);
          });
        })
        .catch((err) => {
          console.log(err);
          this.$toast.error("Tambah user gagal. Coba lagi", {
            position: "top-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        });
    },
  },
};
</script>

<style scoped></style>
