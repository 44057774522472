import http from "../http-common";
/* eslint-disable */
class UjianPilihanService {
  ujianPilihanList(page, itemPerPage, keyword, ujianId) {
    return http.get(
      "/ujian-pilihan/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword +
        "&ujian_id=" +
        ujianId,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  create(data) {
    return http.post("/ujian-pilihan", data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  update(data) {
    return http.put(`/ujian-pilihan/${data.id}`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  delete(id) {
    return http.delete(`/ujian-pilihan/${id}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  setJawabanBenar(data) {
    return http.post(`/set-jawaban-benar`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }
}

export default new UjianPilihanService();
