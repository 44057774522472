<template>
  <div>
    <v-card>
      <v-card-title>
        Pelatihan Terdaftar
        <v-spacer></v-spacer>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5"
              >Apa anda akan menghapus pelatihan ini?</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete"
                >Tidak</v-btn
              >
              <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                >Ya</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card-title>

      <v-row class="mx-4 my-4">
        <v-col cols="12" sm="4" md="4">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Cari Nama"
            single-line
            outlined
            dense
            hide-details
            @keyup.enter="getDataFromApi"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="list_data"
        :options.sync="options"
        :server-items-length="totalData"
        :loading="loading"
        class="elevation-1"
      >
        <template #[`item.tanggal_awal`]="{ item }">
          {{ getCurrentDate(item.tanggal_awal) }}
        </template>
        <template #[`item.tanggal_akhir`]="{ item }">
          {{ getCurrentDate(item.tanggal_akhir) }}
        </template>
        <template #[`item.harga`]="{ item }">
          {{ new Intl.NumberFormat("id-ID").format(item.harga) }}
        </template>
        <template #[`item.data_status`]="{ item }">
          <div v-if="item.data_status == 0">Unpublished</div>
          <div v-else>Published</div>
        </template>
        <template #[`item.actions`]="{ item }">
          <v-icon medium class="mr-3" @click="editItem(item)">
            mdi-pencil
          </v-icon>
          <v-icon medium @click="deleteItem(item)"> mdi-delete </v-icon>
        </template>
      </v-data-table>
    </v-card>

    <v-card class="mt-4">
      <PaketMembershipPelatihanNonComponent
        ref="mychild"
        :key="childKey"
        :refresh="getDataFromApi"
        :id="id"
      />
    </v-card>
  </div>
</template>

<script>
import PaketMembershipPelatihanService from "../services/PaketMembershipPelatihanService";
import CommonService from "../services/CommonService";
// import KomisariatService from "../services/KomisariatService";
import PaketMembershipPelatihanNonComponent from "./PaketMembershipPelatihanNon.vue";
// import store from "../store/index";
// import DPWService from "../services/DPWService";
// import DPDService from "../services/DPDService";
import * as moment from "moment";

export default {
  components: {
    PaketMembershipPelatihanNonComponent,
  },
  props: {
    id: Number,
  },
  data: () => ({
    childKey: 0,
    selected: [],
    search: "",
    // selectedDpw: "",
    // selectedDpd: "",
    // selectedKomisariat: "",
    // items_komisariat: [],
    // items_dpw: [],
    // items_dpd: [],
    headers: [
      {
        text: "No",
        align: "start",
        sortable: false,
        value: "no",
      },
      { text: "Judul", value: "kegiatan_title", sortable: false },
      { text: "Kategori", value: "kategori_name", sortable: false },
      { text: "DPD", value: "dpd_name", sortable: false },
      { text: "Tanggal Awal", value: "tanggal_awal", sortable: false },
      { text: "Tanggal Akhir", value: "tanggal_akhir", sortable: false },
      { text: "Harga", value: "harga", sortable: false },
      { text: "Status", value: "data_status", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
    isFirst: false,
    totalData: 0,
    list_data: [],
    loading: true,
    options: {
      itemsPerPage: 5,
    },

    editedIndex: -1,
    editedItem: {},
    dialogDelete: false,
  }),
  computed: {
    // getDpwPengurusId() {
    //   return store.getters.getUser.dpw_id;
    // },
    // getDpdPengurusId() {
    //   return store.getters.getUser.dpd_id;
    // },
    // getKomisariatId() {
    //   return store.getters.getUser.komisariat_id;
    // },
  },
  watch: {
    selected: {
      handler() {
        if (this.selected != undefined && this.selected[0]) {
          this.childKey++;
        }
      },
      deep: true,
    },
    options: {
      handler() {
        if (this.isFirst) {
          this.getDataFromApi();
        }
      },
      deep: true,
    },
  },
  mounted() {
    // this.getListKomisariat();
    // this.getListDpw();
    this.getDataFromApi();
  },
  methods: {
    getCurrentDate(tanggal) {
      return moment(tanggal).format("DD/MM/YYYY");
    },
    // changeDpw() {
    //   this.selectedDpd = null;
    //   this.selectedKomisariat = null;
    //   this.getDataFromApi();
    // },

    // changeDpd() {
    //   this.selectedKomisariat = null;
    //   this.getDataFromApi();
    // },

    // async getListDpw() {
    //   DPWService.dpwListAll()
    //     .then((res) => {
    //       this.items_dpw = res.data.list_data;

    //       if (this.getDpwPengurusId) {
    //         this.items_dpw.forEach((entry) => {
    //           if (entry.id == this.getDpwPengurusId) {
    //             this.selectedDpw = entry;

    //             if (this.getDpdPengurusId) {
    //               this.getListDpd();
    //             } else {
    //               this.getDataFromApi();
    //             }
    //           }
    //         });
    //       } else {
    //         this.getDataFromApi();
    //       }
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // },

    // async getListDpd() {
    //   var dpwId = "";

    //   if (this.selectedDpw) {
    //     dpwId = this.selectedDpw.id;
    //   }

    //   DPDService.dpdListAll(dpwId)
    //     .then((res) => {
    //       this.items_dpd = res.data.list_data;

    //       if (this.getDpdPengurusId) {
    //         this.items_dpd.forEach((entry) => {
    //           if (entry.id == this.getDpdPengurusId) {
    //             this.selectedDpd = entry;
    //             this.getDataFromApi();
    //           }
    //         });
    //       } else {
    //         this.getDataFromApi();
    //       }
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // },
    // changeKomisariat() {
    //   this.getDataFromApi();
    // },

    // async getListKomisariat() {
    //   KomisariatService.komisariatListAllFilter(this.selectedDpd.id)
    //     .then((res) => {
    //       this.items_komisariat = res.data.list_data;

    //       if (this.getKomisariatId) {
    //         this.items_komisariat.forEach((entry) => {
    //           if (entry.id == this.getKomisariatId) {
    //             this.selectedKomisariat = entry;
    //             this.getDataFromApi();
    //           }
    //         });
    //       } else {
    //         this.getDataFromApi();
    //       }
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // },

    getDataFromApi() {
      const { page, itemsPerPage } = this.options;

      this.loading = true;
      PaketMembershipPelatihanService.kegiatanList(
        page,
        itemsPerPage,
        this.search,
        this.id,
      )
        .then((res) => {
          this.list_data = res.data.list_data;
          this.totalData = res.data.total;
          this.loading = false;
          this.isFirst = true;
        })
        .catch((err) => {
          this.loading = false;
          this.isFirst = true;
          console.log(err);
        });
    },

    editItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.$router.push("/kegiatan-edit/" + item.kegiatan_id).catch((err) => {
        console.log(err);
      });
    },

    deleteItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.closeDelete();

      PaketMembershipPelatihanService.delete(
        this.id,
        this.editedItem.kegiatan_id,
      )
        .then((res) => {
          console.log(res);
          CommonService.showSuccessToast("Hapus pelatihan berhasil");

          this.getDataFromApi();
          this.$refs.mychild.getDataFromApi();
        })
        .catch((err) => {
          console.log(err);

          CommonService.showErrorToast("Hapus pelatihan gagal. Coba lagi");
        });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
  },
};
</script>
