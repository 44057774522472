import http from "../http-common";
/* eslint-disable */
class PeminatanService {
  peminatanList(page, itemPerPage, keyword, userId) {
    return http.get(
      "/nakes/peminatan/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword +
        "&user_id=" +
        userId,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  delete(id) {
    return http.delete(`/nakes/peminatan/${id}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }
}

export default new PeminatanService();
