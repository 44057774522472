import http from "../http-common";
/* eslint-disable */
class PekerjaanService {
  pekerjaanList(page, itemPerPage, keyword, userId) {
    return http.get(
      "/nakes/pekerjaan/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword +
        "&user_id=" +
        userId,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  create(data) {
    if (data.mulai_kerja) {
      data.mulai_kerja = data.mulai_kerja + "-01";
    }

    if (data.sampai_saat_ini) {
      data.akhir_kerja = null;
    } else {
      if (data.akhir_kerja) {
        data.akhir_kerja = data.akhir_kerja + "-01";
      } else {
        data.akhir_kerja = null;
      }
    }

    return http.post("/nakes/pekerjaan", data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  update(data) {
    if (data.mulai_kerja) {
      data.mulai_kerja = data.mulai_kerja + "-01";
    }

    if (data.sampai_saat_ini) {
      data.akhir_kerja = null;
    } else {
      if (data.akhir_kerja) {
        data.akhir_kerja = data.akhir_kerja + "-01";
      } else {
        data.akhir_kerja = null;
      }
    }

    return http.put(`/nakes/pekerjaan/${data.id}`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  delete(id) {
    return http.delete(`/nakes/pekerjaan/${id}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }
}

export default new PekerjaanService();
