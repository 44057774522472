<template>
  <v-main>
    <!-- Provides the application the proper gutter -->
    <v-container fluid>
      <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>

      <!-- If using vue-router -->
      <!-- <router-view></router-view> -->
      <v-tabs v-model="tab">
        <v-tab>Nakes</v-tab>
        <v-tab>Layanan</v-tab>
        <v-tab-item>
          <v-card>
            <v-card-title>
              Nakes <br />

              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Cari Nama"
                single-line
                hide-details
                @keyup.enter="getDataFromApi"
              ></v-text-field>
              <v-spacer></v-spacer>

              <v-btn color="purple" dark class="mb-2" @click="openTambah">
                Tambah
              </v-btn>
              <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                  <v-card-title class="text-h5"
                    >Apa anda akan menghapus data ini?</v-card-title
                  >
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDelete"
                      >Tidak</v-btn
                    >
                    <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                      >Ya</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-card-title>
            <v-card-title>
              <v-row>
                <h6 class="ml-3">Faskes {{ currentFaskes.nama }}</h6>
              </v-row>
            </v-card-title>
            <v-data-table
              :headers="headers"
              :items="list_data"
              :options.sync="options"
              :server-items-length="totalData"
              :loading="loading"
              class="elevation-1"
            >
              <template #[`item.actions`]="{ item }">
                <v-icon medium @click="deleteItem(item)"> mdi-delete </v-icon>
              </template>
            </v-data-table>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <FaskesLayananComponent :id="id" />
        </v-tab-item>
      </v-tabs>

      <!-- Modal -->
      <v-dialog v-model="dialogNakes" max-width="800px">
        <v-card>
          <v-card-title>
            Nakes
            <v-spacer></v-spacer>
            <v-text-field
              v-model="searchNakes"
              append-icon="mdi-magnify"
              label="Cari Nama"
              single-line
              hide-details
              @keyup.enter="getNakesFromApi"
            ></v-text-field>
            <v-spacer></v-spacer>
          </v-card-title>
          <v-data-table
            :headers="headersNakes"
            :items="list_data_nakes"
            :options.sync="optionsNakes"
            :server-items-length="totalDataNakes"
            :loading="loading"
            class="elevation-1"
          >
            <template #[`item.actions`]="{ item }">
              <v-btn
                class="ma-2"
                color="primary"
                dark
                @click="pilihNakes(item)"
              >
                Pilih
                <v-icon dark right> mdi-checkbox-marked-circle </v-icon>
              </v-btn>
            </template>
          </v-data-table>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialogNakes = false">
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </v-main>
</template>

<script>
import FaskesService from "../services/FaskesService";
import FaskesLayananComponent from "./FaskesLayanan.vue";

export default {
  name: "FaskesLayanan",
  components: {
    FaskesLayananComponent,
  },
  props: {
    id: {
      type: String,
      default: "0",
    },
  },
  data: () => ({
    tab: null,
    breadcrumbs: [
      {
        text: "Faskes",
        disabled: false,
        exact: true,
        to: { name: "Faskes" },
      },
      {
        text: "Data Faskes",
        disabled: true,
        href: "breadcrumbs_dashboard",
      },
    ],
    search: "",
    headers: [
      {
        text: "No",
        align: "start",
        sortable: false,
        value: "no",
      },
      { text: "Nama", value: "nama", sortable: false },
      { text: "No Telp", value: "phone", sortable: false },
      { text: "Email", value: "email", sortable: false },
      { text: "Profesi", value: "profesi_name", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
    isFirst: false,
    totalData: 0,
    list_data: [],
    loading: true,
    options: {
      itemsPerPage: 5,
    },

    editedIndex: -1,
    editedItem: {},
    dialogDelete: false,

    currentFaskes: {
      nama: "",
    },

    headersNakes: [
      {
        text: "No",
        align: "start",
        sortable: false,
        value: "no",
      },
      { text: "Nama", value: "nama", sortable: false },
      { text: "No Telp", value: "phone", sortable: false },
      { text: "Email", value: "email", sortable: false },
      { text: "Profesi", value: "profesi_name", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],

    dialogNakes: false,
    searchNakes: "",
    optionsNakes: {
      itemsPerPage: 5,
    },
    list_data_nakes: [],
    totalDataNakes: 0,
    loadingNakes: true,
  }),
  computed: {},
  watch: {
    options: {
      handler() {
        if (this.isFirst) {
          this.getDataFromApi();
        }
      },
      deep: true,
    },
    optionsNakes: {
      handler() {
        this.getNakesFromApi();
      },
      deep: true,
    },
  },
  mounted() {
    this.getDataFromApi();
    this.getFaskes();
  },
  methods: {
    async getDataFromApi() {
      const { page, itemsPerPage } = this.options;

      this.loading = true;
      FaskesService.faskesNakesList(page, itemsPerPage, this.search, this.id)
        .then((res) => {
          this.list_data = res.data.list_data;
          this.totalData = res.data.total;
          this.loading = false;
          this.isFirst = true;
        })
        .catch((err) => {
          this.loading = false;
          this.isFirst = true;
          console.log(err);
        });
    },

    getNakesFromApi() {
      const { page, itemsPerPage } = this.optionsNakes;

      this.loadingNakes = true;
      FaskesService.faskesNakesOptionList(
        page,
        itemsPerPage,
        this.searchNakes,
        this.id,
      )
        .then((res) => {
          this.list_data_nakes = res.data.list_data;
          this.totalDataNakes = res.data.total;
          this.loadingNakes = false;
        })
        .catch((err) => {
          this.loadingNakes = false;
          console.log(err);
        });
    },

    openTambah() {
      this.dialogNakes = true;
      this.getNakesFromApi();
      this.optionsNakes.page = 1;
    },

    async getFaskes() {
      FaskesService.getFaskes(this.id)
        .then((res) => {
          this.currentFaskes = res.data.faskes;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    pilihNakes: function (item) {
      FaskesService.addNakes(this.id, item.id)
        .then((res) => {
          console.log(res);
          this.$toast.success("Tambah Nakes berhasil", {
            position: "top-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });

          this.getDataFromApi();
        })
        .catch((err) => {
          console.log(err);
          this.$toast.error("Tambah Nakes gagal. Coba lagi", {
            position: "top-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        });

      this.dialogNakes = false;
    },

    deleteItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.closeDelete();

      FaskesService.deleteNakes(this.id, this.editedItem.id)
        .then((res) => {
          console.log(res);
          this.$toast.success("Hapus Nakes berhasil", {
            position: "top-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });

          this.getDataFromApi();
        })
        .catch((err) => {
          console.log(err);
          this.$toast.error("Hapus Nakes gagal. Coba lagi", {
            position: "top-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
  },
};
</script>
