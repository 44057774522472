<template>
  <div>
    <v-card>
      <v-card-title>
        Peserta <br />

        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Cari nama"
          single-line
          hide-details
          @keyup.enter="getDataFromApi"
        ></v-text-field>
        <v-spacer></v-spacer>
        <v-combobox
          v-model="selectedStatus"
          label="Status"
          clearable
          dense
          outlined
          :items="items_status"
          item-text="value"
          class="mt-4"
          @change="getDataFromApi"
        ></v-combobox>
        <v-spacer></v-spacer>

        <!-- <v-btn
          color="purple"
          dark
          class="mb-2"
          @click="openTambah"
        >
          Tambah
        </v-btn> -->
        <v-dialog v-model="dialog" persistent max-width="600px">
          <v-card>
            <v-card-title class="text-h5"
              >Apa anda akan mengkonfirmasi peserta ini?</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialog = false">
                Close
              </v-btn>
              <v-btn color="blue darken-1" text @click="doKonfirmasi">
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5"
              >Apa anda akan menghapus Peserta ini?</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete"
                >Tidak</v-btn
              >
              <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                >Ya</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialogBuktiBayar" max-width="500px">
          <v-card>
            <v-card-title class="text-h5">Bukti Bayar</v-card-title>
            <v-card-text>
              <v-img :src="editedItem.bukti_bayar" contain></v-img>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeBuktiBayar"
                >Keluar</v-btn
              >
              <!-- <v-btn
                color="blue darken-1"
                text
                @click="deleteItemConfirm"
              >Ya</v-btn> -->
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialogGantiPassword" max-width="500px">
          <v-card>
            <v-card-title class="text-h5">Ganti Password</v-card-title>
            <v-card-text>
              <v-row dense>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    v-model="currentData.password"
                    label="Password Baru"
                    outlined
                    dense
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeGantiPassword"
                >Batal</v-btn
              >
              <v-btn
                color="blue darken-1"
                text
                @click="gantiPasswordItemConfirm"
                >Ganti</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialogAbsensi" max-width="500px">
          <v-card>
            <v-card-title class="text-h5">Set peserta hadir?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeAbsensi"
                >Tidak</v-btn
              >
              <v-btn color="blue darken-1" text @click="absensiItemConfirm"
                >Ya</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card-title>
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="list_data"
        :options.sync="options"
        :server-items-length="totalData"
        :loading="loading"
        :single-select="true"
        show-select
        class="elevation-1"
      >
        <template #[`item.status_pendaftaran_value`]="{ item }">
          <v-chip :color="getColor(item.status_pendaftaran)" dark>
            {{ item.status_pendaftaran_value }}
          </v-chip>
        </template>
        <template #[`item.tanggal`]="{ item }">
          {{ getCurrentDate(item.tanggal) }}
        </template>
        <template #[`item.absensi`]="{ item }">
          <v-chip v-if="item.absensi == 1" color="green" dark>
            {{ getAbsensi(item.absensi) }}
          </v-chip>
        </template>
        <template #[`item.actions`]="{ item }">
          <v-menu style="margin-left: 20px">
            <template #activator="{ on, attrs }">
              <v-icon medium v-bind="attrs" v-on="on">
                mdi-dots-vertical
              </v-icon>
            </template>
            <v-list>
              <v-list-item @click="detailUser(item)">
                <v-list-item-title>Detail</v-list-item-title>
              </v-list-item>
              <v-list-item @click="buktiBayar(item)">
                <v-list-item-title>Bukti Bayar</v-list-item-title>
              </v-list-item>
              <v-list-item @click="gantiPassword(item)">
                <v-list-item-title>Ganti Password</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-icon
            v-if="item.status_pendaftaran == 2"
            medium
            class="mr-3"
            @click="editItem(item)"
          >
            mdi-pencil
          </v-icon>
          <v-icon medium @click="deleteItem(item)"> mdi-delete </v-icon>
        </template>
      </v-data-table>
    </v-card>

    <!-- Modal -->
  </div>
</template>

<script>
import KegiatanPesertaService from "../../services/KegiatanPesertaDashboardService";
import * as moment from "moment";
import CommonService from "../../services/CommonService";

export default {
  data: () => ({
    childKey: 0,
    isEditable: 0,
    search: "",
    selected: [],
    headers: [
      {
        text: "No",
        align: "start",
        sortable: false,
        value: "no",
      },
      { text: "Nama", value: "nama", sortable: false },
      { text: "Kegiatan", value: "kegiatan_title", sortable: false },
      { text: "No Telp", value: "phone", sortable: false },
      { text: "Status", value: "status_pendaftaran_value", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
    isFirst: false,
    totalData: 0,
    list_data: [],
    loading: true,
    options: {
      itemsPerPage: 5,
      page: 1,
    },

    editedIndex: -1,
    editedItem: {},
    dialog: false,
    dialogDelete: false,
    dialogBuktiBayar: false,
    dialogGantiPassword: false,
    dialogAbsensi: false,

    currentData: {
      kegiatan_id: "",
      id: null,
      title: "",
      tanggal: "",
      jam_awal: "",
      jam_akhir: "",
      titleRules: [(v) => !!v || "Judul harus diisi"],
      tanggalRules: [(v) => !!v || "Tanggal harus diisi"],
      jamAwalRules: [(v) => !!v || "Jam Awal harus diisi"],
      jamAkhirRules: [(v) => !!v || "Jam Akhir harus diisi"],
      password: "",
    },
    selectedStatus: null,
    items_status: [
      {
        code: 1,
        value: "Menunggu Pembayaran",
      },
      {
        code: 2,
        value: "Menunggu Konfirmasi",
      },
    ],
  }),
  watch: {
    selected: {
      handler() {
        if (this.selected != undefined && this.selected[0]) {
          this.childKey++;
        }
      },
      deep: true,
    },
    options: {
      handler() {
        if (this.isFirst) {
          this.getDataFromApi();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getDataFromApi();
  },
  methods: {
    getColor(kategori) {
      switch (kategori) {
        case 1:
          return "pink";
        case 2:
          return "green";
        case 3:
          return "cyan";
        case 4:
          return "blue";
        case 5:
          return "cyan";
      }
    },

    getCurrentDate(tanggal) {
      return moment(tanggal).format("DD/MM/YYYY");
    },

    getAbsensi(absen) {
      console.log(absen);
      if (absen) {
        if (absen == 1) {
          return "Hadir";
        } else {
          return "";
        }
      } else {
        return "";
      }
    },

    async getDataFromApi() {
      const { page, itemsPerPage } = this.options;

      this.loading = true;

      var param = null;

      if (this.selectedStatus) {
        param = this.selectedStatus.code;
      }

      KegiatanPesertaService.kegiatanPesertaList(
        page,
        itemsPerPage,
        this.search,
        param,
      )
        .then((res) => {
          this.list_data = res.data.list_data;
          this.totalData = res.data.total;
          this.loading = false;
          this.isFirst = true;
        })
        .catch((err) => {
          this.loading = false;
          this.isFirst = true;
          console.log(err);
        });
    },

    editItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.dialog = true;
      console.log(this.editedItem);
      // this.currentData = this.editedItem;
      this.currentData.tanggal = moment(this.editedItem.tanggal).format(
        "YYYY-MM-DD",
      );

      console.log(this.editedItem.kegiatan_id);
      this.currentData.id = this.editedItem.id;
      this.currentData.kegiatan_id = this.editedItem.kegiatan_id;
      this.currentData.user_id = this.editedItem.user_id;

      this.isEditable = 1;
    },

    doKonfirmasi: async function () {
      KegiatanPesertaService.konfirmasiPeserta(this.currentData)
        .then((res) => {
          this.dialog = false;

          console.log(res);

          CommonService.showSuccessToast("Peserta berhasil dikonfirmasi");

          this.getDataFromApi();
        })
        .catch((err) => {
          console.log(err);

          CommonService.showErrorToast("Peserta gagal dikonfirmasi. Coba lagi");
        });
    },

    deleteItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    detailUser(item) {
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.$router.push("/nakes-edit/" + item.user_id).catch((err) => {
        console.log(err);
      });
    },

    buktiBayar(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogBuktiBayar = true;
    },

    gantiPassword(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.currentData.id = this.editedItem.user_id;
      this.dialogGantiPassword = true;
    },

    deleteItemConfirm() {
      this.closeDelete();

      KegiatanPesertaService.delete(this.editedItem.id)
        .then((res) => {
          console.log(res);
          CommonService.showSuccessToast("Hapus peserta berhasil");

          this.getDataFromApi();
          this.$refs.mychild.getDataFromApi();
        })
        .catch((err) => {
          console.log(err);
          CommonService.showErrorToast("Hapus peserta gagal. Coba lagi");
        });
    },

    gantiPasswordItemConfirm() {
      this.closeGantiPassword();

      KegiatanPesertaService.gantiPassword(this.currentData)
        .then((res) => {
          console.log(res);
          CommonService.showSuccessToast("Ganti Password berhasil");

          this.getDataFromApi();
        })
        .catch((err) => {
          console.log(err);
          CommonService.showErrorToast("Ganti Password gagal. Coba lagi");
        });
    },

    absensiItemConfirm() {
      this.closeAbsensi();

      KegiatanPesertaService.absensi(this.currentData)
        .then((res) => {
          console.log(res);
          CommonService.showSuccessToast("Peserta hadir berhasil");

          this.getDataFromApi();
        })
        .catch((err) => {
          console.log(err);
          CommonService.showErrorToast("Peserta hadir gagal. Coba lagi");
        });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeBuktiBayar() {
      this.dialogBuktiBayar = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeGantiPassword() {
      this.dialogGantiPassword = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeAbsensi() {
      this.dialogAbsensi = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
  },
};
</script>
