<template>
  <v-main>
    <!-- Provides the application the proper gutter -->
    <v-container fluid>
      <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>

      <!-- If using vue-router -->
      <!-- <router-view></router-view> -->
      <v-card>
        <v-card-title>
          Chat List
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Cari Pesan"
            single-line
            hide-details
            @keyup.enter="getDataFromApi"
          ></v-text-field>
          <v-spacer></v-spacer>

          <!-- <router-link
            to="/user-add"
            style="text-decoration: none; color: inherit;"
          >
            <v-btn
              color="purple"
              dark
              class="mb-2"
            >
              Tambah
            </v-btn>
          </router-link> -->
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5"
                >Apa anda akan menghapus data ini?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete"
                  >Tidak</v-btn
                >
                <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                  >Ya</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card-title>
        <v-card-title>
          <v-row dense>
            <v-card-text style="font-weight: bold"
              >Nakes : {{ nakes_nama }}
              <br />
              Pasien : {{ patient_nama }}
            </v-card-text>
          </v-row>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="list_data"
          :options.sync="options"
          :server-items-length="totalData"
          :loading="loading"
          class="elevation-1"
        >
        </v-data-table>
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
import ChatsService from "../services/ChatsService";
import UserService from "../services/UserService";
import * as moment from "moment";

export default {
  props: {
    nakesId: {
      type: String,
      default: "0",
    },
    patientId: {
      type: String,
      default: "0",
    },
  },
  data: () => ({
    nakes_nama: "",
    patient_nama: "",
    breadcrumbs: [
      {
        text: "Data Chat List",
        disabled: true,
        href: "breadcrumbs_dashboard",
      },
    ],
    search: "",
    headers: [
      {
        text: "No",
        align: "start",
        sortable: false,
        value: "no",
      },
      { text: "Pengirim", value: "sender_nama", sortable: false },
      { text: "Pesan", value: "message", sortable: false },
      { text: "Waktu", value: "created_at", sortable: false },
    ],
    isFirst: false,
    totalData: 0,
    list_data: [],
    loading: true,
    options: {
      itemsPerPage: 5,
    },

    editedIndex: -1,
    editedItem: {},
    dialogDelete: false,
  }),

  watch: {
    options: {
      handler() {
        if (this.isFirst) {
          this.getDataFromApi();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getDataFromApi();
    this.getUser(this.nakesId, 1);
    this.getUser(this.patientId, 0);
  },
  methods: {
    async getDataFromApi() {
      const { page, itemsPerPage } = this.options;

      this.loading = true;
      ChatsService.chatListUser(
        page,
        itemsPerPage,
        this.search,
        this.nakesId,
        this.patientId,
      )
        .then((res) => {
          res.data.list_data.forEach((entry) => {
            console.log(entry.created_at);
            entry.created_at = moment(entry.created_at).format(
              "DD/MM/YYYY HH:mm",
            );
          });

          this.list_data = res.data.list_data;
          this.totalData = res.data.total;
          this.loading = false;
          this.isFirst = true;
        })
        .catch((err) => {
          this.loading = false;
          this.isFirst = true;
          console.log(err);
        });
    },

    async getUser(myid, isNakes) {
      UserService.getUser(myid)
        .then((res) => {
          if (isNakes == 1) {
            this.nakes_nama = res.data.user.nama;
          } else {
            this.patient_nama = res.data.user.nama;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    deleteItemConfirm() {
      this.closeDelete();

      ChatsService.delete(this.editedItem.id)
        .then((res) => {
          console.log(res);
          this.$toast.success("Hapus User berhasil", {
            position: "top-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });

          this.getDataFromApi();
        })
        .catch((err) => {
          console.log(err);
          this.$toast.error("Hapus user gagal. Coba lagi", {
            position: "top-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
  },
};
</script>
