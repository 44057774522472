import http from "../../http-common";
/* eslint-disable */
class JabatanService {
  jabatanList(page, itemPerPage, keyword) {
    return http.get(
      "/jabatan/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  create(data) {
    return http.post("/jabatan", data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  update(data) {
    return http.put(`/jabatan/${data.id}`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }
}

export default new JabatanService();
