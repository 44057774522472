import http from "../http-common";
/* eslint-disable */
class ChatsService {
  chatList(page, itemPerPage, keyword) {
    return http.get(
      "/chats/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  chatListUser(page, itemPerPage, keyword, nakes_id, patient_id) {
    return http.get(
      "/chats/list/user?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword +
        "&nakes_id=" +
        nakes_id +
        "&patient_id=" +
        patient_id,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  getChat(id) {
    return http.get(`/user/${id}`, {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  profesiList() {
    return http.get("/profesi-list", {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  create(data, photo) {
    var bodyFormData = new FormData();
    bodyFormData.append("nama", data.nama);
    bodyFormData.append("phone", data.phone);
    bodyFormData.append("email", data.email);

    if (data.profesi_id) bodyFormData.append("profesi_id", data.profesi_id);

    if (photo != null) bodyFormData.append("photo", photo);

    return http.post("/user", bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  update(id, data, photo) {
    var bodyFormData = new FormData();
    bodyFormData.append("id", id);
    bodyFormData.append("nama", data.nama);
    bodyFormData.append("phone", data.phone);
    bodyFormData.append("email", data.email);

    if (data.profesi_id) bodyFormData.append("profesi_id", data.profesi_id);

    if (photo != null) bodyFormData.append("photo", photo);

    return http.put(`/user/${id}`, bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  delete(id) {
    return http.delete(`/user/${id}`, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
}

export default new ChatsService();
