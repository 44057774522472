<template>
  <div>
    <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>
    <v-container fluid>
      <v-card>
        <v-tabs v-model="tab">
          <v-tab key="download">Download</v-tab>
          <v-tab key="pretest">Pre Test</v-tab>
          <v-tab key="posttest">Post Test</v-tab>
          <!-- <v-tab key="fasilitator">Evaluasi Fasilitator</v-tab> -->
          <!-- <v-tab key="penyelenggaraan">Evaluasi Penyelenggaraan</v-tab> -->
          <!-- <v-tab key="pasca">Evaluasi Pasca Kegiatan</v-tab> -->
          <!-- <v-tab key="rtl">Evaluasi Rencana Tindak Lanjut</v-tab> -->
        </v-tabs>
        <v-tabs-items v-model="tab" touchless>
          <v-tab-item key="download">
            <LaporanComponent :id="id" :kegiatan-id="kegiatanId" />
          </v-tab-item>
          <v-tab-item key="pretest">
            <PelatihanReportResultPretestComponent
              :id="id"
              :kegiatan-id="kegiatanId"
            />
          </v-tab-item>
          <v-tab-item key="posttest">
            <PelatihanReportResultPosttestComponent
              :id="id"
              :kegiatan-id="kegiatanId"
            />
          </v-tab-item>
          <v-tab-item key="fasilitator">
            <PelatihanEvaluasiFasilitatorComponent
              :id="id"
              :kegiatan-id="kegiatanId"
              :user-id="userId"
            />
          </v-tab-item>
          <v-tab-item key="penyelenggaraan">
            <PelatihanEvaluasiPenyelenggaraanComponent
              :id="id"
              :kegiatan-id="kegiatanId"
              :user-id="userId"
            />
          </v-tab-item>
          <v-tab-item key="pasca">
            <PelatihanEvaluasiPascaKegiatanComponent
              :id="id"
              :kegiatan-id="kegiatanId"
              :user-id="userId"
            />
          </v-tab-item>
          <v-tab-item key="rtl">
            <PelatihanEvaluasiRTLComponent
              :id="id"
              :kegiatan-id="kegiatanId"
              :user-id="userId"
            />
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import KegiatanService from "../../services/KegiatanService";
import MasterService from "../../services/MasterService";
import * as moment from "moment";
import PelatihanReportResultPosttestComponent from "../report_result/PelatihanReportResultPosttest.vue";
import PelatihanEvaluasiFasilitatorComponent from "../report/PelatihanNarasumberReport.vue";
import PelatihanEvaluasiPenyelenggaraanComponent from "../report/PelatihanEvaluasiPenyelenggaraanReport.vue";
import PelatihanEvaluasiPascaKegiatanComponent from "../report/PelatihanEvaluasiPascaKegiatanReport.vue";
import PelatihanEvaluasiRTLComponent from "../report/PelatihanEvaluasiRTLReport.vue";

import PelatihanReportResultPretestComponent from "../report_result/PelatihanReportResultPretest.vue";
import LaporanComponent from "../report/LaporanReport.vue";

export default {
  name: "DataReport",
  components: {
    PelatihanReportResultPosttestComponent,
    PelatihanEvaluasiPenyelenggaraanComponent,
    PelatihanEvaluasiPascaKegiatanComponent,
    PelatihanEvaluasiFasilitatorComponent,
    PelatihanEvaluasiRTLComponent,
    PelatihanReportResultPretestComponent,
    LaporanComponent,
  },
  props: {
    id: {
      type: String,
      default: "0",
    },
    kegiatanId: {
      type: String,
      default: "0",
    },
    userId: {
      type: String,
      default: "0",
    },
  },
  data: () => ({
    tab: null,
    breadcrumbs: [
      {
        text: "Data Report",
        disabled: false,
        exact: true,
        to: { name: "Report" },
      },
      { text: "Detil Report", disabled: true },
    ],
    currentData: {
      FotoUrl: "",
      tanggal_awal: "",
      tanggal_akhir: "",
      tanggal_evaluasi_pasca_kegiatan: "",
      selectedKategori: "",
    },
    items_kategori: [],
  }),
  computed: {},
  mounted: async function () {
    this.getListKategoriKegiatan();
    this.getKegiatan();
  },
  methods: {
    getKegiatan() {
      KegiatanService.getKegiatan(this.id)
        .then((res) => {
          console.log(res.data.kegiatan);
          this.currentData = res.data.kegiatan;
          this.currentData.tanggal_awal = moment(
            this.currentData.tanggal_awal,
          ).format("YYYY-MM-DD");
          this.currentData.tanggal_akhir = moment(
            this.currentData.tanggal_akhir,
          ).format("YYYY-MM-DD");

          if (this.currentData.tanggal_evaluasi_pasca_kegiatan) {
            this.currentData.tanggal_evaluasi_pasca_kegiatan = moment(
              this.currentData.tanggal_evaluasi_pasca_kegiatan,
            ).format("YYYY-MM-DD");
          }

          if (res.data.kegiatan.kategori) {
            this.items_kategori.forEach((entry) => {
              if (entry.code == res.data.kegiatan.kategori) {
                this.currentData.selectedKategori = entry;
              }
            });
          }
          this.currentData.FotoUrl = res.data.kegiatan.photo;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getListKategoriKegiatan() {
      MasterService.kategoriKegiatanList()
        .then((res) => {
          this.items_kategori = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style scoped></style>
