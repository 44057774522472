import http from "../http-common";
/* eslint-disable */
class EventDiikutiService {
  kegiatanList(page, itemPerPage, keyword, userId) {
    return http.get(
      "/kegiatan/diikuti/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword +
        "&user_id=" +
        userId,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  getKegiatan(id) {
    return http.get(`/kegiatan/${id}`, {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  create(data, photo) {
    var bodyFormData = new FormData();
    if (data.kegiatan_title)
      bodyFormData.append("kegiatan_title", data.kegiatan_title);
    if (data.kegiatan_desc)
      bodyFormData.append("kegiatan_desc", data.kegiatan_desc);
    if (data.informasi) bodyFormData.append("informasi", data.informasi);
    if (data.prasyarat_khusus)
      bodyFormData.append("prasyarat_khusus", data.prasyarat_khusus);
    if (data.harga) bodyFormData.append("harga", data.harga);
    if (data.tanggal_awal)
      bodyFormData.append("tanggal_awal", data.tanggal_awal);
    if (data.tanggal_akhir)
      bodyFormData.append("tanggal_akhir", data.tanggal_akhir);
    if (data.skp_narasumber)
      bodyFormData.append("skp_narasumber", data.skp_narasumber);
    if (data.skp_moderator)
      bodyFormData.append("skp_moderator", data.skp_moderator);
    if (data.skp_panitia) bodyFormData.append("skp_panitia", data.skp_panitia);
    if (data.skp_peserta) bodyFormData.append("skp_peserta", data.skp_peserta);
    if (data.nomor_skp) bodyFormData.append("nomor_skp", data.nomor_skp);
    if (data.penyelenggara)
      bodyFormData.append("penyelenggara", data.penyelenggara);

    if (data.selectedKategori && data.selectedKategori != undefined) {
      bodyFormData.append("kategori", data.selectedKategori.code);
    }

    if (photo != null) bodyFormData.append("photo", photo);

    return http.post("/kegiatan", bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  update(id, data, photo) {
    var bodyFormData = new FormData();
    bodyFormData.append("id", id);
    if (data.kegiatan_title)
      bodyFormData.append("kegiatan_title", data.kegiatan_title);
    if (data.kegiatan_desc)
      bodyFormData.append("kegiatan_desc", data.kegiatan_desc);
    if (data.informasi) bodyFormData.append("informasi", data.informasi);
    if (data.prasyarat_khusus)
      bodyFormData.append("prasyarat_khusus", data.prasyarat_khusus);
    if (data.harga) bodyFormData.append("harga", data.harga);
    if (data.tanggal_awal)
      bodyFormData.append("tanggal_awal", data.tanggal_awal);
    if (data.tanggal_akhir)
      bodyFormData.append("tanggal_akhir", data.tanggal_akhir);
    if (data.tanggal_evaluasi_pasca_kegiatan)
      bodyFormData.append(
        "tanggal_evaluasi_pasca_kegiatan",
        data.tanggal_evaluasi_pasca_kegiatan
      );
    if (data.skp_narasumber)
      bodyFormData.append("skp_narasumber", data.skp_narasumber);
    if (data.skp_moderator)
      bodyFormData.append("skp_moderator", data.skp_moderator);
    if (data.skp_panitia) bodyFormData.append("skp_panitia", data.skp_panitia);
    if (data.skp_peserta) bodyFormData.append("skp_peserta", data.skp_peserta);
    if (data.nomor_skp) bodyFormData.append("nomor_skp", data.nomor_skp);
    if (data.penyelenggara)
      bodyFormData.append("penyelenggara", data.penyelenggara);

    if (data.selectedKategori && data.selectedKategori != undefined) {
      bodyFormData.append("kategori", data.selectedKategori.code);
    }

    if (photo != null) bodyFormData.append("photo", photo);

    return http.put(`/kegiatan/${id}`, bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  delete(id) {
    return http.delete(`/kegiatan/${id}`, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
}

export default new EventDiikutiService();
