<template>
  <v-main>
    <v-container fluid>
      <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>
      <v-card>
        <PelatihanComponent :id="id" :kegiatan-id="kegiatanId" />
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
import KegiatanService from "../../services/KegiatanService";
import MasterService from "../../services/MasterService";
import * as moment from "moment";
import PelatihanComponent from "./PelatihanReport.vue";

export default {
  name: "KegiatanEdit",
  components: {
    PelatihanComponent,
  },
  props: {
    id: {
      type: String,
      default: "0",
    },
    kegiatanId: {
      type: String,
      default: "0",
    },
  },
  data: () => ({
    breadcrumbs: [
      {
        text: "Data Report",
        disabled: false,
        exact: true,
        to: { name: "Report" },
      },
      { text: "Report Pelatihan", disabled: true },
    ],
    currentData: {
      FotoUrl: "",
      tanggal_awal: "",
      tanggal_akhir: "",
      tanggal_evaluasi_pasca_kegiatan: "",
      selectedKategori: "",
    },
    items_kategori: [],
  }),
  computed: {},
  mounted: async function () {
    this.getListKategoriKegiatan();
    this.getKegiatan();
  },
  methods: {
    getKegiatan() {
      KegiatanService.getKegiatan(this.kegiatanId)
        .then((res) => {
          console.log(res.data.kegiatan);
          this.currentData = res.data.kegiatan;
          this.currentData.tanggal_awal = moment(
            this.currentData.tanggal_awal,
          ).format("YYYY-MM-DD");
          this.currentData.tanggal_akhir = moment(
            this.currentData.tanggal_akhir,
          ).format("YYYY-MM-DD");

          if (this.currentData.tanggal_evaluasi_pasca_kegiatan) {
            this.currentData.tanggal_evaluasi_pasca_kegiatan = moment(
              this.currentData.tanggal_evaluasi_pasca_kegiatan,
            ).format("YYYY-MM-DD");
          }

          if (res.data.kegiatan.kategori) {
            this.items_kategori.forEach((entry) => {
              if (entry.code == res.data.kegiatan.kategori) {
                this.currentData.selectedKategori = entry;
              }
            });
          }
          this.currentData.FotoUrl = res.data.kegiatan.photo;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getListKategoriKegiatan() {
      MasterService.kategoriKegiatanList()
        .then((res) => {
          this.items_kategori = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style scoped></style>
