import http from "../http-common";
/* eslint-disable */
class DashboardService {

  getDashboard(id) {
    return http.get(`/dashboard`, {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

}

export default new DashboardService();
