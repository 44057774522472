<template>
  <div>
    <v-card>
      <v-card-title>
        Paket Benefit
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>

        <v-btn color="purple" dark class="mb-2" @click="doAdd"> Tambah </v-btn>

        <v-dialog v-model="dialog" persistent max-width="600px">
          <v-card>
            <v-card-title>
              <span class="text-h5">Paket Benefit</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-combobox
                      v-model="currentData.selectedTipeBenefit"
                      label="Tipe Benefit"
                      clearable
                      dense
                      outlined
                      :items="items_tipe_benefit"
                      item-text="value"
                    ></v-combobox>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="currentData.value"
                      label="Nilai"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialog = false">
                Close
              </v-btn>
              <v-btn color="blue darken-1" text @click="doSave"> Save </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5"
              >Apa anda akan menghapus data ini?</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete"
                >Tidak</v-btn
              >
              <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                >Ya</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="list_data"
        :options.sync="options"
        :server-items-length="totalData"
        :loading="loading"
        class="elevation-1"
      >
        <template #[`item.actions`]="{ item }">
          <v-icon medium class="mr-3" @click="editItem(item)">
            mdi-pencil
          </v-icon>
          <v-icon medium @click="deleteItem(item)"> mdi-delete </v-icon>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import PaketMembershipService from "../services/PaketMembershipService";
import CommonService from "../services/CommonService";
import MasterService from "../services/MasterService";

export default {
  props: {
    id: {
      type: Number,
      default: 0,
    },
  },
  data: () => ({
    search: "",
    headers: [
      {
        text: "No",
        align: "start",
        sortable: false,
        value: "no",
      },
      { text: "Benefit", value: "benefit_nama", sortable: false },
      { text: "Nilai", value: "value", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
    isFirst: false,
    totalData: 0,
    list_data: [],
    loading: true,
    options: {
      itemsPerPage: 10,
    },

    editedIndex: -1,
    editedItem: {},
    dialog: false,
    dialogDelete: false,

    currentData: {
      id: null,
      selectedTipeBenefit: null,
      value: "",
      paket_membership_id: "",
    },

    items_tipe_benefit: [],
  }),
  watch: {
    options: {
      handler() {
        if (this.isFirst) {
          this.getDataFromApi();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getTipePaketBenefit();
    this.getDataFromApi();
  },
  methods: {
    async getTipePaketBenefit() {
      MasterService.tipePaketBenefitList()
        .then((res) => {
          this.items_tipe_benefit = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getDataFromApi() {
      const { page, itemsPerPage } = this.options;

      this.loading = true;
      PaketMembershipService.paketDetailList(
        page,
        itemsPerPage,
        this.search,
        this.id,
      )
        .then((res) => {
          this.list_data = res.data.list_data;
          this.totalData = res.data.total;
          this.loading = false;
          this.isFirst = true;
        })
        .catch((err) => {
          this.loading = false;
          this.isFirst = true;
          console.log(err);
        });
    },

    doAdd: async function () {
      this.dialog = true;
      this.currentData.id = null;
      this.currentData.selectedTipeBenefit = null;
      this.currentData.value = null;

      console.log("do add");
    },

    doSave: async function () {
      if (this.currentData.id != null) {
        this.currentData.paket_membership_id = this.id;
        PaketMembershipService.updateBenefit(
          this.currentData.id,
          this.currentData,
        )
          .then((res) => {
            console.log(res);
            this.dialog = false;
            CommonService.showSuccessToast("Update benefit berhasil");

            this.getDataFromApi();
          })
          .catch((err) => {
            console.log(err);
            CommonService.showErrorToast("Update benefit gagal. Coba lagi");
          });
      } else {
        this.currentData.paket_membership_id = this.id;
        PaketMembershipService.createBenefit(this.currentData)
          .then((res) => {
            console.log(res);
            this.dialog = false;
            CommonService.showSuccessToast("Tambah benefit berhasil");

            this.getDataFromApi();
          })
          .catch((err) => {
            console.log(err);
            CommonService.showErrorToast("Tambah benefit gagal. Coba lagi");
          });
      }
    },

    editItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.dialog = true;
      this.currentData.value = this.editedItem.value;
      this.currentData.id = this.editedItem.id;

      if (this.editedItem.tipe_benefit) {
        this.items_tipe_benefit.forEach((entry) => {
          if (entry.code == this.editedItem.tipe_benefit) {
            this.currentData.selectedTipeBenefit = entry;
          }
        });
      }

      console.log("do edit");
    },

    deleteItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.closeDelete();

      PaketMembershipService.deleteBenefit(this.editedItem.id)
        .then((res) => {
          console.log(res);
          CommonService.showSuccessToast("Hapur benefit berhasil");

          this.getDataFromApi();
        })
        .catch((err) => {
          console.log(err);

          CommonService.showErrorToast("Hapur benefit gagal. Coba lagi");
        });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
  },
};
</script>
