import http from "../../http-common";
/* eslint-disable */
class VisiMisiService {
  visiMisiList(page, itemPerPage, keyword, dpd) {
    return http.get(
      "/visi-misi/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword +
        "&dpd_id=" +
        dpd,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      },
    );
  }

  visiMisi() {
    return http.get("/visi-misi", {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  create(data) {
    var bodyFormData = new FormData();
    bodyFormData.append("visi", data.visi);
    bodyFormData.append("misi", data.misi);

    if (data.foto_url != null) bodyFormData.append("photo", data.foto_url);

    return http.post("/visi-misi", bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  update(data) {
    var bodyFormData = new FormData();
    bodyFormData.append("id", data.id);
    bodyFormData.append("visi", data.visi);
    bodyFormData.append("misi", data.misi);
    if (data.foto_url != null) bodyFormData.append("photo", data.foto_url);

    return http.put(`/visi-misi/${data.id}`, bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
}

export default new VisiMisiService();
