import http from "../http-common";
/* eslint-disable */
class PendidikanService {
  pendidikanList(page, itemPerPage, keyword, userId) {
    return http.get(
      "/nakes/pendidikan/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword +
        "&user_id=" +
        userId,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  create(data) {
    if (data.mulai_pend) {
      data.mulai_pend = data.mulai_pend + "-01";
    }

    if (data.akhir_pend) {
      data.akhir_pend = data.akhir_pend + "-01";
    } else {
      data.akhir_pend = null;
    }

    if (data.selectedJenjang && data.selectedJenjang != undefined) {
      data.jenjang = data.selectedJenjang.code;
    }

    return http.post("/nakes/pendidikan", data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  update(data) {
    if (data.mulai_pend) {
      data.mulai_pend = data.mulai_pend + "-01";
    }

    if (data.akhir_pend) {
      data.akhir_pend = data.akhir_pend + "-01";
    } else {
      data.akhir_pend = null;
    }

    if (data.selectedJenjang && data.selectedJenjang != undefined) {
      data.jenjang = data.selectedJenjang.code;
    }

    return http.put(`/nakes/pendidikan/${data.id}`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  delete(id) {
    return http.delete(`/nakes/pendidikan/${id}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  masterPendidikan() {
    return http.get("/pendidikan/list", {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }
}

export default new PendidikanService();
