<template>
  <div>
    <v-card>
      <v-card-title>
        Jawaban <br />

        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Cari Jawaban"
          single-line
          hide-details
          @keyup.enter="getDataFromApi"
        ></v-text-field>
        <v-spacer></v-spacer>

        <v-btn color="purple" dark class="mb-2" @click="openTambah">
          Tambah
        </v-btn>
        <v-dialog v-model="dialog" persistent max-width="600px">
          <v-card>
            <v-card-title>
              <span class="text-h5">Jawaban</span>
            </v-card-title>
            <v-card-text>
              <v-form
                ref="form"
                v-model="valid"
                lazy-validation
                class="pl-4 pr-4"
              >
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        v-model="currentData.jawaban"
                        label="Jawaban"
                        required
                        :rules="currentData.jawabanRules"
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model="currentData.urutan"
                        label="Urutan"
                        required
                        :rules="currentData.urutanRules"
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialog = false">
                Close
              </v-btn>
              <v-btn color="blue darken-1" text @click="doSave"> Save </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5"
              >Apa anda akan menghapus data ini?</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete"
                >Tidak</v-btn
              >
              <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                >Ya</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialogJawaban" max-width="500px">
          <v-card>
            <v-card-title class="text-h5"
              >Apa anda akan menset sebagai jawaban benar?</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeJawaban"
                >Tidak</v-btn
              >
              <v-btn color="blue darken-1" text @click="setJawabanBenar"
                >Ya</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="list_data"
        :options.sync="options"
        :server-items-length="totalData"
        :loading="loading"
        class="elevation-1"
      >
        <template #[`item.kategori`]="{ item }">
          <v-chip :color="getColor(item.kategori)" dark>
            {{ getKategoriDescription(item.kategori) }}
          </v-chip>
        </template>
        <template #[`item.video`]="{ item }">
          <a v-if="item.video" :href="item.video" target="_blank">
            Link Video
          </a>
          <span v-else></span>
        </template>
        <template #[`item.file_pdf`]="{ item }">
          <a
            v-if="item.file_pdf_original"
            :href="item.file_pdf"
            target="_blank"
          >
            Link File
          </a>
          <span v-else></span>
        </template>
        <template #[`item.is_benar`]="{ item }">
          <v-chip v-if="item.is_benar" color="green" dark> Benar </v-chip>
        </template>
        <template #[`item.tanggal`]="{ item }">
          {{ getCurrentDate(item.tanggal) }}
        </template>
        <template #[`item.actions`]="{ item }">
          <v-icon medium class="mr-3" @click="editItem(item)">
            mdi-pencil
          </v-icon>
          <v-icon medium class="mr-3" @click="deleteItem(item)">
            mdi-delete
          </v-icon>
          <v-icon medium @click="jawabanBenarItem(item)"> mdi-dialpad </v-icon>
        </template>
      </v-data-table>
    </v-card>

    <!-- Modal -->
  </div>
</template>

<script>
import UjianPilihanService from "../../services/UjianPilihanService";
import CommonService from "../../services/CommonService";

export default {
  props: {
    id: {
      type: Number,
      default: 0,
    },
  },
  data: () => ({
    isEditable: 0,
    valid: true,
    search: "",
    headers: [
      {
        text: "No",
        align: "start",
        sortable: false,
        value: "no",
      },
      { text: "Jawaban", value: "jawaban", sortable: false },
      { text: "Urutan", value: "urutan", sortable: false },
      { text: "Jawaban Benar", value: "is_benar", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
    isFirst: false,
    totalData: 0,
    list_data: [],
    loading: true,
    options: {
      itemsPerPage: 10,
      page: 1,
    },

    editedIndex: -1,
    editedItem: {},
    dialog: false,
    dialogDelete: false,
    dialogJawaban: false,

    currentData: {
      ujian_id: "",
      id: null,
      jawaban: "",
      urutan: "",
      jawabanRules: [(v) => !!v || "Jawaban harus diisi"],
      urutanRules: [(v) => !!v || "Urutan harus diisi"],
    },
  }),
  watch: {
    options: {
      handler() {
        if (this.isFirst) {
          this.getDataFromApi();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getDataFromApi();
  },
  methods: {
    async getDataFromApi() {
      const { page, itemsPerPage } = this.options;

      this.loading = true;
      UjianPilihanService.ujianPilihanList(
        page,
        itemsPerPage,
        this.search,
        this.id,
      )
        .then((res) => {
          this.list_data = res.data.list_data;
          this.totalData = res.data.total;
          this.loading = false;
          this.isFirst = true;
        })
        .catch((err) => {
          this.loading = false;
          this.isFirst = true;
          console.log(err);
        });
    },

    openTambah() {
      this.currentData.id = null;
      this.dialog = true;
      this.isEditable = 1;

      this.currentData.jawaban = "";
      this.currentData.urutan = "";
    },

    editItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.dialog = true;
      console.log(this.editedItem);
      this.currentData.jawaban = this.editedItem.jawaban;
      this.currentData.urutan = this.editedItem.urutan;
      this.currentData.id = this.editedItem.id;

      this.isEditable = 1;
    },

    doSave: async function () {
      if (!this.$refs.form.validate()) {
        return;
      }

      if (this.currentData.id == null) {
        this.currentData.ujian_id = this.id;
        UjianPilihanService.create(this.currentData)
          .then((res) => {
            console.log(res);
            this.dialog = false;

            CommonService.showSuccessToast("Tambah jawaban berhasil");

            this.getDataFromApi();
          })
          .catch((err) => {
            console.log(err);

            CommonService.showSuccessToast("Tambah jawaban gagal. Coba lagi");
          });
      } else {
        this.currentData.ujian_id = this.id;

        UjianPilihanService.update(this.currentData)
          .then((res) => {
            this.dialog = false;

            console.log(res);

            CommonService.showSuccessToast("Update jawaban berhasil");

            this.getDataFromApi();
          })
          .catch((err) => {
            console.log(err);

            CommonService.showSuccessToast("Update jawaban gagal. Coba lagi");
          });
      }
    },

    deleteItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    jawabanBenarItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogJawaban = true;
    },

    deleteItemConfirm() {
      this.closeDelete();

      UjianPilihanService.delete(this.editedItem.id)
        .then((res) => {
          console.log(res);
          CommonService.showSuccessToast("Hapus jawaban berhasil");

          this.getDataFromApi();
        })
        .catch((err) => {
          console.log(err);
          CommonService.showSuccessToast("Hapus jawaban gagal. Coba lagi");
        });
    },

    setJawabanBenar() {
      this.closeJawaban();

      UjianPilihanService.setJawabanBenar(this.editedItem)
        .then((res) => {
          console.log(res);
          CommonService.showSuccessToast("Set jawaban benar berhasil");

          this.getDataFromApi();
        })
        .catch((err) => {
          console.log(err);
          CommonService.showSuccessToast("Set jawaban benar gagal. Coba lagi");
        });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeJawaban() {
      this.dialogJawaban = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
  },
};
</script>
