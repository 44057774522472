import http from "../http-common";
/* eslint-disable */
class FaskesLayananService {
  faskesLayananList(page, itemPerPage, keyword, faskes_id) {
    return http.get(
      "/layanan-faskes/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword +
        "&faskes_id=" +
        faskes_id,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  faskesLayananOptionList(faskes_id) {
    return http.get("/layanan-faskes/list/option?" + "faskes_id=" + faskes_id, {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  getFaskes(id) {
    return http.get(`/faskes/${id}`, {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  create(data) {
    var bodyFormData = new FormData();
    bodyFormData.append("faskes_id", data.faskes_id);
    bodyFormData.append("layanan_id", data.layanan_id);
    bodyFormData.append("harga", data.harga);

    return http.post("/layanan-faskes", bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  update(id, data) {
    var bodyFormData = new FormData();
    bodyFormData.append("id", id);
    bodyFormData.append("faskes_id", data.faskes_id);
    bodyFormData.append("layanan_id", data.layanan_id);
    bodyFormData.append("harga", data.harga);

    return http.put(`/layanan-faskes/${id}`, bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  delete(id) {
    return http.delete(`/layanan-faskes/${id}`, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
}

export default new FaskesLayananService();
