import http from "../http-common";
/* eslint-disable */
class FaskesService {
  faskesList(page, itemPerPage, keyword) {
    return http.get(
      "/faskes/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  faskesCategoryList() {
    return http.get("/faskes/category", {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  faskesNakesList(page, itemPerPage, keyword, id) {
    return http.get(
      "/faskes/nakes/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword +
        "&id=" +
        id,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  faskesNakesOptionList(page, itemPerPage, keyword, id) {
    return http.get(
      "/faskes/nakes/option/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword +
        "&id=" +
        id,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  getFaskes(id) {
    return http.get(`/faskes/${id}`, {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  addNakes(faskes_id, nakes_id) {
    return http.post(`/faskes/${faskes_id}/nakes/${nakes_id}`, {
      headers: {
        "Content-type": "application/json",
      },
    });
  }

  deleteNakes(faskes_id, nakes_id) {
    return http.delete(`/faskes/${faskes_id}/nakes/${nakes_id}`, {
      headers: {
        "Content-type": "application/json",
      },
    });
  }

  create(data) {
    var bodyFormData = new FormData();
    bodyFormData.append("nama", data.nama);
    bodyFormData.append("alamat", data.alamat);

    if (data.category_id && data.category_id != undefined) {
      bodyFormData.append("category_id", data.category_id.code);
    }

    return http.post("/faskes", bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  update(id, data) {
    var bodyFormData = new FormData();
    bodyFormData.append("id", id);
    bodyFormData.append("nama", data.nama);
    bodyFormData.append("alamat", data.alamat);
    if (data.category_id && data.category_id != undefined) {
      bodyFormData.append("category_id", data.category_id.code);
    }

    return http.put(`/faskes/${id}`, bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  delete(id) {
    return http.delete(`/faskes/${id}`, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
}

export default new FaskesService();
