<template>
  <v-main>
    <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>
    <v-container fluid>
      <v-card>
        <v-card-title> Edit Pers </v-card-title>
        <v-fab-transition>
          <v-btn
            v-if="isEditable == 0"
            color="pink"
            dark
            absolute
            top
            right
            fab
            @click="changeEditable"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </v-fab-transition>
        <form class="pl-4 pr-4">
          <v-row dense>
            <v-col cols="12" sm="4" md="4" class="pr-4">
              <v-sheet color="white" elevation="2">
                <v-responsive :aspect-ratio="16 / 9">
                  <v-img :key="fotoKey" :src="pers.FotoUrl" contain></v-img>
                </v-responsive>
              </v-sheet>
              <v-file-input
                v-model="pers.file_foto"
                accept="image/*"
                prepend-icon="mdi-image"
                show-size
                label="Foto"
                :disabled="isEditable == 0"
                @change="loadImage"
              />
            </v-col>
            <v-col cols="12" sm="8" md="8">
              <v-row dense>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    v-model="pers.judul"
                    label="Judul"
                    outlined
                    dense
                    :readonly="isEditable == 0"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <v-textarea
                    v-model="pers.konten"
                    outlined
                    label="Konten"
                    rows="12"
                    row-height="15"
                    :readonly="isEditable == 0"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="d-flex justify-end">
              <v-btn
                v-if="isEditable == 1"
                style="margin-right: 10px"
                @click="doBatal"
                >Batal</v-btn
              >
              <v-btn
                v-if="isEditable == 1"
                dark
                color="primary"
                type="button"
                @click="doSubmit"
                >Simpan</v-btn
              >
            </v-col>
          </v-row>
        </form>
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
import PersService from "../services/PersService";

export default {
  name: "PersEdit",
  components: {},
  props: {
    id: {
      type: String,
      default: "0",
    },
  },
  data: () => ({
    isEditable: 0,
    breadcrumbs: [
      {
        text: "Data Pers",
        disabled: false,
        exact: true,
        to: { name: "Pers" },
      },
      { text: "Edit Pers", disabled: true },
    ],
    pers: {
      FotoUrl: "",
      file_foto: null,
      judul: "",
      konten: "",
    },
    fotoKey: 0,
  }),
  computed: {},
  mounted: async function () {
    this.getPers();
  },
  methods: {
    changeEditable: function () {
      this.isEditable = 1;
    },

    getPers() {
      PersService.getPers(this.id)
        .then((res) => {
          this.pers.judul = res.data.result.judul;
          this.pers.konten = res.data.result.konten;
          this.pers.FotoUrl = res.data.result.foto;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    loadImage: function (data) {
      console.log("loadImage:", data);
      if (data) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.pers.FotoUrl = e.target.result;
          this.fotoKey++;
        };
        reader.readAsDataURL(data);
      } else {
        this.pers.FotoUrl = "";
        this.pers.file_foto = null;
      }
    },

    doBatal: function () {
      this.$router.go(-1);
    },

    doSubmit: async function () {
      PersService.update(this.id, this.pers, this.pers.file_foto)
        .then((res) => {
          console.log(res);
          this.$toast.success("Update Pers berhasil", {
            position: "top-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });

          this.isEditable = 0;
        })
        .catch((err) => {
          console.log(err);
          this.$toast.error("Update Pers gagal. Coba lagi", {
            position: "top-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        });
    },
  },
};
</script>

<style scoped></style>
