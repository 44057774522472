<template>
  <v-main>
    <!-- Provides the application the proper gutter -->
    <v-container fluid>
      <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>

      <!-- If using vue-router -->
      <!-- <router-view></router-view> -->
      <v-card>
        <v-card-title>
          Pengajuan Mutasi
          <v-spacer></v-spacer>

          <v-btn color="purple" dark class="mb-2" @click="doAdd">
            Tambah
          </v-btn>

          <v-dialog v-model="dialog" persistent max-width="600px">
            <v-card>
              <v-card-title>
                <span class="text-h5">Pengajuan Mutasi</span>
              </v-card-title>
              <v-card-text>
                <v-form
                  ref="form"
                  v-model="valid"
                  lazy-validation
                  class="pl-4 pr-4"
                >
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          v-model="currentData.nama"
                          label="Nama Anggota"
                          outlined
                          dense
                          required
                          readonly
                          :rules="currentData.namaRules"
                          @click="openAnggotaModal"
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row dense>
                      <v-col cols="12" sm="12" md="12">
                        <v-text-field
                          v-model="currentData.komisariat_asal_nama"
                          label="Komisariat Asal"
                          readonly
                          outlined
                          dense
                          required
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row dense>
                      <v-col cols="12" sm="12" md="12">
                        <v-combobox
                          v-model="currentData.selectedKomisariatTujuan"
                          label="Komisariat Tujuan"
                          clearable
                          dense
                          outlined
                          :items="items_komisariat"
                          item-text="nama"
                          :rules="currentData.komisariatTujuanRules"
                        ></v-combobox>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col cols="12">
                        <v-checkbox
                          v-model="currentData.langsung_mutasi"
                          label="Langsung mutasikan"
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialog = false">
                  Close
                </v-btn>
                <v-btn color="blue darken-1" text @click="doSave"> Save </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialogUpdate" persistent max-width="600px">
            <v-card>
              <v-card-title>
                <span class="text-h5">Pengajuan Mutasi</span>
              </v-card-title>
              <v-card-text>
                <v-form
                  ref="form"
                  v-model="valid"
                  lazy-validation
                  class="pl-4 pr-4"
                >
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          v-model="currentData.nama"
                          label="Nama Anggota"
                          outlined
                          dense
                          required
                          readonly
                          :rules="currentData.namaRules"
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row dense>
                      <v-col cols="12" sm="12" md="12">
                        <v-text-field
                          v-model="currentData.komisariat_asal_nama"
                          label="Komisariat Asal"
                          readonly
                          outlined
                          dense
                          required
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row dense>
                      <v-col cols="12" sm="12" md="12">
                        <v-combobox
                          v-model="currentData.selectedKomisariatTujuan"
                          label="Komisariat Tujuan"
                          clearable
                          dense
                          outlined
                          :items="items_komisariat"
                          item-text="nama"
                          :rules="currentData.komisariatTujuanRules"
                        ></v-combobox>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialogUpdate = false">
                  Close
                </v-btn>
                <v-btn color="blue darken-1" text @click="doSave"> Save </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5"
                >Apa anda akan menghapus data ini?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete"
                  >Tidak</v-btn
                >
                <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                  >Ya</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialogApprove" max-width="600px">
            <v-card>
              <v-card-title class="text-h5"
                >Apa anda akan mengapprove mutasi anggota ini?</v-card-title
              >
              <v-card-actions>
                <v-container>
                  <v-row>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeApprove"
                      >Tidak</v-btn
                    >
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="approveItemConfirm"
                      >Ya</v-btn
                    >
                  </v-row>
                </v-container>

                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card-title>

        <v-row class="mx-6 mb-6">
          <v-col cols="12" sm="4" md="4">
            <v-text-field
              v-model="search.nama"
              append-icon="mdi-magnify"
              label="Cari nama"
              single-line
              hide-details
              @keyup.enter="getDataFromApi"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="4" md="4">
            <v-combobox
              v-model="search.selectedKomisariat"
              label="Komisariat"
              clearable
              dense
              outlined
              :items="items_komisariat"
              item-text="nama"
              @change="changeKomisariat"
              :disabled="isAdminKomisariat"
            ></v-combobox>
          </v-col>
        </v-row>

        <v-data-table
          :headers="headers"
          :items="list_data"
          :options.sync="options"
          :server-items-length="totalData"
          :loading="loading"
          class="elevation-1"
        >
          <template #[`item.actions`]="{ item }">
            <div class="d-flex flex-row">
              <v-icon
                v-if="
                  item.status < 3 && (getRoleId == 1 || item.is_approver == 1)
                "
                medium
                class="mr-3"
                @click="approveItem(item)"
              >
                mdi-check
              </v-icon>
              <v-icon
                v-if="item.status < 3"
                medium
                class="mr-3"
                @click="editItem(item)"
              >
                mdi-pencil
              </v-icon>
            </div>
          </template>
          <template #[`item.foto_url`]="{ item }">
            <v-img
              :src="item.foto_url"
              contain
              height="100"
              width="200"
            ></v-img>
          </template>
          <template #[`item.created_at`]="{ item }">
            <span>{{ getCurrentDate(item.created_at) }}</span>
          </template>
          <template #[`item.status`]="{ item }">
            <v-chip :color="getColor(item.status)" dark>
              {{ getStatus(item.status) }}
            </v-chip>
          </template>
        </v-data-table>
      </v-card>

      <AnggotaSelectComponent
        ref="modalAnggota"
        v-model="dialogAnggota"
        @pilihAnggota="pilihAnggota"
      />
    </v-container>
  </v-main>
</template>

<script>
import MutasiService from "../services/MutasiService";
import CommonService from "../services/CommonService";
import KomisariatService from "../services/KomisariatService";
import * as moment from "moment";

import AnggotaSelectComponent from "../components/AnggotaSelect.vue";
import store from "../store/index";

export default {
  name: "MutasiAnggota",
  components: {
    AnggotaSelectComponent,
  },
  data: () => ({
    dialogAnggota: false,
    dialogApprove: false,
    dialogUpdate: false,
    valid: true,
    selected: [],
    breadcrumbs: [
      {
        text: "Data Mutasi",
        disabled: true,
        href: "breadcrumbs_dashboard",
      },
    ],
    search: {
      nama: "",
      selectedKomisariat: "",
    },
    headers: [
      {
        text: "No",
        align: "start",
        sortable: false,
        value: "no",
      },
      { text: "Nama", value: "user_nama", sortable: false },
      { text: "NIRA", value: "nira", sortable: false },
      { text: "Status", value: "status", sortable: false },
      { text: "Tanggal", value: "created_at", sortable: false },
      {
        text: "Komisariat Asal",
        value: "komisariat_asal_nama",
        sortable: false,
      },
      {
        text: "Komisariat Tujuan",
        value: "komisariat_tujuan_nama",
        sortable: false,
      },
      { text: "Actions", value: "actions", sortable: false },
    ],
    isFirst: false,
    totalData: 0,
    list_data: [],
    loading: true,
    options: {
      itemsPerPage: 10,
    },

    editedIndex: -1,
    editedItem: {},
    dialog: false,
    dialogDelete: false,

    currentData: {
      id: null,
      nama: "",
      user_id: "",
      selectedKomisariatTujuan: "",
      komisariat_asal_nama: "",
      komisariat_asal: "",
      langsung_mutasi: false,
      namaRules: [(v) => !!v || "Nama harus diisi"],
      komisariatTujuanRules: [(v) => !!v || "Komisariat harus diisi"],
    },

    items_komisariat: [],
  }),

  computed: {
    getRoleId() {
      return store.getters.getUser.role_id;
    },
    getKomisariatId() {
      return store.getters.getUser.komisariat_id;
    },
    isAdminKomisariat() {
      if (store.getters.getUser.komisariat_id) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    selected: {
      handler() {
        if (this.selected != undefined && this.selected[0]) {
          this.childKey++;
        }
      },
      deep: true,
    },
    options: {
      handler() {
        if (this.isFirst) {
          this.getDataFromApi();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getListKomisariat();
    this.getDataFromApi();
  },
  methods: {
    async getListKomisariat() {
      KomisariatService.komisariatListAll()
        .then((res) => {
          this.items_komisariat = res.data.list_data;

          if (this.getKomisariatId) {
            this.items_komisariat.forEach((entry) => {
              if (entry.id == this.getKomisariatId) {
                this.search.selectedKomisariat = entry;
                this.getDataFromApi();
              }
            });
          } else {
            this.getDataFromApi();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getColor(dataStatus) {
      console.log(dataStatus);
      switch (dataStatus) {
        case 1:
          return "blue";
        case 2:
          return "green";
        default:
          return "cyan";
      }
    },

    getStatus(dataStatus) {
      console.log(dataStatus);
      if (dataStatus == 1) {
        return "Menunggu persetujuan Komisariat Asal";
      } else if (dataStatus == 2) {
        return "Menunggu persetujuan Komisariat Tujuan";
      } else {
        return "Mutasi Diterima";
      }
    },

    getCurrentDate(tanggal) {
      return moment(tanggal).format("DD/MM/YYYY");
    },

    getDataFromApi() {
      const { page, itemsPerPage } = this.options;

      this.loading = true;

      var komisariat = "";

      if (this.search.selectedKomisariat) {
        komisariat = this.search.selectedKomisariat.id;
      }

      MutasiService.mutasiList(page, itemsPerPage, this.search.nama, komisariat)
        .then((res) => {
          this.list_data = res.data.list_data;
          this.totalData = res.data.total;
          this.loading = false;
          this.isFirst = true;
        })
        .catch((err) => {
          this.loading = false;
          this.isFirst = true;
          console.log(err);
        });
    },

    doAdd: async function () {
      this.dialog = true;
      this.currentData.id = null;
      this.currentData.nama = "";
      this.currentData.urutan = "";
      this.currentData.selectedKomisariatTujuan = "";
      this.currentData.komisariat_asal_nama = "";
      this.currentData.komisariat_asal = "";
      this.currentData.langsung_mutasi = false;

      console.log("do add");
    },

    doSave: async function () {
      if (!this.$refs.form.validate()) {
        return;
      }

      if (this.currentData.id != null) {
        MutasiService.update(this.currentData)
          .then((res) => {
            console.log(res);
            this.dialogUpdate = false;
            CommonService.showSuccessToast("Update pengajuan mutasi berhasil");

            this.getDataFromApi();
          })
          .catch((err) => {
            console.log(err);
            CommonService.showErrorToast(
              "Update pengajuan mutasi gagal. Coba lagi",
            );
          });
      } else {
        MutasiService.create(this.currentData)
          .then((res) => {
            console.log(res);
            this.dialog = false;
            CommonService.showSuccessToast("Tambah pengajuan mutasi berhasil");

            this.getDataFromApi();
          })
          .catch((err) => {
            console.log(err);
            CommonService.showErrorToast(
              "Tambah pengajuan mutasi gagal. Coba lagi",
            );
          });
      }
    },

    editItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.dialogUpdate = true;
      this.currentData.nama = this.editedItem.user_nama;
      this.currentData.user_id = this.editedItem.user_id;
      this.currentData.urutan = this.editedItem.urutan;
      this.currentData.id = this.editedItem.id;
      this.currentData.komisariat_asal = this.editedItem.komisariat_asal;
      this.currentData.komisariat_asal_nama =
        this.editedItem.komisariat_asal_nama;

      this.items_komisariat.forEach((entry) => {
        if (entry.id == this.editedItem.komisariat_tujuan) {
          this.currentData.selectedKomisariatTujuan = entry;
        }
      });

      console.log("do edit");
    },

    deleteItemConfirm() {
      this.closeDelete();

      MutasiService.delete(this.editedItem.id)
        .then((res) => {
          console.log(res);
          CommonService.showSuccessToast("Hapus pengurus berhasil");

          this.getDataFromApi();
        })
        .catch((err) => {
          console.log(err);

          CommonService.showErrorToast("Hapus pengurus gagal. Coba lagi");
        });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    openAnggotaModal: async function () {
      this.$refs.modalAnggota.getDataFromApi();
      this.dialogAnggota = true;
    },

    pilihAnggota(item) {
      this.dialogAnggota = false;
      console.log(item);
      this.currentData.nama = item.nama;
      this.currentData.user_id = item.id;
      this.currentData.komisariat_asal = item.komisariat_id;
      this.currentData.komisariat_asal_nama = item.komisariat_nama;
    },

    approveItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogApprove = true;
    },

    closeApprove() {
      this.dialogApprove = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    approveItemConfirm() {
      MutasiService.approve(this.editedItem.id, this.currentData)
        .then((res) => {
          this.closeApprove();

          console.log(res);
          this.$toast.success("Approve mutasi berhasil", {
            position: "top-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });

          this.getDataFromApi();
        })
        .catch((err) => {
          console.log(err);
          this.$toast.error("Approve mutasi gagal. Coba lagi", {
            position: "top-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        });
    },

    async changeKomisariat() {
      this.getDataFromApi();
    },
  },
};
</script>
