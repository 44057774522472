import http from "../http-common";
/* eslint-disable */
class PelatihanPesertaPretestService {
  pesertaList(
    page,
    itemPerPage,
    keyword,
    kegiatanId,
    pelatihanId,
    pelatihanDetailId
  ) {
    return http.get(
      "/pelatihan-peserta-pretest/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword +
        "&kegiatan_id=" +
        kegiatanId +
        "&pelatihan_id=" +
        pelatihanId +
        "&pelatihan_detail_id=" +
        pelatihanDetailId,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  pesertaPostTestList(
    page,
    itemPerPage,
    keyword,
    kegiatanId,
    pelatihanId,
    pelatihanDetailId
  ) {
    return http.get(
      "/pelatihan-peserta-posttest/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword +
        "&kegiatan_id=" +
        kegiatanId +
        "&pelatihan_id=" +
        pelatihanId +
        "&pelatihan_detail_id=" +
        pelatihanDetailId,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  getKegiatan(id) {
    return http.get(`/kegiatan/${id}`, {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }
}

export default new PelatihanPesertaPretestService();
