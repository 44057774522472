import http from "../http-common";
/* eslint-disable */
class UserService {
  userList(page, itemPerPage, keyword) {
    return http.get(
      "/user-list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  getUser(id) {
    return http.get(`/user/${id}`, {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  profesiList() {
    return http.get("/profesi-list", {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  pendidikanList() {
    return http.get("/pendidikan-list", {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  create(data, photo) {
    var bodyFormData = new FormData();
    bodyFormData.append("nama", data.nama);
    bodyFormData.append("phone", data.phone);
    bodyFormData.append("email", data.email);
    bodyFormData.append("str", data.str);
    bodyFormData.append("sip", data.sip);
    bodyFormData.append("status_profesi", data.status_profesi);

    if (data.profesi_id) bodyFormData.append("profesi_id", data.profesi_id);
    if (data.pendidikan_id)
      bodyFormData.append("pendidikan_id", data.pendidikan_id);

    if (photo != null) bodyFormData.append("photo", photo);

    return http.post("/user", bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  update(id, data, photo) {
    var bodyFormData = new FormData();
    bodyFormData.append("id", id);
    bodyFormData.append("nama", data.nama);
    bodyFormData.append("phone", data.phone);
    bodyFormData.append("email", data.email);
    bodyFormData.append("str", data.str);
    bodyFormData.append("sip", data.sip);
    bodyFormData.append("status_profesi", data.status_profesi);

    if (data.profesi_id) bodyFormData.append("profesi_id", data.profesi_id);
    if (data.pendidikan_id)
      bodyFormData.append("pendidikan_id", data.pendidikan_id);

    if (photo != null) bodyFormData.append("photo", photo);

    return http.put(`/user/${id}`, bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  delete(id) {
    return http.delete(`/user/${id}`, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
}

export default new UserService();
