<template>
  <v-main>
    <!-- Provides the application the proper gutter -->
    <v-container fluid>
      <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>

      <!-- If using vue-router -->
      <!-- <router-view></router-view> -->
      <v-card>
        <v-card-title>
          Calon Anggota
          <v-spacer></v-spacer>

          <!-- <router-link
            to="/nakes-add"
            style="text-decoration: none; color: inherit"
          >
            <v-btn color="purple" dark class="mb-2"> Tambah </v-btn>
          </router-link> -->
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5"
                >Apa anda akan menghapus data ini?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete"
                  >Tidak</v-btn
                >
                <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                  >Ya</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialogVerifikasi" max-width="750px">
            <v-card>
              <v-card-title class="text-h5"
                >Apa anda akan meng-Approve verikasi keanggotaan data
                ini?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="dialogVerifikasi = false"
                  >Tidak</v-btn
                >
                <v-btn color="blue darken-1" text @click="verifikasiItemConfirm"
                  >Ya</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card-title>

        <v-row class="mx-4 my-4">
          <v-col cols="12" sm="4" md="4">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Cari Nama"
              single-line
              outlined
              dense
              hide-details
              @keyup.enter="getDataFromApi"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="4" md="4">
            <v-combobox
              v-model="selectedDpw"
              label="Dewan Pengurus Wilayah"
              clearable
              dense
              outlined
              :items="items_dpw"
              item-text="nama"
              :disabled="isDpwPengurus"
              @change="changeDpw"
            ></v-combobox>
          </v-col>
          <v-col cols="12" sm="4" md="4">
            <v-combobox
              v-model="selectedDpd"
              label="Dewan Pengurus Daerah"
              clearable
              dense
              outlined
              :items="items_dpd"
              item-text="nama"
              :disabled="isDpdPengurus"
              @change="changeDpd"
              @click="getListDpd"
            ></v-combobox>
          </v-col>
          <v-col cols="12" sm="4" md="4">
            <v-combobox
              v-model="selectedKomisariat"
              label="Komisariat"
              clearable
              dense
              outlined
              :items="items_komisariat"
              item-text="nama"
              :disabled="isAdminKomisariat"
              @change="changeKomisariat"
              @click="getListKomisariat"
            ></v-combobox>
          </v-col>
        </v-row>

        <v-data-table
          :headers="headers"
          :items="list_data"
          :options.sync="options"
          :server-items-length="totalData"
          :loading="loading"
          class="elevation-1"
        >
          <template #[`item.is_verified`]="{ item }">
            <div v-if="item.is_verified == 0">
              Menunggu Verifikasi Keanggotaan
            </div>
            <div v-else>Anggota</div>
          </template>

          <template #[`item.actions`]="{ item }">
            <v-icon
              v-if="item.is_verified == 0"
              class="mr-3"
              medium
              @click="verifikasiAnggota(item)"
            >
              mdi-check
            </v-icon>

            <v-icon medium class="mr-3" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon medium @click="deleteItem(item)"> mdi-delete </v-icon>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
import NakesService from "../services/NakesService";
import CommonService from "../services/CommonService";
import KomisariatService from "../services/KomisariatService";
import store from "../store/index";
import DPWService from "../services/DPWService";
import DPDService from "../services/DPDService";

export default {
  data: () => ({
    dialogVerifikasi: false,
    breadcrumbs: [
      {
        text: "Data Calon Anggota",
        disabled: true,
        href: "breadcrumbs_dashboard",
      },
    ],
    search: "",
    selectedDpw: "",
    selectedDpd: "",
    selectedKomisariat: "",
    items_komisariat: [],
    items_dpw: [],
    items_dpd: [],
    headers: [
      {
        text: "No",
        align: "start",
        sortable: false,
        value: "no",
      },
      { text: "Nama", value: "nama", sortable: false },
      { text: "No Telp", value: "phone", sortable: false },
      { text: "NIK", value: "no_ktp", sortable: false },
      { text: "STR", value: "str", sortable: false },
      { text: "DPW", value: "dpw_nama", sortable: false },
      { text: "DPD", value: "dpd_nama", sortable: false },
      { text: "Komisariat", value: "komisariat_nama", sortable: false },
      { text: "Status", value: "is_verified", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
    isFirst: false,
    totalData: 0,
    list_data: [],
    loading: true,
    options: {
      itemsPerPage: 10,
    },

    editedIndex: -1,
    editedItem: {},
    dialogDelete: false,
  }),
  computed: {
    getDpwPengurusId() {
      return store.getters.getUser.dpw_id;
    },
    getDpdPengurusId() {
      return store.getters.getUser.dpd_id;
    },
    isDpwPengurus() {
      if (store.getters.getUser.dpw_id) {
        return true;
      } else {
        return false;
      }
    },
    isDpdPengurus() {
      if (store.getters.getUser.dpd_id) {
        return true;
      } else {
        return false;
      }
    },
    getKomisariatId() {
      return store.getters.getUser.komisariat_id;
    },
    isAdminKomisariat() {
      if (store.getters.getUser.komisariat_id) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    options: {
      handler() {
        if (this.isFirst) {
          this.getDataFromApi();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getListDpw();
    this.getDataFromApi();
  },

  methods: {
    changeDpw() {
      this.selectedDpd = null;
      this.selectedKomisariat = null;
      this.getDataFromApi();
    },

    changeDpd() {
      this.selectedKomisariat = null;
      this.getDataFromApi();
    },

    async getListDpw() {
      DPWService.dpwListAll()
        .then((res) => {
          this.items_dpw = res.data.list_data;

          if (this.getDpwPengurusId) {
            this.items_dpw.forEach((entry) => {
              if (entry.id == this.getDpwPengurusId) {
                this.selectedDpw = entry;

                if (this.getDpdPengurusId) {
                  this.getListDpd();
                } else {
                  this.getDataFromApi();
                }
              }
            });
          } else {
            this.getDataFromApi();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getListDpd() {
      var dpwId = "";

      if (this.selectedDpw) {
        dpwId = this.selectedDpw.id;
      }

      DPDService.dpdListAll(dpwId)
        .then((res) => {
          this.items_dpd = res.data.list_data;

          if (this.getDpdPengurusId) {
            this.items_dpd.forEach((entry) => {
              if (entry.id == this.getDpdPengurusId) {
                this.selectedDpd = entry;
                this.getDataFromApi();
              }
            });
          } else {
            this.getDataFromApi();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getListKomisariat() {
      KomisariatService.komisariatListAllFilter(this.selectedDpd.id)
        .then((res) => {
          this.items_komisariat = res.data.list_data;

          if (this.getKomisariatId) {
            this.items_komisariat.forEach((entry) => {
              if (entry.id == this.getKomisariatId) {
                this.selectedKomisariat = entry;
                this.getDataFromApi();
              }
            });
          } else {
            this.getDataFromApi();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    changeKomisariat() {
      this.getDataFromApi();
    },

    getDataFromApi() {
      const { page, itemsPerPage } = this.options;

      var komisariat = "";

      if (this.selectedKomisariat) {
        komisariat = this.selectedKomisariat.id;
      }

      var dpw = "";
      var dpd = "";

      if (this.selectedDpw) {
        dpw = this.selectedDpw.id;
      }

      if (this.selectedDpd) {
        dpd = this.selectedDpd.id;
      }

      this.loading = true;
      NakesService.calonNakesList(
        page,
        itemsPerPage,
        this.search,
        komisariat,
        dpw,
        dpd,
      )
        .then((res) => {
          this.list_data = res.data.list_data;
          this.totalData = res.data.total;
          this.loading = false;
          this.isFirst = true;
        })
        .catch((err) => {
          this.loading = false;
          this.isFirst = true;
          console.log(err);
        });
    },

    editItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.$router.push("/nakes-edit/" + item.id).catch((err) => {
        console.log(err);
      });
    },

    deleteItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.closeDelete();

      NakesService.delete(this.editedItem.id)
        .then((res) => {
          console.log(res);

          CommonService.showSuccessToast("Hapus Calon Anggota berhasil");

          this.getDataFromApi();
        })
        .catch((err) => {
          console.log(err);

          CommonService.showErrorToast("Hapus Calon Anggota gagal. Coba lagi");
        });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    verifikasiAnggota(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.dialogVerifikasi = true;
    },

    verifikasiItemConfirm() {
      NakesService.verifikasiCalonAnggota(this.editedItem)
        .then((res) => {
          console.log(res);

          CommonService.showSuccessToast("Approve Calon Anggota Berhasil");

          this.dialogVerifikasi = false;

          this.getDataFromApi();
        })
        .catch((err) => {
          console.log(err);

          CommonService.showErrorToast(
            "Approve Calon Anggota Gagal. Coba lagi",
          );

          this.dialogVerifikasi = false;
        });
    },
  },
};
</script>
