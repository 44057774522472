<template>
  <div>
    <v-card>
      <v-card-title>
        Master Iuran
        <v-spacer></v-spacer>

        <v-btn color="purple" dark class="mb-2" @click="doAdd"> Tambah </v-btn>

        <v-dialog v-model="dialog" persistent max-width="600px">
          <v-card>
            <v-card-title>
              <span class="text-h5">Master Iuran</span>
            </v-card-title>
            <v-card-text>
              <v-form
                ref="form"
                v-model="valid"
                lazy-validation
                class="pl-4 pr-4"
              >
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-combobox
                        v-model="currentData.selectedDpw"
                        label="Dewan Pengurus Wilayah"
                        clearable
                        dense
                        outlined
                        :items="items_dpw"
                        item-text="nama"
                        :disabled="isDpwPengurus"
                        @change="changeDpwInput"
                      ></v-combobox>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-combobox
                        v-model="currentData.selectedDpd"
                        label="Dewan Pengurus Daerah"
                        clearable
                        dense
                        outlined
                        :items="items_dpd"
                        item-text="nama"
                        :disabled="isDpdPengurus"
                        @change="changeDpdInput"
                        @click="getListDpdInput"
                      ></v-combobox>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-combobox
                        v-model="currentData.selectedKomisariat"
                        label="Komisariat"
                        clearable
                        dense
                        outlined
                        :items="items_komisariat"
                        item-text="nama"
                        :rules="currentData.komisariatRules"
                        :disabled="isAdminKomisariat"
                        @click="getListKomisariatInput"
                      ></v-combobox>
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <v-col cols="12" sm="12" md="12">
                      <v-combobox
                        v-model="currentData.tahun"
                        label="Tahun"
                        clearable
                        dense
                        outlined
                        :items="items_tahun"
                        item-text="name"
                        :rules="currentData.tahunRules"
                      ></v-combobox>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field
                        v-model="currentData.nominal"
                        label="Nominal"
                        outlined
                        dense
                        required
                        :rules="currentData.nominalRules"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialog = false">
                Close
              </v-btn>
              <v-btn color="blue darken-1" text @click="doSave"> Save </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5"
              >Apa anda akan menghapus data ini?</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete"
                >Tidak</v-btn
              >
              <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                >Ya</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card-title>

      <v-row class="mx-6 mb-6">
        <v-col cols="12" sm="4" md="4">
          <v-combobox
            v-model="search.selectedDpw"
            label="Dewan Pengurus Wilayah"
            clearable
            dense
            outlined
            :items="items_dpw"
            item-text="nama"
            :disabled="isDpwPengurus"
            @change="changeDpw"
          ></v-combobox>
        </v-col>
        <v-col cols="12" sm="4" md="4">
          <v-combobox
            v-model="search.selectedDpd"
            label="Dewan Pengurus Daerah"
            clearable
            dense
            outlined
            :items="items_dpd"
            item-text="nama"
            :disabled="isDpdPengurus"
            @change="changeDpd"
            @click="getListDpd"
          ></v-combobox>
        </v-col>
        <v-col cols="12" sm="4" md="4">
          <v-combobox
            v-model="search.selectedKomisariat"
            label="Komisariat"
            clearable
            dense
            outlined
            :items="items_komisariat"
            item-text="nama"
            :disabled="isAdminKomisariat"
            @change="changeKomisariat"
            @click="getListKomisariat"
          ></v-combobox>
        </v-col>
        <v-col cols="12" sm="4" md="4">
          <v-combobox
            v-model="search.tahun"
            label="Tahun"
            clearable
            dense
            outlined
            :items="items_tahun"
            item-text="name"
            @change="getDataFromApi"
          ></v-combobox>
        </v-col>
      </v-row>

      <v-data-table
        :headers="headers"
        :items="list_data"
        :options.sync="options"
        :server-items-length="totalData"
        :loading="loading"
        class="elevation-1"
      >
        <template #[`item.kategori`]="{ item }">
          <v-chip :color="getColorKategori(item.kategori)" dark>
            {{ getKategori(item.kategori) }}
          </v-chip>
        </template>
        <template #[`item.status`]="{ item }">
          <v-chip :color="getColor(item.status)" dark>
            {{ getStatus(item.status) }}
          </v-chip>
        </template>
        <template #[`item.foto_url`]="{ item }">
          <v-img
            style="cursor: pointer"
            height="auto"
            width="100"
            :src="item.foto_url"
            @click="showFotoBuktiBayar(item.foto_url)"
          ></v-img>
        </template>
        <template #[`item.nominal`]="{ item }">
          {{ new Intl.NumberFormat("id-ID").format(item.nominal) }}
        </template>
        <template #[`item.actions`]="{ item }">
          <v-icon medium class="mr-3" @click="editItem(item)">
            mdi-pencil
          </v-icon>
          <v-icon medium @click="deleteItem(item)"> mdi-delete </v-icon>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import MasterIuranService from "../services/MasterIuranService";
import CommonService from "../services/CommonService";
import KomisariatService from "../services/KomisariatService";
import store from "../store/index";
import DPWService from "../services/DPWService";
import DPDService from "../services/DPDService";

export default {
  components: {},
  data: () => ({
    isCabangDisabled: false,
    valid: true,
    selected: [],
    search: {
      nama: "",
      tahun: "",
      selectedDpw: "",
      selectedDpd: "",
      selectedKomisariat: "",
    },
    headers: [
      {
        text: "No",
        align: "start",
        sortable: false,
        value: "no",
      },
      { text: "Komisariat", value: "komisariat_nama", sortable: false },
      { text: "Tahun", value: "tahun", sortable: false },
      { text: "Nominal", value: "nominal", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
    isFirst: false,
    totalData: 0,
    list_data: [],
    loading: true,
    options: {
      itemsPerPage: 10,
    },

    editedIndex: -1,
    editedItem: {},
    dialog: false,
    dialogDelete: false,

    currentData: {
      id: null,
      selectedDpw: "",
      selectedDpd: "",
      selectedKomisariat: "",
      tahun: "",
      nominal: "",
      tahunRules: [(v) => !!v || "Tahun harus diisi"],
      nominalRules: [(v) => !!v || "Nominal harus diisi"],
      komisariatRules: [(v) => !!v || "Komisariat harus diisi"],
    },

    items_tahun: [],
    items_dpw: [],
    items_dpd: [],
    items_komisariat: [],
  }),

  computed: {
    getDpwPengurusId() {
      return store.getters.getUser.dpw_id;
    },
    getDpdPengurusId() {
      return store.getters.getUser.dpd_id;
    },
    isDpwPengurus() {
      if (store.getters.getUser.dpw_id) {
        return true;
      } else {
        return false;
      }
    },
    isDpdPengurus() {
      if (store.getters.getUser.dpd_id) {
        return true;
      } else {
        return false;
      }
    },
    getKomisariatId() {
      return store.getters.getUser.komisariat_id;
    },
    isAdminKomisariat() {
      if (store.getters.getUser.komisariat_id) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    selected: {
      handler() {
        if (this.selected != undefined && this.selected[0]) {
          this.childKey++;
        }
      },
      deep: true,
    },
    options: {
      handler() {
        if (this.isFirst) {
          this.getDataFromApi();
        }
      },
      deep: true,
    },
  },
  mounted() {
    for (var i = new Date().getFullYear() + 5; i >= 1990; i--) {
      this.items_tahun.push(i);
    }

    // this.getListKomisariat();
    this.getListDpw();
    this.getListDpwInput();
    this.getDataFromApi();
  },
  methods: {
    changeDpw() {
      this.search.selectedDpd = null;
      this.selectedKomisariat = null;
      this.getDataFromApi();
    },

    changeDpwInput() {
      this.currentData.selectedDpd = null;
      this.currentData.selectedKomisariat = null;
    },

    changeDpd() {
      this.selectedKomisariat = null;
      this.getDataFromApi();
    },

    changeDpdInput() {
      this.currentData.selectedKomisariat = null;
    },

    async getListDpw() {
      DPWService.dpwListAll()
        .then((res) => {
          this.items_dpw = res.data.list_data;

          if (this.getDpwPengurusId) {
            this.items_dpw.forEach((entry) => {
              if (entry.id == this.getDpwPengurusId) {
                this.search.selectedDpw = entry;

                if (this.getDpdPengurusId) {
                  this.getListDpd();
                } else {
                  this.getDataFromApi();
                }
              }
            });
          } else {
            this.getDataFromApi();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getListDpwInput() {
      DPWService.dpwListAll()
        .then((res) => {
          this.items_dpw = res.data.list_data;

          if (this.getDpwPengurusId) {
            this.items_dpw.forEach((entry) => {
              if (entry.id == this.getDpwPengurusId) {
                this.currentData.selectedDpw = entry;

                if (this.getDpdPengurusId) {
                  this.getListDpdInput();
                }
              }
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getListDpd() {
      var dpwId = "";

      if (this.search.selectedDpw) {
        dpwId = this.search.selectedDpw.id;
      }

      DPDService.dpdListAll(dpwId)
        .then((res) => {
          this.items_dpd = res.data.list_data;

          if (this.getDpdPengurusId) {
            this.items_dpd.forEach((entry) => {
              if (entry.id == this.getDpdPengurusId) {
                this.search.selectedDpd = entry;
                this.getDataFromApi();
              }
            });
          } else {
            this.getDataFromApi();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getListDpdInput() {
      var dpwId = "";

      if (this.currentData.selectedDpw) {
        dpwId = this.currentData.selectedDpw.id;
      }

      DPDService.dpdListAll(dpwId)
        .then((res) => {
          this.items_dpd = res.data.list_data;

          if (this.getDpdPengurusId) {
            this.items_dpd.forEach((entry) => {
              if (entry.id == this.getDpdPengurusId) {
                this.currentData.selectedDpd = entry;
              }
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    changeKomisariat() {
      this.getDataFromApi();
    },
    async getListKomisariat() {
      KomisariatService.komisariatListAllFilter(this.search.selectedDpd.id)
        .then((res) => {
          this.items_komisariat = res.data.list_data;

          if (this.getKomisariatId) {
            this.items_komisariat.forEach((entry) => {
              if (entry.id == this.getKomisariatId) {
                this.search.selectedKomisariat = entry;
                this.getDataFromApi();
              }
            });
          } else {
            this.getDataFromApi();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getListKomisariatInput() {
      KomisariatService.komisariatListAllFilter(this.currentData.selectedDpd.id)
        .then((res) => {
          this.items_komisariat = res.data.list_data;

          if (this.getKomisariatId) {
            this.items_komisariat.forEach((entry) => {
              if (entry.id == this.getKomisariatId) {
                this.currentData.selectedKomisariat = entry;
              }
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getDataFromApi() {
      const { page, itemsPerPage } = this.options;

      this.loading = true;

      var tahun = "";
      var kategori = "";
      var status = "";
      var propinsi = "";
      var dpw = "";
      var dpd = "";

      if (this.search.tahun) {
        tahun = this.search.tahun;
      }

      if (this.search.selectedKategori) {
        kategori = this.search.selectedKategori.id;
      }

      if (this.search.selectedStatus) {
        status = this.search.selectedStatus.id;
      }

      if (this.search.selectedPropinsi) {
        propinsi = this.search.selectedPropinsi.id;
      }

      var komisariat = "";

      if (this.search.selectedKomisariat) {
        komisariat = this.search.selectedKomisariat.id;
      }

      if (this.search.selectedDpw) {
        dpw = this.search.selectedDpw.id;
      }

      if (this.search.selectedDpd) {
        dpd = this.search.selectedDpd.id;
      }

      MasterIuranService.iuranList(
        page,
        itemsPerPage,
        this.search.nama,
        tahun,
        kategori,
        status,
        propinsi,
        komisariat,
        dpw,
        dpd,
      )
        .then((res) => {
          this.list_data = res.data.list_data;
          this.totalData = res.data.total;
          this.loading = false;
          this.isFirst = true;
        })
        .catch((err) => {
          this.loading = false;
          this.isFirst = true;
          console.log(err);
        });
    },

    doAdd: async function () {
      this.dialog = true;
      this.currentData.id = null;
      this.currentData.nama = "";
      this.currentData.urutan = "";
      this.currentData.no_npa = "";
      this.currentData.tahun = "";
      this.currentData.nominal = "";
      this.currentData.foto_url = null;
      this.currentData.FotoUrl = "";
      this.currentData.langsung_setujui = false;
      this.currentData.selectedJabatan = "";
      this.currentData.cabang_asal_nama = "";
      this.currentData.cabang_asal = "";
      this.currentData.selectedKategori = null;
      this.currentData.langsung_setujui = false;
      // this.currentData.selectedPropinsi = "";
      this.isCabangDisabled = false;

      if (!this.getKomisariatId) {
        this.currentData.selectedKomisariat = null;
      }

      console.log("do add");
    },

    doSave: async function () {
      if (!this.$refs.form.validate()) {
        return;
      }

      if (this.currentData.id != null) {
        MasterIuranService.update(this.currentData.id, this.currentData)
          .then((res) => {
            console.log(res);
            this.dialog = false;
            CommonService.showSuccessToast("Update master iuran berhasil");

            this.getDataFromApi();
          })
          .catch((err) => {
            console.log(err);
            CommonService.showErrorToast(
              "Update master iuran gagal. Coba lagi",
            );
          });
      } else {
        MasterIuranService.create(this.currentData, this.currentData.foto_url)
          .then((res) => {
            console.log(res);
            this.dialog = false;
            CommonService.showSuccessToast("Tambah master iuran berhasil");

            this.getDataFromApi();
          })
          .catch((err) => {
            console.log(err);
            CommonService.showErrorToast(
              "Tambah master iuran gagal. Coba lagi",
            );
          });
      }
    },

    editItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.dialog = true;
      this.isCabangDisabled = true;
      this.currentData.tahun = this.editedItem.tahun;
      this.currentData.nominal = this.editedItem.nominal;
      this.currentData.id = this.editedItem.id;

      this.items_komisariat.forEach((entry) => {
        if (entry.id == this.editedItem.komisariat_id) {
          this.currentData.selectedKomisariat = entry;
        }
      });
    },

    deleteItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.closeDelete();

      MasterIuranService.delete(this.editedItem.id)
        .then((res) => {
          console.log(res);
          CommonService.showSuccessToast("Hapus master iuran berhasil");

          this.getDataFromApi();
        })
        .catch((err) => {
          console.log(err);

          CommonService.showErrorToast("Hapus master iuran gagal. Coba lagi");
        });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    showFotoBuktiBayar(foto) {
      this.fotoBuktiBayar = foto;
      this.dialogBuktiBayar = true;
    },
  },
};
</script>
