import http from "../../http-common";
/* eslint-disable */
class FasilitatorService {
  fasilitatorList(page, itemPerPage, keyword, komisariat) {
    return http.get(
      "/fasilitator/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword +
        "&komisariat_id=" +
        komisariat,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      },
    );
  }

  create(data) {
    var bodyFormData = new FormData();
    bodyFormData.append("nama", data.nama);
    bodyFormData.append("nip", data.nip);
    bodyFormData.append("pendidikan_formal", data.pendidikan_formal);
    bodyFormData.append("pendidikan_informal", data.pendidikan_informal);

    if (data.selectedKomisariat && data.selectedKomisariat != undefined) {
      bodyFormData.append("komisariat_id", data.selectedKomisariat.id);
    }

    if (data.foto_url != null) bodyFormData.append("photo", data.foto_url);

    return http.post("/fasilitator", bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  update(data) {
    var bodyFormData = new FormData();
    bodyFormData.append("id", data.id);
    bodyFormData.append("nama", data.nama);
    bodyFormData.append("nip", data.nip);
    bodyFormData.append("pendidikan_formal", data.pendidikan_formal);
    bodyFormData.append("pendidikan_informal", data.pendidikan_informal);

    if (data.selectedKomisariat && data.selectedKomisariat != undefined) {
      bodyFormData.append("komisariat_id", data.selectedKomisariat.id);
    }

    if (data.foto_url != null) bodyFormData.append("photo", data.foto_url);

    return http.put(`/fasilitator/${data.id}`, bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  delete(id) {
    return http.delete(`/fasilitator/${id}`, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
}

export default new FasilitatorService();
