<template>
  <div>
    <v-card>
      <v-card class="pl-4 pr-4 pt-4 pb-4">
        <v-card-title> Sertifikat </v-card-title>
        <v-fab-transition>
          <v-btn
            v-if="isEditable == 0"
            class="mt-8 mr-4"
            color="pink"
            dark
            absolute
            top
            right
            fab
            @click="changeEditable"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </v-fab-transition>
        <v-btn color="purple" dark class="mb-2" @click="getDownloadSertifikat">
          Download Sample
        </v-btn>
        <v-form ref="form" v-model="valid" lazy-validation class="pl-4 pr-4">
          <v-row dense>
            <v-col cols="12" sm="6" md="6" class="pr-4">
              <v-sheet color="white" elevation="2">
                <v-responsive :aspect-ratio="16 / 9">
                  <v-img :key="fotoKey" :src="currentData.FotoUrl" contain>
                  </v-img>
                </v-responsive>
              </v-sheet>
              <v-file-input
                v-model="currentData.file_foto"
                accept="image/*"
                prepend-icon="mdi-image"
                show-size
                label="Foto"
                :disabled="isEditable == 0"
                @change="loadImage"
              />
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-row dense>
                <!-- <v-col
                  cols="12"
                  sm="6"
                  md="6"
                >
                  <v-text-field
                    label="Posisi Nama Horizontal"
                    outlined
                    dense
                    v-model="currentData.posisi_nama_x"
                    :readonly="isEditable == 0"
                    required
                  ></v-text-field>
                </v-col> -->
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    v-model="currentData.posisi_nama_y"
                    label="Posisi Nama Vertical"
                    outlined
                    dense
                    :readonly="isEditable == 0"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" class="d-flex justify-end">
              <v-btn
                v-if="isEditable == 1"
                style="margin-right: 10px"
                @click="doBatal"
                >Batal</v-btn
              >
              <v-btn
                v-if="isEditable == 1"
                dark
                color="primary"
                type="button"
                @click="doSubmit"
                >Simpan</v-btn
              >
            </v-col>
          </v-row>
        </v-form>
      </v-card>
    </v-card>
  </div>
</template>

<script>
import CommonService from "../../services/CommonService";
import SertifikatService from "../../services/SertifikatService";

export default {
  name: "KegiatanEdit",
  props: {
    id: {
      type: String,
      default: "0",
    },
  },
  data: () => ({
    isEditable: 0,
    valid: true,
    currentData: {
      posisi_nama_x: 0,
      posisi_nama_y: 0,
      FotoUrl: "",
      file_foto: null,
      kegiatan_id: "",
      kegiatan_title: "",
      kegiatan_desc: "",
      informasi: "",
      prasyarat_khusus: "",
      harga: "",
      tanggal_awal: "",
      tanggal_akhir: "",
      jenis_kegiatan: "",
      skp_narasumber: "",
      skp_moderator: "",
      skp_panitia: "",
      skp_peserta: "",
      nomor_skp: "",
      penyelenggara: "",
      kategori: "",
      selectedKategori: "",
      kegiatanTitleRules: [(v) => !!v || "Judul harus diisi"],
      kegiatanDescRules: [(v) => !!v || "Deskripsi harus diisi"],
    },
    fotoKey: 0,
  }),
  computed: {},
  mounted: async function () {
    this.getSertifikat();
  },
  methods: {
    changeEditable: function () {
      this.isEditable = 1;
    },

    loadImage: function (data) {
      console.log("loadImage:", data);
      if (data) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.currentData.FotoUrl = e.target.result;
          this.fotoKey++;
        };
        reader.readAsDataURL(data);
      } else {
        this.currentData.FotoUrl = "";
        this.currentData.file_foto = null;
      }
    },

    getSertifikat() {
      SertifikatService.getSertifikat(this.id)
        .then((res) => {
          this.currentData = res.data.sertifikat;
          this.currentData.FotoUrl = res.data.sertifikat.photo;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getDownloadSertifikat() {
      SertifikatService.downloadSertifikat(this.id)
        .then((res) => {
          console.log(res.data.file);
          window.open(res.data.file, "_blank");
        })
        .catch((err) => {
          console.log(err);
          CommonService.showErrorToast("Download sertifikat gagal. Coba lagi");
        });
    },

    doBatal: function () {
      this.isEditable = 0;
    },

    doSubmit: async function () {
      if (!this.$refs.form.validate()) {
        window.scrollTo(0, 0);
        return;
      }

      if (this.currentData.id == null) {
        this.currentData.kegiatan_id = this.id;
        SertifikatService.create(this.currentData)
          .then((res) => {
            console.log(res);
            CommonService.showSuccessToast("Tambah sertifikat berhasil");
            this.isEditable = 0;
          })
          .catch((err) => {
            console.log(err);
            CommonService.showErrorToast("Tambah sertifikat gagal. Coba lagi");
          });
      } else {
        SertifikatService.update(this.currentData)
          .then((res) => {
            console.log(res);
            CommonService.showSuccessToast("Update sertifikat berhasil");

            this.isEditable = 0;
          })
          .catch((err) => {
            console.log(err);
            CommonService.showErrorToast("Update sertifikat gagal. Coba lagi");
          });
      }
    },
  },
};
</script>

<style scoped></style>
