import http from "../http-common";
/* eslint-disable */
class UjianService {
  ujianList(page, itemPerPage, keyword, pelatihanDetailId) {
    return http.get(
      "/ujian/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword +
        "&pelatihan_detail_id=" +
        pelatihanDetailId,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  ujianListCopy() {
    return http.get("/ujian/list/copy", {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  createPreTest(data) {
    data.jenis_ujian = 1;
    return http.post("/ujian", data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  createPostTest(data) {
    data.jenis_ujian = 2;
    return http.post("/ujian", data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  copyPreTest(data) {
    data.jenis_ujian = 1;
    return http.post("/copy-ujian", data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  copyPostTest(data) {
    data.jenis_ujian = 2;
    return http.post("/copy-ujian", data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  update(data) {
    return http.put(`/ujian/${data.id}`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  delete(id) {
    return http.delete(`/ujian/${id}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }
}

export default new UjianService();
