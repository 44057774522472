<template>
  <div>
    <v-card>
      <v-card-title>
        Membership
        <v-spacer></v-spacer>
      </v-card-title>

      <v-row class="mx-6 mb-6">
        <v-col cols="12" sm="4" md="4">
          <v-text-field
            v-model="search.nama"
            append-icon="mdi-magnify"
            label="Cari nama"
            single-line
            hide-details
            @keyup.enter="getDataFromApi"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="4" md="4">
          <v-text-field
            v-model="search.invoice_no"
            append-icon="mdi-magnify"
            label="No Invoice"
            single-line
            hide-details
            @keyup.enter="getDataFromApi"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="4" md="4">
          <v-combobox
            v-model="search.selectedDpw"
            label="Dewan Pengurus Wilayah"
            clearable
            dense
            outlined
            :items="items_dpw"
            item-text="nama"
            :disabled="isDpwPengurus"
            @change="changeDpw"
          ></v-combobox>
        </v-col>
        <v-col cols="12" sm="4" md="4">
          <v-combobox
            v-model="search.selectedDpd"
            label="Dewan Pengurus Daerah"
            clearable
            dense
            outlined
            :items="items_dpd"
            item-text="nama"
            :disabled="isDpdPengurus"
            @change="changeDpd"
            @click="getListDpd"
          ></v-combobox>
        </v-col>
        <v-col cols="12" sm="4" md="4">
          <v-combobox
            v-model="search.selectedKomisariat"
            label="Komisariat"
            clearable
            dense
            outlined
            :items="items_komisariat"
            item-text="nama"
            :disabled="isAdminKomisariat"
            @change="changeKomisariat"
            @click="getListKomisariat"
          ></v-combobox>
        </v-col>
        <v-col cols="12" sm="4" md="4">
          <v-combobox
            v-model="search.selectedStatus"
            label="Status"
            clearable
            dense
            outlined
            :items="items_status"
            item-text="name"
            @change="getDataFromApi"
          ></v-combobox>
        </v-col>
      </v-row>

      <v-data-table
        :headers="headers"
        :items="list_data"
        :options.sync="options"
        :server-items-length="totalData"
        :loading="loading"
        class="elevation-1"
      >
        <template #[`item.kategori`]="{ item }">
          <v-chip :color="getColorKategori(item.kategori)" dark>
            {{ getKategori(item.kategori) }}
          </v-chip>
        </template>
        <template #[`item.created_at`]="{ item }">
          {{ getCurrentDate(item.created_at) }}
        </template>
        <template #[`item.status`]="{ item }">
          <v-chip :color="getColor(item.status)" dark>
            {{ getStatus(item.status) }}
          </v-chip>
        </template>
        <template #[`item.foto_url`]="{ item }">
          <v-btn
            v-if="item.kategori == 4"
            color="blue"
            dark
            class="mb-2"
            @click="showFotoBuktiBayar(item.foto_url)"
          >
            View
          </v-btn>
        </template>
        <template #[`item.nominal`]="{ item }">
          {{ new Intl.NumberFormat("id-ID").format(item.nominal) }}
        </template>
        <template #[`item.nominal_pusat`]="{ item }">
          <div v-if="item.nominal_pusat">
            <div v-if="item.kategori == 1 || item.kategori == 3">
              {{ new Intl.NumberFormat("id-ID").format(item.nominal_pusat) }}
            </div>
          </div>
        </template>
        <template #[`item.nominal_cabang`]="{ item }">
          <div v-if="item.nominal_cabang">
            <div v-if="item.kategori == 1 || item.kategori == 3">
              {{ new Intl.NumberFormat("id-ID").format(item.nominal_cabang) }}
            </div>
          </div>
        </template>
        <template #[`item.virtual_account`]="{ item }">
          <div v-if="item.virtual_account">
            {{ item.virtual_account }}
          </div>
        </template>
        <template #[`item.pusat_splitted`]="{ item }">
          <div v-if="item.kategori == 1 || item.kategori == 3">
            <v-chip :color="getColorSplitted(item.pusat_splitted)" dark>
              {{ getSplittedType(item.pusat_splitted) }}
            </v-chip>
          </div>
        </template>
        <template #[`item.cabang_splitted`]="{ item }">
          <div v-if="item.kategori == 1 || item.kategori == 3">
            <v-chip :color="getColorSplitted(item.cabang_splitted)" dark>
              {{ getSplittedType(item.cabang_splitted) }}
            </v-chip>
          </div>
        </template>
        <template #[`item.actions`]="{ item }">
          <div class="d-flex flex-row">
            <v-icon
              v-if="
                item.kategori == 4 &&
                (item.status == 2 || item.status == 3 || item.status == 7) &&
                (item.is_approver == 1 || getRoleId == 1)
              "
              medium
              class="mr-3"
              @click="approveItem(item)"
            >
              mdi-check
            </v-icon>
            <v-icon
              v-if="
                item.kategori == 4 &&
                (item.status == 2 || item.status == 3 || item.status == 7) &&
                (item.is_approver == 1 || getRoleId == 1)
              "
              medium
              class="mr-3"
              @click="rejectItem(item)"
            >
              mdi-close
            </v-icon>
          </div>
          <!-- <v-icon
            medium
            @click="deleteItem(item)"
          >
            mdi-delete
          </v-icon> -->
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import IuranService from "../services/InvoiceService";
import KomisariatService from "../services/KomisariatService";
import * as moment from "moment";

import store from "../store/index";
import DPWService from "../services/DPWService";
import DPDService from "../services/DPDService";

export default {
  name: "KeuanganAnggota",
  components: {},
  data: () => ({
    dialogBuktiBayar: false,
    dialogApprove: false,
    dialogReject: false,
    selected: [],
    search: {
      nama: "",
      invoice_no: "",
      tahun: "",
      selectedDpw: "",
      selectedDpd: "",
      selectedKategori: "",
      selectedStatus: "",
      selectedKomisariat: "",
    },
    headers: [
      {
        text: "No",
        align: "start",
        sortable: false,
        value: "no",
      },
      { text: "Nama", value: "user_nama", sortable: false, width: "210px" },
      {
        text: "Komisariat",
        value: "komisariat_nama",
        sortable: false,
        width: "120px",
      },
      { text: "NIRA", value: "nira", sortable: false },
      { text: "Invoice No", value: "invoice_no", sortable: false },
      { text: "Tanggal", value: "created_at", sortable: false },
      { text: "Tahun", value: "tahun", sortable: false },
      { text: "Nominal", value: "nominal", sortable: false },
      {
        text: "No VA",
        value: "virtual_account",
        sortable: false,
        width: "100px",
      },
      { text: "Status", value: "status", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
    isFirst: false,
    totalData: 0,
    list_data: [],
    loading: true,
    options: {
      page: 1,
      itemsPerPage: 10,
    },

    editedIndex: -1,
    editedItem: {},

    items_tahun: [],
    items_dpw: [],
    items_dpd: [],
    items_komisariat: [],
    items_status: [
      {
        id: 1,
        name: "Menunggu Pembayaran Anggota",
      },
      {
        id: 4,
        name: "Terverifikasi",
      },
    ],

    fotoBuktiBayar: null,
  }),

  computed: {
    getDpwPengurusId() {
      return store.getters.getUser.dpw_id;
    },
    getDpdPengurusId() {
      return store.getters.getUser.dpd_id;
    },
    isDpwPengurus() {
      if (store.getters.getUser.dpw_id) {
        return true;
      } else {
        return false;
      }
    },
    isDpdPengurus() {
      if (store.getters.getUser.dpd_id) {
        return true;
      } else {
        return false;
      }
    },
    getRoleId() {
      return store.getters.getUser.role_id;
    },
    getKomisariatId() {
      return store.getters.getUser.komisariat_id;
    },
    isAdminKomisariat() {
      if (store.getters.getUser.komisariat_id) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    selected: {
      handler() {
        if (this.selected != undefined && this.selected[0]) {
          this.childKey++;
        }
      },
      deep: true,
    },
    options: {
      handler() {
        console.log("memanggil sekali");
        if (this.isFirst) {
          this.getDataFromApi();
        }
      },
      deep: true,
    },
  },
  mounted() {
    for (var i = new Date().getFullYear() + 5; i >= 1990; i--) {
      this.items_tahun.push(i);
    }

    this.search.tahun = new Date().getFullYear();

    // this.getListKomisariat();
    this.getListDpw();
    this.getDataFromApi();
  },
  methods: {
    changeDpw() {
      this.search.selectedDpd = null;
      this.selectedKomisariat = null;
      this.getDataFromApi();
    },

    changeDpd() {
      this.selectedKomisariat = null;
      this.getDataFromApi();
    },

    async getListDpw() {
      DPWService.dpwListAll()
        .then((res) => {
          this.items_dpw = res.data.list_data;

          if (this.getDpwPengurusId) {
            this.items_dpw.forEach((entry) => {
              if (entry.id == this.getDpwPengurusId) {
                this.search.selectedDpw = entry;

                if (this.getDpdPengurusId) {
                  this.getListDpd();
                } else {
                  this.getDataFromApi();
                }
              }
            });
          } else {
            this.getDataFromApi();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getListDpd() {
      var dpwId = "";

      if (this.search.selectedDpw) {
        dpwId = this.search.selectedDpw.id;
      }

      DPDService.dpdListAll(dpwId)
        .then((res) => {
          this.items_dpd = res.data.list_data;

          if (this.getDpdPengurusId) {
            this.items_dpd.forEach((entry) => {
              if (entry.id == this.getDpdPengurusId) {
                this.search.selectedDpd = entry;
                this.getDataFromApi();
              }
            });
          } else {
            this.getDataFromApi();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getListKomisariat() {
      KomisariatService.komisariatListAllFilter(this.search.selectedDpd.id)
        .then((res) => {
          this.items_komisariat = res.data.list_data;

          if (this.getKomisariatId) {
            this.items_komisariat.forEach((entry) => {
              if (entry.id == this.getKomisariatId) {
                this.search.selectedKomisariat = entry;
                this.getDataFromApi();
              }
            });
          } else {
            this.getDataFromApi();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    changeKomisariat() {
      this.getDataFromApi();
    },
    getSplittedType(id) {
      if (id == 1) {
        return "Sudah";
      } else {
        return "Belum";
      }
    },
    getKategori(id) {
      if (id == 1) {
        return "Pembayaran Iuran pendaftaran Anggota";
      } else if (id == 2) {
        return "Pembayaran Tabsol";
      } else if (id == 3) {
        return "Pembayaran perpanjangan Iuran Anggota";
      } else if (id == 4) {
        return "Upload Data Iuran Lama";
      } else if (id == 5) {
        return "Pembayaran Pelatihan";
      } else if (id == 6) {
        return "Update Tabsol Lama";
      }
    },

    getColorKategori(dataStatus) {
      switch (dataStatus) {
        case 1:
          return "pink lighten-3";
        case 2:
          return "purple lighten-3";
        case 3:
          return "teal lighten-3";
        default:
          return "red lighten-3";
      }
    },

    getColor(dataStatus) {
      console.log(dataStatus);
      switch (dataStatus) {
        case 1:
          return "light-blue";
        case 2:
          return "green";
        case 3:
          return "cyan";
        case 4:
          return "blue";
        case 7:
          return "blue";
        default:
          return "red";
      }
    },

    getColorSplitted(dataStatus) {
      switch (dataStatus) {
        case 1:
          return "green";
        default:
          return "red";
      }
    },

    getStatus(id) {
      if (id == 1) {
        return "Menunggu Pembayaran Anggota";
      } else if (id == 2) {
        return "Menunggu Konfirmasi Komisariat";
      } else if (id == 3) {
        return "Menunggu Konfirmasi DPD";
      } else if (id == 4) {
        return "Terverifikasi";
      } else if (id == 5) {
        return "Rejected";
      } else if (id == 6) {
        return "Canceled";
      } else if (id == 7) {
        return "Menunggu Konfirmasi DPW";
      }
    },

    getCurrentDate(tanggal) {
      return moment(tanggal).format("DD/MM/YYYY");
    },

    getDataFromApi() {
      const { page, itemsPerPage } = this.options;

      this.loading = true;

      var kategori = "";
      var status = "";
      var komisariat = "";
      var dpw = "";
      var dpd = "";

      if (this.search.selectedKategori) {
        kategori = this.search.selectedKategori.id;
      }

      if (this.search.selectedStatus) {
        status = this.search.selectedStatus.id;
      }

      if (this.search.selectedKomisariat) {
        komisariat = this.search.selectedKomisariat.id;
      }

      if (this.search.selectedDpw) {
        dpw = this.search.selectedDpw.id;
      }

      if (this.search.selectedDpd) {
        dpd = this.search.selectedDpd.id;
      }

      console.log("ambil invoice");

      IuranService.invoiceMembershipList(
        page,
        itemsPerPage,
        this.search.nama,
        this.search.invoice_no,
        kategori,
        status,
        komisariat,
        dpw,
        dpd,
      )
        .then((res) => {
          this.list_data = res.data.list_data;
          this.totalData = res.data.total;
          this.loading = false;
          this.isFirst = true;
        })
        .catch((err) => {
          this.loading = false;
          this.isFirst = true;
          console.log(err);
        });
    },

    approveItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogApprove = true;
    },

    showFotoBuktiBayar(foto) {
      this.fotoBuktiBayar = foto;
      this.dialogBuktiBayar = true;
    },

    rejectItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogReject = true;
    },
  },
};
</script>
