<template>
  <v-card>
    <v-card-title>
      List Pelatihan
      <v-spacer></v-spacer>

      <v-dialog v-model="dialogAdd" max-width="500px">
        <v-card>
          <v-card-title class="text-h5">Tambah Pelatihan ini?</v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeAdd">Tidak</v-btn>
            <v-btn color="blue darken-1" text @click="addItemConfirm">Ya</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card-title>

    <v-row class="mx-4 my-4">
      <v-col cols="12" sm="4" md="4">
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Cari Nama"
          single-line
          outlined
          dense
          hide-details
          @keyup.enter="getDataFromApi"
        ></v-text-field>
      </v-col>
    </v-row>

    <v-data-table
      :headers="headers"
      :items="list_data"
      :options.sync="options"
      :server-items-length="totalData"
      :loading="loading"
      class="elevation-1"
    >
      <template #[`item.tanggal_awal`]="{ item }">
        {{ getCurrentDate(item.tanggal_awal) }}
      </template>
      <template #[`item.tanggal_akhir`]="{ item }">
        {{ getCurrentDate(item.tanggal_akhir) }}
      </template>
      <template #[`item.harga`]="{ item }">
        {{ new Intl.NumberFormat("id-ID").format(item.harga) }}
      </template>
      <template #[`item.data_status`]="{ item }">
        <div v-if="item.data_status == 0">Unpublished</div>
        <div v-else>Published</div>
      </template>
      <template #[`item.actions`]="{ item }">
        <v-chip color="cyan" dark @click="addItem(item)">
          Tambah Pelatihan
        </v-chip>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import CommonService from "../services/CommonService";
import PaketMembershipPelatihanService from "../services/PaketMembershipPelatihanService";
import * as moment from "moment";

export default {
  props: {
    refresh: Function,
    id: Number,
  },
  data: () => ({
    search: "",
    headers: [
      {
        text: "No",
        align: "start",
        sortable: false,
        value: "no",
      },
      { text: "Judul", value: "kegiatan_title", sortable: false },
      { text: "Kategori", value: "kategori_name", sortable: false },
      { text: "DPD", value: "dpd_name", sortable: false },
      { text: "Tanggal Awal", value: "tanggal_awal", sortable: false },
      { text: "Tanggal Akhir", value: "tanggal_akhir", sortable: false },
      { text: "Harga", value: "harga", sortable: false },
      { text: "Status", value: "data_status", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
    isFirst: false,
    totalData: 0,
    list_data: [],
    loading: true,
    options: {
      itemsPerPage: 10,
    },

    editedIndex: -1,
    editedItem: {},
    dialogAdd: false,
  }),
  computed: {},
  watch: {
    options: {
      handler() {
        if (this.isFirst) {
          this.getDataFromApi();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getDataFromApi();
  },
  methods: {
    getCurrentDate(tanggal) {
      return moment(tanggal).format("DD/MM/YYYY");
    },
    getDataFromApi() {
      const { page, itemsPerPage } = this.options;

      this.loading = true;
      PaketMembershipPelatihanService.kegiatanNonList(
        page,
        itemsPerPage,
        this.search,
        this.id,
      )
        .then((res) => {
          this.list_data = res.data.list_data;
          this.totalData = res.data.total;
          this.loading = false;
          this.isFirst = true;
        })
        .catch((err) => {
          this.loading = false;
          this.isFirst = true;
          console.log(err);
        });
    },

    addItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogAdd = true;
    },

    addItemConfirm() {
      this.closeAdd();

      this.editedItem.paket_membership_id = this.id;
      PaketMembershipPelatihanService.create(this.editedItem)
        .then((res) => {
          console.log(res);

          CommonService.showSuccessToast("Tambah pelatihan berhasil");

          this.getDataFromApi();
          this.refresh();
        })
        .catch((err) => {
          console.log(err);

          CommonService.showErrorToast("Tambah pelatihan gagal. Coba lagi");
        });
    },

    closeAdd() {
      this.dialogAdd = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
  },
};
</script>
