import http from "../../http-common";
/* eslint-disable */
class AgendaPelatihanService {
  agendaPelatihanList(page, itemPerPage, keyword, dpd) {
    return http.get(
      "/agenda-pelatihan/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword +
        "&dpd_id=" +
        dpd,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      },
    );
  }

  agendaPelatihan(page, itemPerPage, keyword) {
    return http.get("/agenda-pelatihan", {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  create(data) {
    var bodyFormData = new FormData();

    if (data.poster_url != null) bodyFormData.append("photo", data.poster_url);

    return http.post("/agenda-pelatihan", bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  update(data) {
    var bodyFormData = new FormData();
    bodyFormData.append("id", data.id);
    if (data.foto_url != null) bodyFormData.append("photo", data.foto_url);

    return http.put(`/agenda-pelatihan/${data.id}`, bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
}

export default new AgendaPelatihanService();
