<template>
  <v-main>
    <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>
    <v-container fluid>
      <v-card>
        <v-card-title> Edit User </v-card-title>
        <v-fab-transition>
          <v-btn
            v-if="isEditable == 0"
            color="pink"
            dark
            absolute
            top
            right
            fab
            @click="changeEditable"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </v-fab-transition>
        <form class="pl-4 pr-4">
          <v-row dense>
            <v-col cols="12" sm="6" md="6" class="pr-4">
              <v-sheet color="white" elevation="2">
                <v-responsive :aspect-ratio="16 / 9">
                  <v-img :key="fotoKey" :src="userData.FotoUrl" contain></v-img>
                </v-responsive>
              </v-sheet>
              <v-file-input
                v-model="userData.file_foto"
                accept="image/*"
                prepend-icon="mdi-image"
                show-size
                label="Foto"
                :disabled="isEditable == 0"
                @change="loadImage"
              />
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-row dense>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    v-model="userData.nama"
                    label="Nama"
                    outlined
                    dense
                    :readonly="isEditable == 0"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    v-model="userData.phone"
                    label="No Telepon"
                    outlined
                    dense
                    :readonly="isEditable == 0"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    v-model="userData.email"
                    label="Email"
                    outlined
                    dense
                    :readonly="isEditable == 0"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" sm="6" md="6">
                  <v-combobox
                    v-model="selectedPendidikan"
                    label="Pendidikan"
                    clearable
                    dense
                    outlined
                    :items="items_pendidikan"
                    item-text="value"
                    :readonly="isEditable == 0"
                  ></v-combobox>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="d-flex justify-end">
              <v-btn
                v-if="isEditable == 1"
                style="margin-right: 10px"
                @click="doBatal"
                >Batal</v-btn
              >
              <v-btn
                v-if="isEditable == 1"
                dark
                color="primary"
                type="button"
                @click="doSubmit"
                >Simpan</v-btn
              >
            </v-col>
          </v-row>
        </form>
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
import UserService from "../services/UserService";

export default {
  name: "UserEdit",
  components: {},
  props: {
    id: {
      type: String,
      default: "0",
    },
  },
  data: () => ({
    isEditable: 0,
    breadcrumbs: [
      {
        text: "Data User",
        disabled: false,
        exact: true,
        to: { name: "User" },
      },
      { text: "Edit User", disabled: true },
    ],
    userData: {
      FotoUrl: "",
      file_foto: null,
      nama: "",
      email: "",
      phone: "",
      profesi_id: "",
      str: "",
      sip: "",
      status_profesi: "",
      pendidikan_id: "",
    },
    selectedProfesi: "",
    items_profesi: [],
    selectedPendidikan: "",
    items_pendidikan: [],
    fotoKey: 0,
  }),
  computed: {},
  mounted: async function () {
    this.getListProfesi();
    this.getListPendidikan();
  },
  methods: {
    changeEditable: function () {
      this.isEditable = 1;
    },

    loadImage: function (data) {
      console.log("loadImage:", data);
      if (data) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.userData.FotoUrl = e.target.result;
          this.fotoKey++;
        };
        reader.readAsDataURL(data);
      } else {
        this.userData.FotoUrl = "";
        this.userData.file_foto = null;
      }
    },

    getUser() {
      UserService.getUser(this.id)
        .then((res) => {
          this.userData.nama = res.data.user.nama;
          this.userData.phone = res.data.user.phone;
          this.userData.email = res.data.user.email;
          this.userData.str = res.data.user.str;
          this.userData.sip = res.data.user.sip;
          this.userData.status_profesi = res.data.user.status_profesi;
          this.userData.profesi_id = res.data.user.profesi_id;
          this.userData.pendidikan_id = res.data.user.pendidikan_id;

          if (res.data.user.profesi_id) {
            this.items_profesi.forEach((entry) => {
              if (entry.code == res.data.user.profesi_id) {
                this.selectedProfesi = entry;
              }
            });
          }
          if (res.data.user.pendidikan_id) {
            this.items_pendidikan.forEach((entry) => {
              if (entry.code == res.data.user.pendidikan_id) {
                this.selectedPendidikan = entry;
              }
            });
          }
          this.userData.FotoUrl = res.data.user.photo;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getListProfesi() {
      UserService.profesiList()
        .then((res) => {
          this.items_profesi = res.data.list_data;

          this.getUser();
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getListPendidikan() {
      UserService.pendidikanList()
        .then((res) => {
          this.items_pendidikan = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    doBatal: function () {
      this.$router.go();
    },

    doSubmit: async function () {
      if (this.selectedProfesi && this.selectedProfesi != undefined) {
        this.userData.profesi_id = this.selectedProfesi.code;
      }
      if (this.selectedPendidikan && this.selectedPendidikan != undefined) {
        this.userData.pendidikan_id = this.selectedPendidikan.code;
      }
      UserService.update(this.id, this.userData, this.userData.file_foto)
        .then((res) => {
          console.log(res);
          this.isEditable = 0;
          this.$toast.success("Update User berhasil", {
            position: "top-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        })
        .catch((err) => {
          console.log(err);
          this.$toast.error("Tambah user gagal. Coba lagi", {
            position: "top-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        });
    },
  },
};
</script>

<style scoped></style>
