<template>
  <v-card>
    <v-card-title>
      Anggota
      <v-spacer></v-spacer>
      <!-- <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Cari Nama"
        single-line
        hide-details
        @keyup.enter="getDataFromApi"
      ></v-text-field>
      <v-spacer></v-spacer> -->

      <v-dialog v-model="dialogAdd" max-width="500px">
        <v-card>
          <v-card-title class="text-h5"
            >Tambah anggota ini sebagi Admin?</v-card-title
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeAdd">Tidak</v-btn>
            <v-btn color="blue darken-1" text @click="addItemConfirm">Ya</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card-title>

    <v-row class="mx-4 my-4">
      <v-col cols="12" sm="4" md="4">
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Cari Nama"
          single-line
          outlined
          dense
          hide-details
          @keyup.enter="getDataFromApi"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="4" md="4">
        <v-combobox
          v-model="selectedDpw"
          label="Dewan Pengurus Wilayah"
          clearable
          dense
          outlined
          :items="items_dpw"
          item-text="nama"
          :disabled="isDpwPengurus"
          @change="changeDpw"
        ></v-combobox>
      </v-col>
      <v-col cols="12" sm="4" md="4">
        <v-combobox
          v-model="selectedDpd"
          label="Dewan Pengurus Daerah"
          clearable
          dense
          outlined
          :items="items_dpd"
          item-text="nama"
          :disabled="isDpdPengurus"
          @change="changeDpd"
          @click="getListDpd"
        ></v-combobox>
      </v-col>
      <v-col cols="12" sm="4" md="4">
        <v-combobox
          v-model="selectedKomisariat"
          label="Komisariat"
          clearable
          dense
          outlined
          :items="items_komisariat"
          item-text="nama"
          :disabled="isAdminKomisariat"
          @change="changeKomisariat"
          @click="getListKomisariat"
        ></v-combobox>
      </v-col>
    </v-row>

    <v-data-table
      :headers="headers"
      :items="list_data"
      :options.sync="options"
      :server-items-length="totalData"
      :loading="loading"
      class="elevation-1"
    >
      <template #[`item.actions`]="{ item }">
        <v-chip color="cyan" dark @click="addItem(item)">
          Tambah sebagai Admin
        </v-chip>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import CommonService from "../services/CommonService";
import AdminService from "../services/AdminDPDService";
import KomisariatService from "../services/KomisariatService";
import store from "../store/index";
import DPWService from "../services/DPWService";
import DPDService from "../services/DPDService";

export default {
  props: {
    refresh: Function,
  },
  data: () => ({
    search: "",
    selectedDpw: "",
    selectedDpd: "",
    selectedKomisariat: "",
    items_komisariat: [],
    items_dpw: [],
    items_dpd: [],
    headers: [
      {
        text: "No",
        align: "start",
        sortable: false,
        value: "no",
      },
      { text: "Nama", value: "nama", sortable: false },
      { text: "Komisariat", value: "komisariat_nama", sortable: false },
      { text: "No Telp", value: "phone", sortable: false },
      { text: "Email", value: "email", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
    isFirst: false,
    totalData: 0,
    list_data: [],
    loading: true,
    options: {
      itemsPerPage: 10,
    },

    editedIndex: -1,
    editedItem: {},
    dialogAdd: false,
  }),
  computed: {
    getDpwPengurusId() {
      return store.getters.getUser.dpw_id;
    },
    getDpdPengurusId() {
      return store.getters.getUser.dpd_id;
    },
    isDpwPengurus() {
      if (store.getters.getUser.dpw_id) {
        return true;
      } else {
        return false;
      }
    },
    isDpdPengurus() {
      if (store.getters.getUser.dpd_id) {
        return true;
      } else {
        return false;
      }
    },
    getKomisariatId() {
      return store.getters.getUser.komisariat_id;
    },
    isAdminKomisariat() {
      if (store.getters.getUser.komisariat_id) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    options: {
      handler() {
        if (this.isFirst) {
          this.getDataFromApi();
        }
      },
      deep: true,
    },
  },
  mounted() {
    // this.getListKomisariat();
    this.getListDpw();
    this.getDataFromApi();
  },
  methods: {
    changeDpw() {
      this.selectedDpd = null;
      this.selectedKomisariat = null;
      this.getDataFromApi();
    },

    changeDpd() {
      this.selectedKomisariat = null;
      this.getDataFromApi();
    },

    async getListDpw() {
      DPWService.dpwListAll()
        .then((res) => {
          this.items_dpw = res.data.list_data;

          if (this.getDpwPengurusId) {
            this.items_dpw.forEach((entry) => {
              if (entry.id == this.getDpwPengurusId) {
                this.selectedDpw = entry;

                if (this.getDpdPengurusId) {
                  this.getListDpd();
                } else {
                  this.getDataFromApi();
                }
              }
            });
          } else {
            this.getDataFromApi();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getListDpd() {
      var dpwId = "";

      if (this.selectedDpw) {
        dpwId = this.selectedDpw.id;
      }

      DPDService.dpdListAll(dpwId)
        .then((res) => {
          this.items_dpd = res.data.list_data;

          if (this.getDpdPengurusId) {
            this.items_dpd.forEach((entry) => {
              if (entry.id == this.getDpdPengurusId) {
                this.selectedDpd = entry;
                this.getDataFromApi();
              }
            });
          } else {
            this.getDataFromApi();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    changeKomisariat() {
      this.getDataFromApi();
    },

    async getListKomisariat() {
      KomisariatService.komisariatListAllFilter(this.selectedDpd.id)
        .then((res) => {
          this.items_komisariat = res.data.list_data;

          if (this.getKomisariatId) {
            this.items_komisariat.forEach((entry) => {
              if (entry.id == this.getKomisariatId) {
                this.selectedKomisariat = entry;
                this.getDataFromApi();
              }
            });
          } else {
            this.getDataFromApi();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getDataFromApi() {
      const { page, itemsPerPage } = this.options;

      var komisariat = "";
      var dpw = "";
      var dpd = "";

      if (this.selectedKomisariat) {
        komisariat = this.selectedKomisariat.id;
      }

      if (this.selectedDpw) {
        dpw = this.selectedDpw.id;
      }

      if (this.selectedDpd) {
        dpd = this.selectedDpd.id;
      }

      this.loading = true;
      AdminService.adminNonList(
        page,
        itemsPerPage,
        this.search,
        komisariat,
        dpw,
        dpd,
      )
        .then((res) => {
          this.list_data = res.data.list_data;
          this.totalData = res.data.total;
          this.loading = false;
          this.isFirst = true;
        })
        .catch((err) => {
          this.loading = false;
          this.isFirst = true;
          console.log(err);
        });
    },

    addItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogAdd = true;
    },

    addItemConfirm() {
      this.closeAdd();

      this.editedItem.pib_id = 1;
      AdminService.create(this.editedItem)
        .then((res) => {
          console.log(res);

          CommonService.showSuccessToast("Tambah admin berhasil");

          this.getDataFromApi();
          this.refresh();
        })
        .catch((err) => {
          console.log(err);

          CommonService.showErrorToast("Tambah admin gagal. Coba lagi");
        });
    },

    closeAdd() {
      this.dialogAdd = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
  },
};
</script>
