import http from "../http-common";
/* eslint-disable */
class KomunitasService {
  komunitasList(page, itemPerPage, keyword) {
    return http.get(
      "/komunitas/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  create(data) {
    var bodyFormData = new FormData();
    bodyFormData.append("name", data.name);
    bodyFormData.append("profesi_id", data.selectedProfesi.code);

    return http.post("/komunitas", bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  update(id, data) {
    var bodyFormData = new FormData();
    bodyFormData.append("id", id);
    bodyFormData.append("name", data.name);
    bodyFormData.append("profesi_id", data.selectedProfesi.code);

    return http.put(`/komunitas/${id}`, bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  delete(id) {
    return http.delete(`/komunitas/${id}`, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
}

export default new KomunitasService();
