<template>
  <div>
    <v-card>
      <v-card-title>
        Pre Test <br />
        <v-spacer></v-spacer>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="list_data"
        :options.sync="options"
        :server-items-length="totalData"
        :loading="loading"
        class="elevation-1"
        :hide-default-footer="true"
        :single-expand="false"
        :expanded.sync="expanded"
        show-expand
      >
        <template #expanded-item="{ heads, item }">
          <td :colspan="heads.length" class="pt-4">
            <span>Pilihan : </span>
            <v-list>
              <v-list-item-group>
                <v-list-item v-for="(pilihan, i) in item.pilihans" :key="i">
                  <v-list-item-content>
                    <v-list-item-title
                      :text="pilihan.no + '. ' + pilihan.jawaban"
                    ></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </td>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import ReportService from "../../services/ReportService";

export default {
  props: {
    id: {
      type: Number,
      default: 0,
    },
    userId: {
      type: String,
      default: "0",
    },
  },
  data: () => ({
    expanded: [],
    childKey: 0,
    selected: [],
    search: "",
    headers: [
      {
        text: "No",
        align: "start",
        sortable: false,
        value: "no",
      },
      { text: "Pertanyaan", value: "pertanyaan", sortable: false },
      { text: "Jawaban", value: "jawaban", sortable: false },
      { text: "", value: "data-table-expand" },
    ],
    isFirst: false,
    totalData: 0,
    list_data: [],
    loading: true,
    options: {
      itemsPerPage: 10,
      page: 1,
    },
  }),
  watch: {
    selected: {
      handler() {
        if (this.selected != undefined && this.selected[0]) {
          this.childKey++;
        }
      },
      deep: true,
    },
    options: {
      handler() {
        if (this.isFirst) {
          this.getDataFromApi();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getDataFromApi();
  },
  methods: {
    async getDataFromApi() {
      const { page, itemsPerPage } = this.options;

      this.loading = true;
      ReportService.ujianList(
        page,
        itemsPerPage,
        this.search,
        this.id,
        this.userId,
      )
        .then((res) => {
          this.list_data = res.data.list_data;
          this.totalData = res.data.total;
          this.loading = false;
          this.isFirst = true;
        })
        .catch((err) => {
          this.loading = false;
          this.isFirst = true;
          console.log(err);
        });
    },
  },
};
</script>
