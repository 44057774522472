import http from "../http-common";
/* eslint-disable */
class PaketMembershipPelatihanService {
  kegiatanList(page, itemPerPage, keyword, id) {
    return http.get(
      "/paket-membership-pelatihan/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword +
        "&paket_membership_id=" +
        id,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      },
    );
  }

  kegiatanNonList(page, itemPerPage, keyword, id) {
    return http.get(
      "/paket-membership-pelatihan-non/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword +
        "&paket_membership_id=" +
        id,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      },
    );
  }

  create(data) {
    var bodyFormData = new FormData();
    if (data.paket_membership_id)
      bodyFormData.append("paket_membership_id", data.paket_membership_id);
    if (data.kegiatan_id) bodyFormData.append("kegiatan_id", data.kegiatan_id);

    return http.post("/paket-membership-pelatihan", bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  delete(id, kegiatanId) {
    return http.delete(`/paket-membership-pelatihan/${id}/${kegiatanId}`, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
}

export default new PaketMembershipPelatihanService();
