import http from "../../http-common";
/* eslint-disable */
class TestimoniService {
  testimoniList(page, itemPerPage, keyword, dpd) {
    return http.get(
      "/testimoni/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword +
        "&dpd_id=" +
        dpd,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      },
    );
  }

  create(data) {
    var bodyFormData = new FormData();
    bodyFormData.append("testimoni", data.testimoni);
    bodyFormData.append("konsumen", data.konsumen);

    if (data.selectedHide && data.selectedHide != undefined) {
      bodyFormData.append("data_status", data.selectedHide.text);
    }

    if (data.selectedDpd && data.selectedDpd != undefined) {
      bodyFormData.append("dpd_id", data.selectedDpd.id);
    }

    if (data.foto_url != null) bodyFormData.append("photo", data.foto_url);

    return http.post("/testimoni", bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  update(data) {
    var bodyFormData = new FormData();
    bodyFormData.append("id", data.id);
    bodyFormData.append("testimoni", data.testimoni);
    bodyFormData.append("konsumen", data.konsumen);

    if (data.selectedHide && data.selectedHide != undefined) {
      bodyFormData.append("data_status", data.selectedHide.text);
    }

    if (data.selectedDpd && data.selectedDpd != undefined) {
      bodyFormData.append("dpd_id", data.selectedDpd.id);
    }

    if (data.foto_url != null) bodyFormData.append("photo", data.foto_url);

    return http.put(`/testimoni/${data.id}`, bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  delete(id) {
    return http.delete(`/testimoni/${id}`, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
}

export default new TestimoniService();
