<template>
  <div>
    <v-card>
      <v-card-title>
        Evaluasi Penyelenggaraan <br />

        <v-spacer></v-spacer>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="list_data"
        :options.sync="options"
        :server-items-length="totalData"
        :loading="loading"
        class="elevation-1"
        :hide-default-footer="true"
        :single-expand="false"
        :expanded.sync="expanded"
        show-expand
      >
        <template #expanded-item="{ heads, item }">
          <td :colspan="heads.length" class="pt-4 pb-4">
            <span>Jawaban : </span> <br />
            <span class="content-desc">{{ item.jawaban }}</span>
          </td>
        </template>
      </v-data-table>
    </v-card>

    <!-- Modal -->
  </div>
</template>

<script>
import ReportService from "../../services/ReportService";

export default {
  props: {
    kegiatanId: {
      type: String,
      default: "0",
    },
    userId: {
      type: String,
      default: "0",
    },
  },
  data: () => ({
    expanded: [],
    childKey: 0,
    search: "",
    selected: [],
    headers: [
      {
        text: "No",
        align: "start",
        sortable: false,
        value: "no",
      },
      { text: "Aspek Dinilai", value: "aspek_dinilai", sortable: false },
      { text: "Jawaban", value: "data-table-expand" },
    ],
    isFirst: false,
    totalData: 0,
    list_data: [],
    loading: true,
    options: {
      itemsPerPage: 10,
      page: 1,
    },
  }),
  watch: {
    selected: {
      handler() {
        if (this.selected != undefined && this.selected[0]) {
          this.childKey++;
        }
      },
      deep: true,
    },
    options: {
      handler() {
        if (this.isFirst) {
          this.getDataFromApi();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getDataFromApi();
  },
  methods: {
    async getDataFromApi() {
      const { page, itemsPerPage } = this.options;

      this.loading = true;
      ReportService.evaluasiPenyelenggaraanReportList(
        page,
        itemsPerPage,
        this.search,
        this.kegiatanId,
        this.userId,
      )
        .then((res) => {
          this.list_data = res.data.list_data;
          this.totalData = res.data.total;
          this.loading = false;
          this.isFirst = true;
        })
        .catch((err) => {
          this.loading = false;
          this.isFirst = true;
          console.log(err);
        });
    },
  },
};
</script>

<style scoped>
.content-desc {
  white-space: pre-wrap;
  font-size: 14px;
}
</style>
