import http from "../http-common";
/* eslint-disable */
class InformasiBroadcastService {
  broadcastList(page, itemPerPage, keyword) {
    return http.get(
      "/broadcast/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      },
    );
  }

  create(data) {
    var bodyFormData = new FormData();
    if (data.nama) bodyFormData.append("nama", data.nama);
    if (data.deskripsi) bodyFormData.append("deskripsi", data.deskripsi);

    if (data.file_foto != null) bodyFormData.append("photo", data.file_foto);

    return http.post("/broadcast", bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  update(data) {
    var bodyFormData = new FormData();
    bodyFormData.append("id", data.id);
    if (data.nama) bodyFormData.append("nama", data.nama);
    if (data.deskripsi) bodyFormData.append("deskripsi", data.deskripsi);

    if (data.file_foto != null) bodyFormData.append("photo", data.file_foto);

    return http.put(`/broadcast/${data.id}`, bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  delete(id) {
    return http.delete(`/broadcast/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  sendBroadcast(id) {
    var bodyFormData = new FormData();
    bodyFormData.append("id", id);

    return http.post(`/broadcast-send`, bodyFormData, {
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  cancelBroadcast(id) {
    var bodyFormData = new FormData();
    bodyFormData.append("id", id);

    return http.post(`/broadcast-cancel`, bodyFormData, {
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }
}

export default new InformasiBroadcastService();
